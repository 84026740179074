<template>
    <div class="py-0 mt-4 mb-8">
        <v-container class="mx-0 my-0" style="padding:0 !important; max-width: 100% !important">
            <v-card class="mt-1 pt-1 pb-10 px-0 size" style="margin-bottom: 35px !important" align="center">
                <v-row align="end" class="px-4 pb-3" justify="center">
                    <h3 class="px-4 pt-4 azul--text" style="font-size:25px">{{ $t('register_in_portal.register_in_portal') }}</h3>
                </v-row>
                <v-col cols="6" justify="start" align="start" class="px-0">
                    <v-row>
                        <v-col cols="6" class="py-1">
                            <span justify="center" class="pr-1 azul--text text--uppercase font-weight-bold" style="font-size:18px">
                                {{ $t('process_pendings.general_data') }} 
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.appointment') }}: </span> {{ this.id }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.client') }}: </span> {{ this.appointment.client.name }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.estimated_date_of_arriving') }}: </span> {{ this.appointment.arrival_date }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="$store.state.language == 'en'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.operation') }}: </span> {{ this.appointment.operation.description1 }} </v-col>
                        <v-col v-if="$store.state.language == 'es'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.operation') }}: </span> {{ this.appointment.operation.description }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.container') }}: </span> {{ this.appointment.container_number }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.description') }}: </span> {{ this.appointment.description }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.comments') }}: </span> {{ this.appointment.commentary }} </v-col>
                    </v-row>
                </v-col>
                <v-col class="py-5 px-0" cols="6" justify="start" align="start">
                    <v-row>
                        <v-col cols="12" class="py-1">
                            <span justify="center" class="pr-1 azul--text font-weight-bold" style="font-size:18px">
                                {{ $t('process_pendings.products') }} 
                            </span>
                        </v-col>
                    </v-row>
                    <datatable :props="{headers:headers_sku, items: appointment.products}" @methodHandler="methodHandler"></datatable>   
                </v-col>

                <v-col cols="6" justify="center" align="center" class="px-0 mx-0">
                    <v-autocomplete class="px-0 mx-0" v-model="appointment.gate_id" :items="portals" item-text="name" item-value="id" :rules="rules_required" :label="$t('register_in_portal.select_ramp')"></v-autocomplete>
                </v-col>
                
                <v-row class="mx-0 py-0 my-0" align="center" justify="center">
                    <v-btn color="white" class="black--text mr-4" @click="cancel">
                        {{ $t('register_in_portal.cancel') }}
                    </v-btn>
                    <v-btn color="amarillo" outlined class="white--text mr-4" @click="toPending">
                        {{ $t('register_in_portal.to_pending') }}
                    </v-btn>
                    <v-btn color="amarillo" class="white--text" @click="update" :disabled="!isSelected" v-permission="'op_on_premises.update'">
                        {{ $t('register_in_portal.save') }}
                    </v-btn>
                </v-row>
            </v-card>
            <bottom-bar ref="bottombar" :from="from" :m_type="m_type"></bottom-bar>
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('edit_appointment.validations.required_field')]}, 
        headers_sku: function(){
            return [
                {text: this.$t('process_pendings.sku'), value: 'sku_p', class: 'text-uppercase'},
                // {text: this.$t('edit_appointment.description'), value: 'descripcion', class: 'text-uppercase'},
                {text: this.$t('process_pendings.pieces'), value: 'quantity', class: 'text-uppercase'},
                {text: this.$t('process_pendings.unit'), value: 'unit', class: 'text-uppercase'}
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            from: this.$route.params.from,
            m_type: this.$route.params.m_type,
            snackbar: {
                visible: false,
                color: 'primary',
                timeout: 2000,
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            appointment:{
                // General data
                client_id: "",
                client: {
                    name:""
                },
                arrival_date: "",
                eta: "",
                operation_id: "",
                operation: {
                    description: "",
                    description1: ""
                },
                container_number: "",
                description: "",
                commentary: "",
                
                gate_id: "",
                products: []
            },            isSelected: false,
            selected:'',
            appointments: [],
            portals: [],
        }
    },
    mounted(){
        this.index();
        localStorage.setItem('bottom', 1);
        this.$store.state.module = "headers.in_portal_operation";
        this.$store.state.bottom = 1;
    },
    methods: {
        back(){
            this.$router.push({ name: 'OperationOnPremises' });
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            
            this.$http.get(this.$store.state.chronosApi + 'appointments/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data = response.data.data.attributes;
                    if(response.data.operation.format_type == "Entrada"){
                        if(response.data.unknown_products == true){
                            if(response.data.standby_products[0].attributes.unknownProducts.boxes > 0){
                                this.appointment.products.push({
                                    sku_p: this.$t('process_pendings.boxes'),
                                    quantity: response.data.standby_products[0].attributes.unknownProducts.boxes
                                })
                            }
                            else if(response.data.standby_products[0].attributes.unknownProducts.pallets > 0){
                                this.appointment.products.push({
                                    sku_p: this.$t('process_pendings.pallets'),
                                    quantity: response.data.standby_products[0].attributes.unknownProducts.pallets
                                })
                            }
                        }
                        else{
                            response.data.standby_products.forEach(x => 
                            {
                                x = x.attributes;
                                var p = {};
                                p = {
                                    sku_p: x.product.sku,
                                    quantity: x.quantity,
                                    unit: x.product.unit
                                }
                                            
                                this.appointment.products.push(p);
                            });
                        }
                    } 
                    else if(response.data.operation.format_type == "Salida"){
                        response.data.detail_orders.forEach(x => 
                        {
                            x = x.attributes;
                            var p = {};
                            p = {
                                sku_p: x.product.sku,
                                quantity: x.expected,
                                unit: x.product.unit
                            }
                                        
                            this.appointment.products.push(p);
                        });
                    }

                    // General Data
                    this.$store.state.clientId              = response.data.client.id ?? null;
                    this.appointment.client_id              = response.data.client.id ?? null;
                    this.appointment.client                 = response.data.client ?? null;
                    this.appointment.operation              = response.data.operation ?? null;
                    this.appointment.arrival_date           = response.data.arrival_date.substring(0, 10) ?? null;
                    if(response.data.is_national == true && response.data.eta != undefined && response.data.eta != null)
                        this.appointment.eta                = response.data.eta.substring(0, 10);
                    this.appointment.operation_id           = response.data.operation.id ?? null;
                    this.appointment.additional_services    = response.data.additional_services ?? null;
                    this.appointment.purchase_order         = response.data.purchase_order ?? null;
                    this.appointment.container_number       = response.data.container_number ?? null;
                    this.appointment.description            = response.data.description ?? null;
                    this.appointment.commentary             = response.data.commentary ?? null;
                    this.appointment.gate_id                = response.data.gate_id ?? null;
                }       
            })
            .catch((response) => {              
                this.$store.state.loaderProps.visible = false;
                console.log(response)
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });

            this.$http.get(this.$store.state.chronosApi + 'free_gates', {headers: {Authorization: lsToken}})
            .then((response) =>{
                response.data.data.forEach(x => {
                    this.portals.push({ name: x.attributes.name, id: x.attributes.id });
                })
            }).catch((response) => {
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('on_premises_mobile.responses.error')
                    }
                }
            });
        },
        update(){
            if(this.appointment.gate_id != null && this.appointment.gate_id != ''){
                const appointment = {
                    gate_id: this.appointment.gate_id,
                    status: 'En rampa',
                    newStatus: 'En rampa',
                }

                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'setStatus/' + this.id, appointment, {headers: {Authorization: lsToken}})
                .then((response) => {   
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('register_in_portal.responses.correct')
                        } 
                        this.$router.push('/operation/on-ramp/' + this.from + '/' + this.m_type);
                    }
                }).catch((error) => {
                    console.log(error);
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('register_in_portal.responses.update')
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('register_in_portal.responses.empty')
                }
            }
        },
        toPending(){
            const appointment = {
                gate_id: null,
                status: 'Pendiente',
                newStatus: 'Pendiente',
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.put(this.$store.state.chronosApi + 'setStatus/' + this.id, appointment, {headers: {Authorization: lsToken}})
            .then((response) => {   
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('register_in_portal.responses.correct')
                    } 
                    this.$router.push('/operation/pendings/' + this.from + '/' + this.m_type);
                }
            }).catch((error) => {
                console.log(error);
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('register_in_portal.responses.update')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        }
    },
    watch:{
        'appointment.gate_id': function() {
            if(this.appointment.gate_id != null && this.appointment.gate_id != "") this.isSelected = true;
        },
    }
}
</script>

<style lang="css">
.size{
    min-height: calc(100vh - 120px) !important;
    position:relative !important;
}
</style>