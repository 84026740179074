<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_product.edit') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.sku" :label="$t('edit_product.sku')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.barcode" :label="$t('edit_product.barcode')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.description" :label="$t('edit_product.description')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.client_id" item-value="id" item-text="name" :items="clients" :label="$t('edit_product.client')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.category_id" item-value="id" item-text="name"  :items="categories" :label="$t('edit_product.category')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.storage_policy" :label="$t('edit_product.refill_alert')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>      
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.unit" :items="units" :label="$t('edit_product.unit')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.size_unit" :items="size_units" :label="$t('edit_product.size_unit')"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.height" :label="$t('edit_product.height')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.length" :label="$t('create_product.length')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.width" :label="$t('create_product.width')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.weight" :label="$t('edit_product.weight')"></v-text-field>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.quantity_per_box" :label="$t('edit_product.quantity_per_box')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                        
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.quantity_per_pallet" :label="$t('edit_product.quantity_per_pallet')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row> -->

                        <v-row class="px-5 pb-2 my-0" justify="center" align="center">
                            <v-col cols="4" class="px-3 py-0">
                                <span >{{ $t('edit_product.product_national') }}:</span>
                            </v-col>
                            <v-col cols="2" class="px-3 py-0" justify="end" align="end">
                                <v-btn class="radio-left" v-bind:color="product.is_national == false ? 'info' : 'white'" @click="product.is_national = false">{{ $t('edit_product.no') }}</v-btn>
                                <v-btn class="radio-right" v-bind:color="product.is_national == true? 'info' : 'white'" @click="product.is_national = true">{{ $t('edit_product.yes') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_product.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'product.update'">
                                {{ $t('edit_product.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            product: {
                sku: '',
                description: '',
                storage_policy: 0,
                quantity: '',
                cost: '',
                unit: '',
                weight: '',
                is_national: true,
                client_id: '',
                category_id: '',
                barcode: '', 
                height: '', 
                length : '', 
                width: '',
                size_unit: '',
                // quantity_per_box: '',
                // quantity_per_pallet: '',
            },
            categories: [],
            clients: [],
            size_units: ["CM/KG", "IN/LB"],
            units: [
                "KILO",
                "GRAMO",
                "METRO LINEAL",
                "METRO CUADRADO",
                "METRO CÚBICO",
                "PIEZA",
                "CABEZA",
                "LITRO",
                "PAR",
                "KILOWATT",
                "MILLAR",
                "JUEGO",
                "KILOWATT/HORA",
                "TONELADA",
                "BARRIL",
                "GRAMO NETO",
                "DECENAS",
                "CIENTOS",
                "DOCENAS",
                "CAJA",
                "BOTELLA",
                "POUNDS",
                "FEET"
            ]
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_product";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'clientsIndex/2', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data
                response.data.forEach(x => {
                    x = x.attributes;
                    this.clients.push({id: x.id, name: x.name});
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            this.$http.get(this.$store.state.chronosApi + 'categories', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.forEach(x => {
                    this.categories.push({id: x.id, name: x.name})
                }) 
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            this.$http.get(this.$store.state.chronosApi + 'products/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.product = {
                    sku: response.data.data.attributes.sku,
                    description: response.data.data.attributes.description,
                    barcode: response.data.data.attributes.barcode,
                    size_unit: response.data.data.attributes.size_unit,
                    width: response.data.data.attributes.width,
                    length: response.data.data.attributes.length,
                    height: response.data.data.attributes.height,
                    quantity_per_box: response.data.data.attributes.quantity_per_box,
                    quantity_per_pallet: response.data.data.attributes.quantity_per_pallet,
                    category:{
                        name: response.data.data.attributes.category.attributes.name,
                    },
                    unit: response.data.data.attributes.unit,
                    is_national: response.data.data.attributes.is_national == true ? 1 : 0,
                    storage_policy: response.data.data.attributes.storage_policy,
                    weight: response.data.data.attributes.weight,
                    client: {
                        name: response.data.data.attributes.client.attributes.name
                    }
                }, 
                this.product.client_id = response.data.data.attributes.client.attributes.id;
                this.product.category_id = response.data.data.attributes.category.attributes.id
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;

            const formVal = this.$refs.form.validate();

            if(!formVal){
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('create_product.form_invalid')
                }
                this.$store.state.loaderProps.visible = false;

                return;
            }

            const lsToken = localStorage.getItem("access_token");
            this.$http.put(this.$store.state.chronosApi + 'products/' + this.id, this.product, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status == 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('edit_warehouse.responses.success')
                    }
                    this.$router.push({ name: "ProductsIndex" });  
                }
            }).catch((err) => {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: err.response.data.message
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>