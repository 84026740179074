<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('customers.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" :to="'/customers/create'" v-permission="'client.create'">
                        {{ $t('customers.new_customer') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('customers.modal.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('customers.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('customers.no') }}</v-btn>
                            <v-btn color="error" @click="deleteCustomer" v-permission="'client.delete'">
                                {{ $t('customers.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('customers.id'), align: "center", value: 'id', class: 'text-uppercase' },
                { text: this.$t('customers.name'), align: "center", value: 'name', class: 'text-uppercase' },
                { text: this.$t('customers.country'), align: "center", value: 'country', class: 'text-uppercase' },
                { text: this.$t('customers.state'), align: "center", value: 'state', class: 'text-uppercase' },
                { text: this.$t('customers.phone'), align: "center", value: 'contact_phone', class: 'text-uppercase' },
                { text: this.$t('customers.contact'), align: "center", value: 'email', class: 'text-uppercase' },
                { text: this.$t('customers.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            idCustomer: '',
            actions: [
                {
                    name: this.$t('customers.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '',
                    permission: "client.show"
                },
                {
                    name: this.$t('customers.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    link: '',
                    permission: "client.update"
                },
                {
                    name: this.$t('customers.edit_fees'),
                    icon: {
                        color: 'accent',
                        icon: 'add_card'
                    },
                    link: '',
                    permission: "customer_fees.create"
                },
                // {
                //     name: this.$t('customers.delete'),
                //     icon: {
                //         color: 'accent',
                //         icon: 'delete'
                //     },
                //     method: 'askDelete',
                //     permission: "client.delete"
                // },
                {
                    name: this.$t('customers.manage_shops'),
                    icon: {
                        color: 'accent',
                        icon: 'shopping_cart'
                    },
                    link: '',
                    permission: "client.manage_stores"
                },
                {
                    name: this.$t('customers.configure_sms'),
                    icon: {
                        color: 'accent',
                        icon: 'chat'
                    },
                    link: '',
                    permission: "client.show"
                },
            ],
            description: '',
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.customers";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'clientsIndex/2', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.data.forEach(x =>{
                        x = x.attributes;
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x.actions[0].link = 'customers/details/' + x.id;
                        x.actions[1].link = 'customers/edit/' + x.id;
                        x.actions[2].link = 'customers/edit/fees/' + x.id;
                        x.actions[3].link = 'customers/manage_stores/' + x.id;
                        x.actions[4].link = 'customers/manage_sms/' + x.id;
                        x['address'] = x.municipality  + ' ' + x.neighborhood + ' ' + x.street + ' ' + x.external_number
                        x['parameters'] = x;
                        this.props.items.push(x);
                    });
                this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        askDelete(customer){
            this.modalDelete = true;
            this.idCustomer = customer.id;
        },
        deleteCustomer(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'clients/' + this.idCustomer, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.modalDelete = false;
                    this.index();
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        update(customer){
            console.log(customer);
        },
    }
}
</script>

<style>

</style>