<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_visitor.new_scheduled_visitor') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <datepicker :open_dialog="menu_date" :model="visitor.date" :label="$t('create_visitor.visit_date')" @model="updateDate('visitor.date', $event)" :rules_required="rules_required"></datepicker>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="visitor.visitor_name" :label="$t('create_visitor.name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="visitor.company" :label="$t('create_visitor.company')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="visitor.plates" :label="$t('create_visitor.vehicle_plates')"></v-text-field>
                            </v-col>
                        </v-row> -->
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="visitor.receiver_name" :label="$t('create_visitor.receiver_name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="visitor.subject" :label="$t('create_visitor.subject')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <datepicker :open_dialog="menu_validity" :model="visitor.validity_date" :label="$t('create_visitor.validity_date')" @model="updateDate('visitor.validity_date', $event)" :rules_required="rules_required"></datepicker>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_visitor.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'visitors.create'">
                                {{ $t('create_visitor.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            menu: false,
            entry_time_modal: false,
            visitor: {
                date: "",
                validity_date: "",
                entry_time: "",  
                visitor_name: "", 
                company: "", 
                receiver_name: "", 
                subject: "", 
                warehouse_id: this.$store.state.warehouseId,
            },
            menu_date: false,
            menu_validity: false,
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_visitor";
    },
    methods: {
        index(){
            
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const formData = new FormData();

            formData.append('date', this.visitor.date);
            // formData.append('entry_time', this.visitor.entry_time);
            formData.append('visitor_name', this.visitor.visitor_name);
            formData.append('company', this.visitor.company);
            formData.append('receiver_name', this.visitor.receiver_name);
            formData.append('subject', this.visitor.subject);
            formData.append('validity_date', this.visitor.validity_date);
            // formData.append('observations', this.visitor.observations);
            formData.append('warehouse_id', this.visitor.warehouse_id);
            
            this.$http.post(this.$store.state.chronosApi + 'visitors', formData,{headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_visitor.responses.success')
                }
                this.$router.push({ name: "VisitorsIndex" });  
            }).catch((err) => {
                if("name" in err.response.data){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('create_visitor.validations.name')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        updateDate(model, new_date){            
            this[model.split(".")[0]][model.split(".")[1]] = new_date;
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>