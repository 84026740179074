<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_carrier.new_carrier') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.carrier_line" :label="$t('create_carrier.carrier_line')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.drivers_name" :label="$t('create_carrier.drivers_name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.tract_number" :label="$t('create_carrier.tract_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.tract_plates" :label="$t('create_carrier.tract_plates')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.container_number" :label="$t('create_carrier.container_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.box_plates" :label="$t('create_carrier.box_plates')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.seal_number" :label="$t('create_carrier.seal_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.waybill" :label="$t('create_carrier.waybill')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.observations" :label="$t('create_carrier.observations')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :clearable="false" v-model="carrier.identification_photo" accept="image/*" capture="camera" :label="$t('create_carrier.identification_photo')" :rules="rules_required"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :clearable="false" v-model="carrier.vehicle_photo_front" accept="image/*" capture="camera" :label="$t('create_carrier.vehicle_photo_front')" :rules="rules_required"></v-file-input>
                            </v-col>
                            <v-col cols="3" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :clearable="false" v-model="carrier.vehicle_photo_back" accept="image/*" capture="camera" :label="$t('create_carrier.vehicle_photo_back')" :rules="rules_required"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :clearable="false" v-model="carrier.vehicle_photo_side" accept="image/*" capture="camera" :label="$t('create_carrier.vehicle_photo_side')" :rules="rules_required"></v-file-input>
                            </v-col>
                            <v-col cols="3" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :clearable="false" v-model="carrier.vehicle_photo_seal" accept="image/*" capture="camera" :label="$t('create_carrier.vehicle_photo_seal')" :rules="rules_required"></v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_carrier.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'carriers.create'">
                                {{ $t('create_carrier.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            menu: false,
            arrival_time_modal: false,
            carrier: {
                arrival_date: "",
                arrival_time: "", 
                carrier_line: "", 
                drivers_name: "", 
                tract_number: "", 
                tract_plates: "", 
                container_number: "", 
                box_plates: "", 
                seal_number: "", 
                waybill: "",
                observations: "",
                identification_photo: [],
                vehicle_photo_front: [],
                vehicle_photo_back: [],
                vehicle_photo_side: [],
                vehicle_photo_seal: [],
                warehouse_id: this.$store.state.warehouseId,
            },
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_carrier";
    },
    methods: {
        index(){
            
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const formData = new FormData();

            formData.append('arrival_date', this.carrier.arrival_date);
            formData.append('arrival_time', this.carrier.arrival_time);
            formData.append('carrier_line', this.carrier.carrier_line);
            formData.append('drivers_name', this.carrier.drivers_name);
            formData.append('tract_number', this.carrier.tract_number);
            formData.append('tract_plates', this.carrier.tract_plates);
            formData.append('container_number', this.carrier.container_number);
            formData.append('box_plates', this.carrier.box_plates);
            formData.append('seal_number', this.carrier.seal_number);
            formData.append('waybill', this.carrier.waybill);
            formData.append('observations', this.carrier.observations);
            formData.append('warehouse_id', this.carrier.warehouse_id);
            formData.append('identification_photo', this.carrier.identification_photo);
            formData.append('vehicle_photo_front', this.carrier.vehicle_photo_front);
            formData.append('vehicle_photo_back', this.carrier.vehicle_photo_back);
            formData.append('vehicle_photo_side', this.carrier.vehicle_photo_side);
            formData.append('vehicle_photo_seal', this.carrier.vehicle_photo_seal);
            
            this.$http.post(this.$store.state.chronosApi + 'carriers', formData,{headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_carrier.responses.success')
                }
                this.$router.push({ name: "CarriersIndex" });  
            }).catch((err) => {
                if("name" in err.response.data){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('create_carrier.validations.name')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>