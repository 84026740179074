<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_warehouse.edit') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="warehouse.name" :label="$t('create_warehouse.name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="warehouse.country" :loading="countries_load" :items="countries" :label="$t('create_warehouse.country')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="warehouse.state" :loading="states_load" :items="states" :label="$t('create_warehouse.state')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="warehouse.municipality" :label="$t('create_warehouse.municipality')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="warehouse.neighborhood" :label="$t('create_warehouse.neighborhood')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="warehouse.street" :label="$t('create_warehouse.street')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="warehouse.zipcode" :label="$t('create_warehouse.zip_code')" :rules="rules_required"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="warehouse.external_number" :label="$t('create_warehouse.ext_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="warehouse.internal_number" :label="$t('create_warehouse.int_number')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-checkbox class="mt-0" color="amarillo" v-model="warehouse.picking_required" :label="$t('create_warehouse.pickeo_required')" :rules="rules_required"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 pt-1 mt-0" justify="center" align="center">
                            <v-col cols="6" align="start" justify="start">    
                                <h4 class="azul--text text-uppercase">{{ $t('create_warehouse.shipping_services') }}</h4>
                                <label>{{ $t('create_warehouse.select_shipping_services') }}</label>
                            </v-col>
                        </v-row>
                        <v-row v-for="ss of warehouse.shipping_services" :key="ss.id" class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-checkbox class="mt-0" color="amarillo" v-model="ss.work" :label="ss.name"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel" >
                                {{ $t('create_warehouse.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" >
                                {{ $t('create_warehouse.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            countries: [],
            states: [],
            warehouse: {
                country: '',
                state: '',
                name: '',
                municipality: '',
                neighborhood: '',
                street: '',
                zipcode: '',
                external_number: '',
                internal_number: '',
                shipping_services: [],
                picking_required: true
            },
            countries_load: false,
            states_load: false,
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_warehouse";
    },
    methods: {
        index(){
            this.countries = [];
            this.states = [];
            
            // Get countries
            this.countries_load = true;
            this.$http.get("https://locations.intech.mx/api/getCountries", {})
            .then((response) => {
                this.countries = response.data
                this.countries_load = false;
            }).finally(() => {
                this.countries_load = false;
            });

            // Get Shippment_services
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipping_services/', {headers: {Authorization: lsToken}})
            .then((response1) => {
                response1.data.forEach(x => {
                    var exist = false;
                    this.warehouse.shipping_services.forEach(y => {
                        if(y.name == x.name) {
                            exist = true;
                            y.work = true;
                        }
                    })
                    if(exist == false){
                        x.work = false;
                        this.warehouse.shipping_services.push(x);
                    }
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.post(this.$store.state.chronosApi + 'warehouses', this.warehouse, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status == 201){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('create_warehouse.responses.success')
                    }
                    this.$router.push({ name: "WarehousesIndex" });  
                }
            }).catch((err) => {
                if("name" in err.response.data){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('create_warehouse.validations.name')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        "warehouse.country": function(){
            this.states = [];
            this.states_load = true;
            this.$http.get("https://locations.intech.mx/api/getStates/"+this.warehouse.country, {})
            .then((response) => {
                if(response.status === 200){
                    this.states = response.data
                    this.states_load = false;
                }
            }).finally(() => {
                this.states_load = false;
            });
        },
    }
}
</script>

<style lang="css">
</style>