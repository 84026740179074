<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('external_stuck_orders.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('external_stuck_orders.modal.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('external_stuck_orders.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('external_stuck_orders.no') }}</v-btn>
                            <v-btn color="error" @click="deleteOrder" v-permission="'stuck_external_orders.delete'">
                                {{ $t('external_stuck_orders.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('external_stuck_orders.order_details.header') }} {{ this.orderToShow.order_id }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('external_stuck_orders.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("external_stuck_orders.order") }}</label> <label>{{ this.orderToShow.order_id }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("external_stuck_orders.date") }}</label> <label>{{ this.orderToShow.date }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("external_stuck_orders.observation") }}</label> <label>{{ this.orderToShow.observation }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("external_stuck_orders.customer") }}</label> <label>{{ this.orderToShow.source }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("external_stuck_orders.channel") }}</label> <label>{{ this.orderToShow.channel }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('external_stuck_orders.products') }}</label></div>
                    <span>
                        <div v-for="(product, i) of orderToShow.products" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.sku }}</label> <label>{{ product.quantity }}</label></div>
                    </span>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDetail = false">{{ $t('external_stuck_orders.go_back') }}</v-btn>
                            <!-- <v-btn color="amarillo" class="white--text" @click="orderSupply(orderToShow)">
                                {{ $t('external_stuck_orders.supply') }}
                            </v-btn> -->
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('external_stuck_orders.customer'), align: "center", value: 'source', class: 'text-uppercase' },
                { text: this.$t('external_stuck_orders.order'), align: "center", value: 'order_id', class: 'text-uppercase' },
                { text: this.$t('external_stuck_orders.date'), align: "center", value: 'created_at', class: 'text-uppercase' },
                { text: this.$t('external_stuck_orders.channel'), align: "center", value: 'channel', class: 'text-uppercase' },
                { text: this.$t('external_stuck_orders.observation'), align: "center", value: 'observation', class: 'text-uppercase' },
                { text: this.$t('external_stuck_orders.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDetail: false,
            modalDelete: false,
            orderToDelete: '',
            orderToShow: '',
            actions: [
            {
                    name: this.$t('external_stuck_orders.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                    permission: "stuck_external_orders.show"
                },
                {
                    name: this.$t('external_stuck_orders.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    link: '/stuck_external_orders/edit/1',
                    permission: "stuck_external_orders.show"
                },
                {
                    name: this.$t('external_stuck_orders.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                    permission: "stuck_external_orders.delete"
                }
            ],
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.external_stuck_orders";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'stuck_external_orders', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.statusText === "OK"){
                    response.data.forEach(x =>{
                        x.products = JSON.parse(x.products);
                        x.created_at = x.created_at.split("T")[0];
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x['actions'][1].link = "/stuck_external_orders/edit/" + x.id
                        x['parameters'] = x;
                        this.props.items.push(x);
                    });
                    
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        askDelete(order){
            this.modalDelete = true;
            this.orderToDelete = order;
        },
        showDetails(order){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'stuck_external_orders/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.orderToShow = {
                    order_id: response.data.order_id,
                    date: response.data.created_at.substring(0,10),
                    observation: response.data.observation,
                    source: response.data.source,
                    channel: response.data.channel,
                    products: JSON.parse(response.data.products)
                }
                this.$store.state.loaderProps.visible = false;
                this.modalDetail = true;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        deleteOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'stuck_external_orders/' + this.orderToDelete.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 204){
                    this.modalDelete = false;
                    this.index();
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 204){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    }
}
</script>

<style>

</style>