<template>
     <div class="py-0 mt-4 mb-8">
        <v-container class="mx-0 my-0" style="padding:0 !important; max-width: 100% !important">
            <v-card class="mt-1 pt-1 pb-10 px-0 size" style="margin-bottom: 35px !important">      
                <v-text-field v-model="props.search" class="px-2" append-icon="search" :label="$t('on_premises.search')" single-line hide-details></v-text-field>
                <span v-for="data in this.filteredArray" :key="data.id">
                    <v-col cols="6" class="px-0 py-0" style="display:inline-block !important">
                        <v-card class="mx-2 mt-4 px-3 py-2" :to="'/operation/process-pending-out/' + data.id + '/' + from + '/' + m_type" style="font-size:13px;" cols="6">
                            <div class="decoration_waitingOut"></div>
                            <v-col class="px-2 mx-0" cols="12" md="12" align="start">
                                <v-row>
                                    <v-col class="py-0 px-0" style="color:rgba(239, 72, 98, 1); font-weight: bold !important;" md="12"><span class="pr-1 secondary--text"></span> {{ data.client.name }}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="px-4 mx-0">
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{ $t('operation.appointment') }}: </span> {{ data.id }}
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{ $t('operation.container') }}: </span> {{ data.container_number }}
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">
                                            {{ $t('operation.schedule') }}: </span> 
                                            {{ data.arrival_date.substring(0,10) }}
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{ $t('operation.movement_type') }}: </span>
                                            <span v-if="$store.state.language == 'es'">{{ data.operation.description }}</span>
                                            <span v-if="$store.state.language == 'en'">{{ data.operation.description1 }}</span>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card>
                    </v-col>
                </span>
            </v-card>
            <bottom-bar ref="bottombar" :from="from" :m_type="m_type"></bottom-bar>
        </v-container>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="white" text @click="snackbar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>
<script>
export default {
  components: { },
    data(){
        return {
            from: this.$route.params.from,
            m_type: this.$route.params.m_type,
            page: 1,
            pages: 0,
            snackbar: {
                visible: false,
                color: 'primary',
                timeout: 2000,
                text: ''
            },
            filteredArray: [],
            props:{
                items: [],
                search: ''
            },
        }
    },
    mounted(){
        this.$store.state.module = "headers.waiting_output_operation";
        this.index();
        localStorage.setItem('bottom', 4);
        this.$store.state.bottom = 4;
    },
    methods: {
        handler(i, id){
            if(i == 0) this.edit(id);
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointmentsOperation/Pendiente de salida/' + this.from + '/' + this.m_type, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    this.props.items.push(x.attributes);
                })
                this.filteredArray = this.props.items;
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {this.$store.state.loaderProps.visible = false;});
        },
    },
    watch: {
        'props.search': function() {
            const sear = this.props.search.toString().toLowerCase();
            
            if(!sear) { return this.filteredArray = this.props.items; }
            this.filteredArray = this.props.items.filter((x)=> {
                if(x.id != null) if (x.id.toString().toLowerCase().includes(sear)) return x;
                if(x.container_number != null) if (x.container_number.toString().toLowerCase().includes(sear)) return x; 
                if(x.arrival_date != null) if (x.arrival_date.substring(0,10).toString().toLowerCase().includes(sear)) return x;
                if(x.operation != null) if (x.operation.description.toString().toLowerCase().includes(sear)) return x;
                if(x.operation != null) if (x.operation.description1.toString().toLowerCase().includes(sear)) return x;
                if(x.client != null) if (x.client.name.toString().toLowerCase().includes(sear)) return x;
            });
        }
    }
}
</script>

<style lang="css">
.decoration_waitingOut{
    background-color: rgba(239, 72, 98, 1);
    height: 100%;
    width: 10px;
    position:absolute;
    padding: 0;
    margin: 0;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 5px !important;
    left:-2px;
    top: 0;
}
.size{
    min-height: calc(100vh - 120px) !important;
    position:relative !important;
}
</style>