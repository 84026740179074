<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="2" class="py-0 my-0">
                    <v-autocomplete class="my-0 py-0" v-model="current_product" :items="products" item-value="id" item-text="sku" :label="$t('operations_per_product.sku')" :rules="rules_required"></v-autocomplete> 
                </v-col>
                <v-col cols="2" class="py-0">
                    <datepicker :open_dialog="menu" :model="initial_date" :label="$t('stock_report.initial_date')" @model="updateDate($event)" :rules_required="rules_required"></datepicker>
                </v-col>
                <v-col cols="2" class="py-0">
                    <datepicker :open_dialog="menu1" :model="final_date" :label="$t('stock_report.final_date')" @model="updateDateFinal($event)" :rules_required="rules_required"></datepicker>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="3" align="end" class="py-0 my-0">
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="exportData">
                        {{ $t('operations_per_product.export') }}
                    </v-btn>
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="generate">
                        {{ $t('operations_per_product.generate') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text >
                    <v-col class="py-0">
                        <v-row class="azul font-weight-bold text-uppercase white--text px-2 py-0">
                            <v-col cols="3" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('operations_per_customer.income') }}:</span> {{ summary.income }}</v-col>
                            <v-col cols="2" class="py-1"><span class="font-weight-bold text-u1ppercase">{{ $t('operations_per_customer.outcome') }}:</span> {{ summary.outcome }}</v-col>
                            <v-col cols="2" class="py-1" v-permission:any="'customer_fulfillment.show|advanced_customer_fulfillment.show|customer_all_access.show'"><span class="font-weight-bold text-uppercase">{{ $t('operations_per_customer.fulfillment') }}:</span> {{ summary.fulfillment }}</v-col>
                            <v-col cols="2" class="py-1" v-permission:any="'customer_fulfillment_eon.show|customer_all_access.show'"><span class="font-weight-bold text-uppercase">{{ $t('operations_per_customer.externals') }}:</span> {{ summary.external }}</v-col>
                            <v-col cols="3" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('operations_per_customer.returns') }}:</span> {{ summary.returns }}</v-col>           
                        </v-row>
                    </v-col>
                </v-card-text>

                <v-card-text class="pt-0 pb-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('operations_per_product.operation_type'), align: "center", value: 'type', class: 'text-uppercase' },
                { text: this.$t('operations_per_product.date'), align: "center", value: 'date', class: 'text-uppercase' },
                { text: this.$t('operations_per_product.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('operations_per_product.damaged'), align: "center", value: 'damaged', class: 'text-uppercase' },
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            products: [],
            current_product: '',
            initial_date: '',
            final_date: '',
            report:{
                initial_date: '',
                final_date: ''
            },
            menu: false,
            menu1: false,
            summary: {
                income: 0,
                outcome: 0,
                fulfillment: 0,
                returns: 0,
                external: 0
            },
            fulfillment_dates: [],
            external_dates: [],
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.operations_by_product_customer";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        updateDate(newDate){
            this.initial_date = newDate;
        },
        updateDateFinal(date){
            this.final_date = date;
        },
        index(){
            this.props.items = [];
            this.products = [];
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'products/customer/' + this.$store.state.clientUserId, { headers: {Authorization: lsToken} })
            .then((response) => {
                response.data.products.data.forEach(x => {
                    x = x.attributes;
                    this.products.push({
                        sku: x.sku,
                        id: x.id
                    });
                })
            })
            .catch((error) => {
                error
            })
        },
        generate(){
            this.props.items = [];
            this.external_dates = [];
            this.fulfillment_dates = [];
            this.summary = {
                income: 0,
                outcome: 0,
                fulfillment: 0,
                returns: 0,
                external: 0
            };

            const show_fulfillment = this.$store.state.loggedUserPermissions.includes("customer_fulfillment.show") || this.$store.state.loggedUserPermissions.includes("advanced_customer_fulfillment.show") || this.$store.state.loggedUserPermissions.includes("customer_all_access.show")
            const show_fulfillment_eon = this.$store.state.loggedUserPermissions.includes("customer_fulfillment_eon.show") || this.$store.state.loggedUserPermissions.includes("customer_all_access.show") 
            
            if(this.$store.state.warehouseId != "" && this.$store.state.clientUserId != "" && this.initial_date != "" && this.final_date != "" && this.current_product != ""){

                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'reports/operationsByProduct?warehouse='+ this.$store.state.warehouseId + '&client='+ this.$store.state.clientUserId + '&product=' + this.current_product + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data.data.forEach(x => {
                        x = x.attributes;
                        if (x.type == "Fulfillment" && show_fulfillment) {
                            this.summary.fulfillment++; 
                            if (!this.fulfillment_dates.includes(x.date)){
                                this.fulfillment_dates.push(x.date);
                                this.props.items.push(x);
                            }
                            else this.props.items.find(item => (item.date == x.date && item.type == "Fulfillment")).quantity += x.quantity;
                        } 
                        else if(x.type == "FFX" && show_fulfillment_eon){
                            this.summary.external++; 
                            if (!this.external_dates.includes(x.date)){
                                this.external_dates.push(x.date);
                                this.props.items.push(x);
                            }
                            else this.props.items.find(item => (item.date == x.date && item.type == "FFX")).quantity += x.quantity;
                        }
                        else if(x.type == "Entrada" || x.type == "Salida" || x.type == "Retorno"){
                            if(x.type == "Entrada") this.summary.income++;
                            else if(x.type == "Salida") this.summary.outcome++;
                            else if(x.type == "Retorno") this.summary.returns++;
                            
                            this.props.items.push(x);
                        }   
                    })
                })
                .catch((response) => {
                    console.log(response)
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportData(){
            if(this.$store.state.warehouseId != "" && this.$store.state.clientUserId != "" && this.initial_date != "" && this.final_date != "" && this.current_product != ""){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'reports/operationsByProductExport?warehouse='+ this.$store.state.warehouseId + '&client='+ this.$store.state.clientUserId + '&product=' + this.current_product + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Operaciones_por_producto.xlsx');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        }
    },
}
</script>
<style>
</style>