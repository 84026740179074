<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_stuck_external_order.edit') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="stuck_order.order_id" :label="$t('edit_stuck_external_order.order_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="4" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product_selected.sku" :loading="products_load" :items="products" :label="$t('edit_stuck_external_order.product')"></v-autocomplete> 
                            </v-col>
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="product_selected.quantity" :label="$t('edit_stuck_external_order.quantity')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0" justify="end" align="end">
                                <v-btn class="right white--text" color="amarillo" @click="addProduct" >
                                    {{ $t('edit_stuck_external_order.add') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <datatable :income_editable="true" :unit_price_editable="true" class="custom_table_class" :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_stuck_external_order.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save">
                                {{ $t('edit_stuck_external_order.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
        headers(){ 
            return [
                { text: this.$t('edit_stuck_external_order.product'), value: 'sku', class: 'text-uppercase', width:'50%' },
                { text: this.$t('edit_stuck_external_order.quantity'), value: 'quantity', class: 'text-uppercase', width:'20%' },
                { text: '', value: 'actions', class: 'text-uppercase', width:'10%' },
            ]
        } 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            client_token: "",
            stuck_order: {
                order_id: "",
                products: []
            },
            products_load: false,
            products: [],
            props: {
                items: []
            },
            product_selected: {
                sku: "",
                quantity: ""
            },
            actions: [
                {
                    name: this.$t('edit_stuck_external_order.delete_product'),
                    icon: {
                        color: 'error',
                        icon: 'disabled_by_default'
                    },
                    method: 'deleteProduct',
                }
            ],
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_external_stuck_order";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        index(){
            this.products = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'stuck_external_orders/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.stuck_order = response.data;
                JSON.parse(this.stuck_order.products).forEach(x => {
                    x.actions = JSON.parse(JSON.stringify(this.actions))
                    x.parameters = x;
                    this.props.items.push(x);
                })
                this.client_token = JSON.parse(this.stuck_order.additional_information).client_token;

                this.$http.get(this.$store.state.chronosApi + '/getProductsByToken/' + this.client_token, {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data.data.forEach(x => {
                        this.products.push(x.attributes.sku);
                    })
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.props.items.forEach(x => {
                delete x.actions;
                delete x.parameters;
            })
            this.stuck_order.products = JSON.stringify({ products: this.props.items })
            
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.post(this.$store.state.eonShopsApi + 'api/update_order/update', this.stuck_order, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.$http.delete(this.$store.state.eonShopsApi + 'stuck_external_orders/' + this.id, {headers: {Authorization: lsToken}})
                .then((result) => {
                    result;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
                response;
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_stuck_order.responses.success')
                }

                this.$router.go(-1);
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        addProduct(){
            this.props.items.push({ 
                sku: this.product_selected.sku, 
                quantity: this.product_selected.quantity,
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: {
                    sku: this.product_selected.sku, 
                    quantity: this.product_selected.quantity
                }
            });

            this.product_selected.sku = "";
            this.product_selected.quantity = "";
        },
        cancel(){
            this.$router.go(-1);
        },
        deleteProduct(x){
            var result = this.props.items.findIndex(obj => obj.sku == x.sku && obj.quantity == x.quantity);
            this.props.items.splice(result, 1);
        }
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>