<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center" v-if="from == 'international'">    
                        <h3 class="azul--text text-uppercase" v-if="m_type == 'income'">{{ $t('create_appointment.import') }} </h3>
                        <h3 class="azul--text text-uppercase" v-else>{{ $t('create_appointment.export') }} </h3>                    
                    </v-col>
                    <v-col cols="12" align="center" justify="center" v-else>    
                        <h3 class="azul--text text-uppercase" v-if="m_type == 'income'">{{ $t('create_appointment.income') }} </h3>
                        <h3 class="azul--text text-uppercase" v-else>{{ $t('create_appointment.outcome') }} </h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <client-selector class="py-0" ref="clientSelector" @getClients="v => items.clients = v" :is_national="from == 'national' ? 1 : 0" :rules="rules_required"></client-selector>  
                            </v-col>
                        </v-row>
                        
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="from == 'international'">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :readonly="from == 'national'" v-model="appointment.operation_id" :items="operations" item-value="id" item-text="description" :label="$t('create_appointment.operation')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <datepicker :open_dialog="menu" :model="appointment.arrival_date" :label="$t('create_appointment.estimated_date')" @model="updateDate" :rules_required="rules_required"></datepicker>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="this.from == 'international' && ((this.appointment.operation_id == 1 || this.appointment.operation_id == 3 || this.appointment.operation_id == 7 || this.appointment.operation_id == 9) && this.appointment.operation_id != '')">
                            <v-col cols="6" class="py-0">
                                <datepicker :open_dialog="menu2" :model="appointment.eta" @model="updateDateEta" :label="$t('create_appointment.estimated_date_eta')" :rules_required="rules_required"></datepicker>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="crossdockDocumentalSalida">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="appointment.formato_entrada" :label="$t('create_appointment.formato_entrada')" :rules="rules_number"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="appointment.container_number" :label="$t('create_appointment.container')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="appointment.description" :label="$t('create_appointment.description')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="appointment.commentary" :label="$t('create_appointment.comments')"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-spacer></v-spacer> 
                        <v-col cols="6">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_appointment.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'appointment.create'">
                                {{ $t('create_appointment.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        client: function(){
            return this.$store.state.clientId
        },
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        rules_number(){ return [
                v => v == null || /^(|\d)+$/.test(v) || this.$t('edit_appointment.validations.numeric'),
                v => !!v || this.$t('create_appointment.validations.required_field')
        ]}
    },
    data(){
        return {
            from: this.$route.params.from, // national || international
            m_type: this.$route.params.m_type, // income || outcome
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            menu: false,
            menu2: false,
            operations: [],
            movements: [],
            origins: [
                "Nacional",
                "Internacional"
            ],
            appointment:{
                client_id: "",
                arrival_date: "",
                eta: "",
                operation_id: "",
                is_national: "",
                container_number: "",
                description: "",
                commentary: "",
                status: "Pendiente"
            },
            pushto: '/national-pendings/income',
            crossdockDocumentalSalida: false
        }
    },
    mounted(){
        if(this.from == 'national') {
            if(this.m_type == "income"){
                this.appointment.is_national = 1;
                this.appointment.operation_id = 5;
                this.pushto = "/national-pendings/income";
            } else {
                this.appointment.is_national = 1;
                this.appointment.operation_id = 6;
                this.pushto = "/national-pendings/outcome";
            }
        }
        else {
            if(this.m_type == 'income') this.pushto = "/international-pendings/income";
            else if(this.m_type == 'outcome') this.pushto = "/international-pendings/outcome";
            this.appointment.is_national = 0;
        }
        this.index();
        this.$store.state.module = "headers.create_new";
    },
    methods: {
        updateDate(newDate){
            this.appointment.arrival_date = newDate;
        },
        updateDateEta(newDate){
            this.appointment.eta = newDate;
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'operations', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.forEach(x => {
                    if(this.from == 'international' && x.id != 5 && x.id != 6){
                        if(this.m_type == "income" && x.id % 2 != 0)
                            this.operations.push({id: x.id, description: this.$store.state.language == 'es' ? x.description : x.description1, format_type: x.format_type});
                        else if(this.m_type == "outcome" && x.id % 2 == 0)
                            this.operations.push({id: x.id, description: this.$store.state.language == 'es' ? x.description : x.description1, format_type: x.format_type});
                    }
                        
                    else if(this.from == 'national' && (x.id == 5 || x.id == 6)) 
                        this.operations.push({id: x.id, description: this.$store.state.language == 'es' ? x.description : x.description1, format_type: x.format_type});
                })
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        save(){
            this.appointment.client_id = this.$store.state.clientId; 
            const formVal = this.$refs.form.validate();

            if(formVal){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.post(this.$store.state.chronosApi + 'appointments', this.appointment, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 201){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('create_appointment.responses.ok')
                        }

                        this.$router.push(this.pushto);
                    }
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('create_appointment.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else{
                this.$store.state.loaderProps.visible = false
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('create_appointment.form_validation')
                }
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        '$route.params.from': function() {
            location.reload();
        },
        '$route.params.m_type': function() {
            location.reload();
        }
    }
}
</script>

<style lang="css">
</style>