<template>
	<v-bottom-navigation class="v-footer" v-model="value" color="amarillo" dark shift>
		<v-btn class="btn" @click="pendings" v-permission:any="'op_pendings.index|op_pendings.show'"> {{ $t('footer.pendings') }} </v-btn>
		<v-btn class="btn" @click="on_premises" v-permission:any="'op_on_premises.index|op_on_premises.show'"> {{ $t('footer.on_premises') }} </v-btn>
		<v-btn class="btn" @click="on_ramp" v-permission:any="'op_on_premises.index|op_on_premises.show'"> {{ $t('footer.on_ramp') }} </v-btn>
		<v-btn class="btn" @click="processing" v-permission:any="'op_in_process.index|op_in_process.show'"> {{ $t('footer.processing') }} </v-btn>
		<v-btn class="btn" @click="output" v-permission:any="'op_pending_out.index|op_pending_out.show'"> {{ $t('footer.output') }} </v-btn>
	</v-bottom-navigation>
</template>

<script>
	export default {
		name: 'BarraInferior',
		props: [
            'from',
			'm_type'
        ],
		data () {
			return {
				value: this.$store.state.bottom
			}
		},
		mounted(){
		},
		methods:{
			pendings(){
				localStorage.setItem('bottom', 0);
				this.$store.state.bottom = 0;
				this.$router.push('/operation/pendings/' + this.from + '/' + this.m_type);
			},
			on_premises(){
				localStorage.setItem('bottom', 1);
				this.$store.state.bottom = 1;
				this.$router.push('/operation/on-premises/' + this.from + '/' + this.m_type);			
			},
			on_ramp(){
				localStorage.setItem('bottom', 2);
				this.$store.state.bottom = 2;
				this.$router.push('/operation/on-ramp/' + this.from + '/' + this.m_type);
			},
			processing(){
				localStorage.setItem('bottom', 3);
				this.$store.state.bottom = 3;
				this.$router.push('/operation/in-process/' + this.from + '/' + this.m_type);		 	
			},
			output(){
				localStorage.setItem('bottom', 4);
				this.$store.state.bottom = 4;
				this.$router.push('/operation/pending-out/' + this.from + '/' + this.m_type);			
			}
		},
		watch: {
			'$store.state.bottom': function(){
				this.value =  this.$store.state.bottom;
			}
		}
	};
</script>
<style lang="css">
	.v-footer{
		position: absolute !important;
		height: 35px !important;
		margin: 0 0.75em;
		bottom: 35px !important;
		min-width: calc(100vw - (220px + 0.375em));
		width: calc(100vw - (220px + 0.375em)) !important;
	}
	
	.btn{
		height: 25px !important;
		padding: 0px;
		margin: 0px;
	}

</style>