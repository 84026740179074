<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="2" class="py-0">
                    <datepicker :open_dialog="menu" :model="initial_date" :label="$t('annex_documents.initial_date')" @model="updateInitialDate($event)" :rules_required="rules_required"></datepicker>
                </v-col>
                <v-col cols="2" class="py-0">
                    <datepicker :open_dialog="menu1" :model="final_date" :label="$t('annex_documents.final_date')" @model="updateFinalDate($event)" :rules_required="rules_required"></datepicker>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="exportData">
                        {{ $t('annex_documents.export') }}
                    </v-btn>
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="generate">
                        {{ $t('annex_documents.generate') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('annex_documents.user'), align: "center", value: 'user_name', class: 'text-uppercase' },
                { text: this.$t('annex_documents.appointments_created'), align: "center", value: 'appointments_created', class: 'text-uppercase' },
                { text: this.$t('annex_documents.doc_complete'), align: "center", value: 'doc_complete', class: 'text-uppercase' },
                { text: this.$t('annex_documents.doc_incomplete'), align: "center", value: 'doc_incomplete', class: 'text-uppercase' },
                { text: this.$t('annex_documents.empty_doc'), align: "center", value: 'empty_doc', class: 'text-uppercase' },
                { text: this.$t('annex_documents.complete_foreign'), align: "center", value: 'complete_foreign', class: 'text-uppercase' },
                { text: this.$t('annex_documents.incomplete_foreign'), align: "center", value: 'incomplete_foreign', class: 'text-uppercase' },
                { text: this.$t('annex_documents.empty_foreign'), align: "center", value: 'empty_foreign', class: 'text-uppercase' },
                { text: this.$t('annex_documents.effectiveness'), align: "center", value: 'effectiveness', class: 'text-uppercase' },
            ]
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            initial_date: '',
            final_date: '',
            report:{
                initial_date: '',
                final_date: ''
            },
            menu: false,
            menu1: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.annex_documents_report";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        updateInitialDate(value){
            this.initial_date = value;
        },
        updateFinalDate(value){
            this.final_date = value;
        },
        index(){
            this.props.items = [];
        },
        generate(){
            this.props.items = [];
            if( this.initial_date != "" && this.final_date != ""){

                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'reports/annexDocuments?warehouse='+ this.$store.state.warehouseId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}})
                .then((response) => {
                    var t = this;
                    Object.keys(response.data.data).forEach(function (key) { 
                        var x = {
                            user_name: response.data.data[key].user_name,
                            appointments_created: response.data.data[key].appointments_created,
                            doc_complete: response.data.data[key].doc_complete,
                            doc_incomplete: response.data.data[key].doc_incomplete,
                            empty_doc: response.data.data[key].empty_doc,
                            empty_foreign: response.data.data[key].empty_foreign,
                            complete_foreign: response.data.data[key].complete_foreign,
                            incomplete_foreign: response.data.data[key].incomplete_foreign,
                            effectiveness: ((100 / response.data.data[key].appointments_created) * response.data.data[key].doc_complete).toFixed(2) + "%"
                        };
                        
                        t.props.items.push(x);
                    })
                })
                .catch((response) => {
                    console.log(response);
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportData(){
            if( this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;

                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'reports/annexDocumentsExport?warehouse='+ this.$store.state.warehouseId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Documentos_Anexo_24.xlsx');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        }
    }
}
</script>

<style>
</style>