<template>
    <div class="py-0 mt-4 mb-8">
        <v-container class="mx-0 my-0" style="padding:0 !important; max-width: 100% !important">
            <v-card class="mt-1 pt-1 pb-10 px-0 size" style="margin-bottom: 35px !important" align="center">
                <v-row class="px-0 py-4 text-center" justify="center" align="center">
                    <v-col cols="6" class="py-1" justify="center" align="center">
                        <span justify="center" class="pr-1 azul--text font-weight-bold" style="font-size:18px">
                            {{ $t('register_in_process.start_to_process') }} 
                        </span> 
                    </v-col>
                </v-row>
                <v-col cols="6" justify="start" align="start" class="px-0">
                    <v-row>
                        <v-col cols="6" class="py-1">
                            <span justify="center" class="pr-1 azul--text font-weight-bold" style="font-size:18px">
                                {{ $t('register_in_process.general_data') }} 
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.appointment') }}: </span> {{ this.id }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.client') }}: </span> {{ this.appointment.client.name }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.estimated_date_of_arriving') }}: </span> {{ this.appointment.arrival_date }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="$store.state.language == 'es'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.operation') }}: </span> {{ this.appointment.operation.description }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="$store.state.language == 'en'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.operation') }}: </span> {{ this.appointment.operation.description1 }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.container') }}: </span> {{ this.appointment.container_number }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.description') }}: </span> {{ this.appointment.description }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.comments') }}: </span> {{ this.appointment.commentary }} </v-col>
                    </v-row>
                </v-col>
                <v-col class="py-5 px-0" cols="6" justify="start" align="start">
                    <v-row>
                        <v-col cols="12" class="py-1">
                            <span justify="center" class="pr-1 azul--text font-weight-bold" style="font-size:18px">
                                {{ $t('register_in_process.products') }} 
                            </span>
                        </v-col>
                    </v-row>
                    <datatable :props="{headers:headers_sku, items: appointment.products}" @methodHandler="methodHandler"></datatable>
                </v-col>
                <v-col class="py-5 px-0" cols="6" justify="start" align="start">
                    <v-row>
                        <v-col cols="12" class="py-1">
                            <span justify="center" class="pr-1 azul--text font-weight-bold" style="font-size:18px">
                            {{ $t('register_in_process.transport_details') }} 
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-form ref="form" lazy-validation @submit.prevent="update">
                    <v-col cols="6" align="center" justify="center" class="px-0 py-0">
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-col cols="12" class="py-0">
                                <v-autocomplete :items="tipos_carga" v-model="appointment.cargo_type" :rules="rules_required" :label="$t('register_in_process.type_of_cargo')"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-3 pb-2" justify="start" align="center">
                            <v-col cols="8" class="px-0 py-0" justify="left" align="left">
                                <span >{{ $t('register_in_process.merchandise_good_conditions') }}:</span>
                            </v-col>
                            <v-col cols="4" class="px-0 py-0">
                                <v-btn class="radio-left" v-bind:color="appointment.merchandise_good_conditions == 0 ? 'info' : 'white'" @click="appointment.merchandise_good_conditions = 0">{{ $t('register_in_process.false') }}</v-btn>
                                <v-btn class="radio-right" v-bind:color="appointment.merchandise_good_conditions == 1? 'info' : 'white'" @click="appointment.merchandise_good_conditions = 1">{{ $t('register_in_process.true') }}</v-btn>
                            </v-col>
                        </v-row> 
                        <v-row class="px-3 pb-2" justify="start" align="center">
                            <v-col cols="8" class="px-0 py-0" justify="left" align="left">
                                <span>{{ $t('register_in_process.packaging_complies_conditions') }}:</span>
                            </v-col>
                            <v-col cols="4" class="px-0 py-0">
                                <v-btn class="radio-left" v-bind:color="appointment.packaging_complies_conditions == 0 ? 'info' : 'white'" @click="appointment.packaging_complies_conditions = 0">{{ $t('register_in_process.false') }}</v-btn>
                                <v-btn class="radio-right" v-bind:color="appointment.packaging_complies_conditions == 1? 'info' : 'white'" @click="appointment.packaging_complies_conditions = 1">{{ $t('register_in_process.true') }}</v-btn>
                            </v-col>    
                        </v-row>
                    </v-col>
                </v-form>
                <v-row align="center" justify="center">
                    <v-col class="mb-1" cols="6" align="end" justify="center">
                        <v-btn color="white" class="mr-2" @click="cancel"> {{ $t('register_in_process.cancel') }}</v-btn>
                        <v-btn color="amarillo" outlined class="white--text mr-2" @click="toOnPremises" v-permission="'op_on_premises.update'"> {{ $t('register_in_process.remove_of_portal') }}</v-btn>
                        <v-btn color="amarillo" class="white--text" :disabled="appointment.status != 'En rampa' || !can_continue" @click="update" v-permission="'op_on_premises.update'"> {{ $t('register_in_process.process_container') }}</v-btn>
                    </v-col>  
                </v-row>
            </v-card>
            <bottom-bar ref="bottombar" :from="from"></bottom-bar>
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        headers_sku: function(){
            return [
                {text: this.$t('process_pendings.sku'), value: 'sku_p', class: 'text-uppercase'},
                // {text: this.$t('edit_appointment.description'), value: 'descripcion', class: 'text-uppercase'},
                {text: this.$t('process_pendings.quantity'), value: 'quantity', class: 'text-uppercase'},
                {text: this.$t('process_pendings.unit'), value: 'unit', class: 'text-uppercase'}
            ];
        },
        rules_required(){return [v => !!v || this.$t('edit_appointment.validations.required_field')]}, 
        numeric(){return [v => /^(|\d)+$/.test(v) || this.$t('register_in_process.validations.numbers'),];} 
    },
    data(){
        return {
            id: this.$route.params.id,
            from: this.$route.params.from,
            m_type: this.$route.params.m_type,
            snackbar: {
                visible: false,
                color: 'primary',
                timeout: 2000,
                text: ''
            },
            tipos_carga: [
                { text: this.$t("register_in_process.forklift"), value: "MONTACARGA" },
                { text: this.$t("register_in_process.manually"), value: "MANUAL" },
                { text: this.$t("register_in_process.crane"), value: "GRUA" },
                { text: this.$t("register_in_process.none"), value: "NINGUNA" }
            ],
            modulation: false,
            appointment:{
                // General data
                client_id: "",
                client: {
                    name:""
                },
                arrival_date: "",
                eta: "",
                operation_id: "",
                operation: {
                    description: "",
                    description1: ""
                },
                container_number: "",
                description: "",
                commentary: "",
                additional_services: "",
                purchase_order: "",

                // Transport details
                carrier_line: "",
                drivers_name: "",
                license_plate: "",
                box: "",
                tractor: "",
                status: "",
                consignment_note: "",
                cargo_type: "",
                transport_type: "",
                
                // Foreign trade
                origin_supplier_id: "",
                patent_number: "",
                modulation_status: "",
                modulation_date: "",
                modulation_time: "",
                bill_of_lading: "",
                remittance: "",
                petition: "",
                notices: "",
                entry_petition: "",

                merchandise_good_conditions: 1,
                packaging_complies_conditions: 1,

                products: []
            },
            movements: [],
            items_g: [],
            racks: [],
            can_continue: true
        }
    },
    mounted(){
        localStorage.setItem('bottom', 2);
        this.$store.state.bottom = 2;
        this.$store.state.module = "headers.in_process_operation";
        this.index();
    },
    methods: {
        cancel(){
            this.$router.push('/operation/on-ramp/' + this.from + '/' + this.m_type);
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointments/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data = response.data.data.attributes;

                    if(response.data.warehouse.picking_required && response.data.operation.format_type == "Salida"){
                        if(response.data.already_picked == null || response.data.already_picked == undefined || response.data.already_picked == false) this.can_continue = false;
                    }
                    // products and Details
                    if(response.data.operation.format_type == "Entrada"){
                        if(response.data.unknown_products == true){
                            if(response.data.standby_products[0].attributes.unknownProducts.boxes > 0){
                                this.appointment.products.push({
                                    sku_p: this.$t('process_pendings.boxes'),
                                    quantity: response.data.standby_products[0].attributes.unknownProducts.boxes
                                })
                            }
                            else if(response.data.standby_products[0].attributes.unknownProducts.pallets > 0){
                                this.appointment.products.push({
                                    sku_p: this.$t('process_pendings.pallets'),
                                    quantity: response.data.standby_products[0].attributes.unknownProducts.pallets
                                })
                            }
                        }
                        else{
                            response.data.standby_products.forEach(x => 
                            {
                                x = x.attributes;
                                var p = {};
                                p = {
                                    sku_p: x.product.sku,
                                    quantity: x.quantity,
                                    unit: x.product.unit
                                }
                                            
                                this.appointment.products.push(p);
                            });
                        }
                    } 
                    else if(response.data.operation.format_type == "Salida"){
                        response.data.detail_orders.forEach(x => 
                        {
                            x = x.attributes;
                            var p = {};
                            p = {
                                sku_p: x.product.sku,
                                quantity: x.expected,
                                unit: x.product.unit
                            }
                                        
                            this.appointment.products.push(p);
                        });
                    }

                    // General Data
                    this.$store.state.clientId              = response.data.client.id ?? null;
                    this.appointment.client_id              = response.data.client.id ?? null;
                    this.appointment.client                 = response.data.client ?? null;
                    this.appointment.operation              = response.data.operation ?? null;
                    this.appointment.arrival_date           = response.data.arrival_date.substring(0, 10) ?? null;
                    if(response.data.is_national == true && response.data.eta != undefined && response.data.eta != null)
                        this.appointment.eta                = response.data.eta.substring(0, 10);
                    this.appointment.operation_id           = response.data.operation.id ?? null;
                    this.appointment.additional_services    = response.data.additional_services ?? null;
                    this.appointment.purchase_order         = response.data.purchase_order ?? null;
                    this.appointment.container_number       = response.data.container_number ?? null;
                    this.appointment.description            = response.data.description ?? null;
                    this.appointment.commentary             = response.data.commentary ?? null;
                    this.appointment.status                 = response.data.status ?? null;
 
                    // Transport Details
                    this.appointment.carrier_line           = response.data.carrier_line ?? null;
                    this.appointment.drivers_name           = response.data.drivers_name ?? null;
                    this.appointment.license_plate          = response.data.license_plate ?? null;
                    this.appointment.tractor                = response.data.tractor ?? null;
                    this.appointment.consignment_note       = response.data.consignment_note ?? null;
                    this.appointment.cargo_type             = response.data.cargo_type ?? null;
                    this.appointment.transport_type         = response.data.transport_type ?? null;
                    
                    // Foreign Trade
                    if(response.data.is_national == false){
                        this.modulation = true;
                        this.appointment.origin_supplier_id     = response.data.origin_supplier_id ?? null;
                        this.appointment.patent_number          = response.data.patent_number ?? null;    
                        this.appointment.modulation_status      = response.data.modulation_status ?? null;
                        this.appointment.modulation_date        = response.data.modulation_date ?? null;
                        this.appointment.modulation_time        = response.data.modulation_time ?? null;
                        this.appointment.bill_of_lading         = response.data.bill_of_lading ?? null;
                        this.appointment.remittance             = response.data.remittance ?? null;
                        this.appointment.petition               = response.data.petition ?? null;
                        this.appointment.notices                = response.data.notices ?? null;
                        this.appointment.entry_petition         = response.data.entry_petition ?? null;
                    }
                }

                this.$http.get(this.$store.state.chronosApi + 'getLocations/' + response.data.operation.id + '/' + response.data.client.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        response.data.forEach(x =>{
                            this.racks.push({
                                id: x.id,
                                name: x.name
                            });
                        });
                    }
                });       
            })
            .catch((response) => {              
                this.$store.state.loaderProps.visible = false;
                console.log(response)
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        update(){
            const valid = this.$refs.form.validate();
            
            if(valid){
                this.appointment.newStatus = 'Procesando';
                this.appointment.status = 'Procesando';
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'setStatus/' + this.id, this.appointment, {headers: {Authorization: lsToken}})
                .then((response) => {   
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('register_in_process.responses.correct')
                        } 
                        this.$router.push('/operation/in-process/' + this.from + '/' + this.m_type);
                    }
                }).catch((error) => {
                    console.log(error);
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('register_in_process.responses.update')
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });  
            } else {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('process_pendings.missing_required_fields')
                }
            }
        },
        toOnPremises(){
            this.appointment.newStatus = 'En recinto';
            this.appointment.status = 'En recinto';
            this.appointment.gate_id = null;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.put(this.$store.state.chronosApi + 'setStatus/' + this.id, this.appointment, {headers: {Authorization: lsToken}})
            .then((response) => {   
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('register_in_process.responses.correct')
                    } 
                    this.$router.push('/operation/on-premises/' + this.from + '/' + this.m_type);
                }
            }).catch((error) => {
                console.log(error);
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('register_in_process.responses.update')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
    },
    watch:{
    }
}
</script>