<template>
    <div class="py-0 mt-4 mb-8">
        <v-container class="mx-0 my-0" style="padding:0 !important; max-width: 100% !important">
            <v-card class="mt-1 pt-1 pb-10 px-0 size" style="margin-bottom: 35px !important">
                <v-text-field v-model="props.search" class="px-2" append-icon="search" :label="$t('on_premises.search')"
                    single-line hide-details></v-text-field>
                <span v-for="data in this.filteredArray" :key="data.id">
                    <v-col cols="6" class="px-0 py-0" style="display:inline-block !important">
                        <v-card class="mx-2 mt-4 px-3 py-2" :to="data.link" style="font-size:13px;" cols="6">
                            <div class="decoration_bussy" v-if="data.status == 'bussy'"></div>
                            <div class="decoration_free" v-if="data.status == 'free'"></div>
                            <div class="decoration_process" v-if="data.status == 'process'"></div>
                            <div class="decoration_unprocessable" v-if="data.status == 'unprocessable'"></div>

                            <v-col class="px-2 mx-0" cols="12" md="12" align="start">
                                <v-row>
                                    <v-col v-if="data.status == 'free'" class="py-0 px-0 text-uppercase"
                                        style="color:rgb(186, 214, 0); font-weight: bold !important;" md="12"><span
                                            class="pr-1 secondary--text"></span> {{ data.name }}</v-col>
                                    <v-col v-if="data.status == 'bussy'" class="py-0 px-0 text-uppercase"
                                        style="color:rgb(252, 188, 16); font-weight: bold !important;" md="12"><span
                                            class="pr-1 secondary--text"></span>{{ data.name }} ({{ data.cliente
                                        }})</v-col>
                                    <v-col v-if="data.status == 'process'" class="py-0 px-0 text-uppercase"
                                        style="color:#de0000; font-weight: bold !important;" md="12"><span
                                            class="pr-1 secondary--text"></span>{{ data.name }} ({{ data.cliente
                                        }})</v-col>
                                    <v-col v-if="data.status == 'unprocessable'" class="py-0 px-0 text-uppercase"
                                        style="color:#444444; font-weight: bold !important;" md="12"><span
                                            class="pr-1 secondary--text"></span>{{ data.name }} ({{ data.cliente
                                        }})</v-col>
                                </v-row>
                                <v-row v-if="data.status == 'bussy'">
                                    <v-col cols="6" class="px-4 mx-0">
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{
                                                $t('operation.appointment') }}: </span> {{ data.id }}
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{
                                                $t('operation.container') }}: </span> {{ data.bol }}
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">
                                                {{ $t('operation.schedule') }}: </span>
                                            {{ data.fecha_registro }}
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{
                                                $t('operation.operation') }}: </span><span>{{ data.maniobras
                                                }}</span>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-row v-if="data.status == 'free'">
                                    <v-col cols="6" class="px-4 mx-0">
                                        <v-row>
                                            <span style="color:rgb(186, 214, 0) !important;"
                                                class="pr-1 secondary--text text-uppercase font-weight-bold">{{
                                                $t('on_premises_mobile.free') }}</span>
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold"><br></span>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold"><br></span>
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold"><br></span>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-row v-if="data.status == 'process'">
                                    <v-col cols="6" class="px-4 mx-0">
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{
                                                $t('operation.appointment') }}: </span> {{ data.id }}
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{
                                                $t('operation.container') }}: </span> {{ data.bol }}
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">
                                                {{ $t('operation.schedule') }}: </span>
                                            {{ data.fecha_registro }}
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{
                                                $t('operation.operation') }}: </span><span>{{ data.maniobras
                                                }}</span>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-row v-if="data.status == 'unprocessable'">
                                    <v-col cols="6" class="px-4 mx-0">
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{
                                                $t('operation.appointment') }}: </span> {{ data.id }}
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{
                                                $t('operation.container') }}: </span> {{ data.bol }}
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">
                                                {{ $t('operation.schedule') }}: </span>
                                            {{ data.fecha_registro }}
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 secondary--text font-weight-bold">{{
                                                $t('operation.operation') }}: </span><span>{{ data.maniobras
                                                }}</span>
                                        </v-row>
                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-card>
                    </v-col>
                </span>
            </v-card>
            <bottom-bar ref="bottombar" :from="from" :m_type="m_type"></bottom-bar>
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{
            snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {
            from: this.$route.params.from,
            m_type: this.$route.params.m_type,
            page: 1,
            pages: 0,
            snackbar: {
                visible: false,
                color: 'primary',
                timeout: 2000,
                text: ''
            },
            filteredArray: [],
            props: {
                items: [],
                portals: [],
                search: '',
            },
        }
    },
    mounted() {
        localStorage.setItem('bottom', 2);
        this.$store.state.module = "headers.on_premises_operation";
        this.$store.state.bottom = 2;
        this.index();
    },
    methods: {
        handler(i, id) {
            if (i == 0) this.edit(id);
        },
        index() {
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointmentsOperation/En rampa/' + this.from + '/' + this.m_type, { headers: { Authorization: lsToken } })
                .then((response) => {
                    if (response.status === 200) {
                        response.data.data.forEach(x => {
                            this.props.items.push(x.attributes);
                        })
                        this.filteredArray = this.props.items;

                        this.$http.get(this.$store.state.chronosApi + 'gates', { headers: { Authorization: lsToken } })
                            .then((response) => {
                                response.data.data.forEach(x => {
                                    x = x.attributes;
                                    const appointment = this.props.items.find(item => {
                                        if (item.gate_id != null && item.gate_id != undefined) {
                                            if (item.gate_id == x.id) return item;
                                        }
                                    });

                                    if (appointment != null && appointment != undefined) {
                                        if(this.from == "national" && this.m_type == "income"){
                                            if(appointment.is_national == true && appointment.operation.id == 5) this.drawProcessableAppointment(x, appointment);
                                            else this.drawUnprocessableAppointment(x, appointment);
                                        }
                                        else if(this.from == "national" && this.m_type == "outcome"){
                                            if(appointment.is_national == true && appointment.operation.id == 6) this.drawProcessableAppointment(x, appointment);
                                            else this.drawUnprocessableAppointment(x, appointment);
                                        }
                                        else if(this.from == "international" && this.m_type == "income"){
                                            if(appointment.is_national == false && appointment.operation.id % 2 != 0) this.drawProcessableAppointment(x, appointment);
                                            else this.drawUnprocessableAppointment(x, appointment);
                                        }
                                        else if(this.from == "international" && this.m_type == "outcome"){
                                            if(appointment.is_national == false && appointment.operation.id % 2 == 0) this.drawProcessableAppointment(x, appointment);
                                            else this.drawUnprocessableAppointment(x, appointment);
                                        }
                                    }
                                    else {
                                        x.id = x.id + ' ';
                                        x['status'] = 'free';
                                        this.props.portals.push(x);
                                    }

                                });
                                this.filteredArray = this.props.portals;
                            }).catch((response) => {
                                if (response.status !== 200) {
                                    this.snackbar = {
                                        visible: true,
                                        color: 'red',
                                        text: this.$t('on_premises_mobile.responses.error')
                                    }
                                }
                            });
                    }
                })
                .catch((response) => {
                    console.log(response)
                    if (response.status !== 200) {
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('on_premises_mobile.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
        },
        drawProcessableAppointment(x, appointment) {
            x.cita = this.$t("on_premises_mobile.appointment") + ': ' + appointment.id + ', ' + appointment.operation.description + ' ' + this.$t("on_premises_mobile.to") + ' ' + appointment.client.name + ', ' + this.$t("on_premises_mobile.container") + ': ' + appointment.container_number;
            x.cliente = appointment.client.name;
            x.id = appointment.id;
            x.bol = appointment.container_number;
            x.fecha_registro = appointment.arrival_date.toString().substring(0, 10);
            x.maniobras = appointment.operation.description;
            x.appointmentStatus = appointment.status;

            if (appointment.status == "En rampa") {
                x['status'] = 'bussy';
                x['link'] = '/operation/process-in-process/' + x.id + '/' + this.from + '/' + this.m_type;
                this.props.portals.push(x);
            }
            else {
                x['status'] = 'process';
                x['link'] = '';
                this.props.portals.push(x);
            }
        },
        drawUnprocessableAppointment(x, appointment) {
            x.cita = this.$t("on_premises_mobile.appointment") + ': ' + appointment.id + ', ' + appointment.operation.description + ' ' + this.$t("on_premises_mobile.to") + ' ' + appointment.client.name + ', ' + this.$t("on_premises_mobile.container") + ': ' + appointment.container_number;
            x.cliente = appointment.client.name;
            x.id = appointment.id;
            x.bol = appointment.container_number;
            x.fecha_registro = appointment.arrival_date.toString().substring(0, 10);
            x.maniobras = appointment.operation.description;
            x.appointmentStatus = appointment.status;
            x['status'] = 'unprocessable';
            x['link'] = '';
            this.props.portals.push(x);
        }
    },
    watch: {
        'props.search': function () {
            const sear = this.props.search.toString().toLowerCase();
            if (!sear) { return this.filteredArray = this.props.portals; }
            this.filteredArray = this.props.portals.filter((x) => {
                if (x.status != this.$t('on_premises_mobile.free')) {
                    if (x.id.toString().toLowerCase().includes(sear)) return x;
                    else if (x.bol.toString().toLowerCase().includes(sear)) return x;
                    else if (x.fecha_registro.toString().toLowerCase().includes(sear)) return x;
                    else if (x.maniobras.toString().toLowerCase().includes(sear)) return x;
                    else if (x.cliente.toString().toLowerCase().includes(sear)) return x;
                }
            });
        }
    }
}
</script>

<style lang="css">
.decoration_free {
    background-color: #9fb700;
    height: 100%;
    width: 10px;
    position: absolute;
    padding: 0;
    margin: 0;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 5px !important;
    left: -2px;
    top: 0;
}

.decoration_bussy {
    background-color: rgba(252, 188, 16, 1);
    height: 100%;
    width: 10px;
    position: absolute;
    padding: 0;
    margin: 0;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 5px !important;
    left: -2px;
    top: 0;
}

.decoration_process {
    background-color: #de0000;
    height: 100%;
    width: 10px;
    position: absolute;
    padding: 0;
    margin: 0;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 5px !important;
    left: -2px;
    top: 0;
}

.decoration_unprocessable {
    background-color: #444444;
    height: 100%;
    width: 10px;
    position: absolute;
    padding: 0;
    margin: 0;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 5px !important;
    left: -2px;
    top: 0;
}
</style>