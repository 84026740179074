<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('appointment_picking.pickeo') }} </h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-row class="px-0 py-0 mb-1" justify="center" align="center">
                        <v-col cols="2" justify="center" align="center" class="px-1 py-1" style="text-align: left; background-color: #9fb700 !important; color: white; font-weight: 500;">
                            {{ $t('appointment_picking.location') }}
                        </v-col>
                        <v-col cols="2" justify="center" align="center" class="px-1 py-1" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                            {{ $t('appointment_picking.product') }}
                        </v-col>
                        <v-col cols="2" justify="center" align="center" class="px-1 py-1" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                            {{ $t('appointment_picking.batch') }}
                        </v-col>
                        <v-col cols="2" justify="center" align="center" class="px-1 py-1" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                            {{ $t('appointment_picking.reference') }}
                        </v-col>
                        <v-col cols="2" justify="center" align="center" class="px-1 py-1" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                            {{ $t('appointment_picking.pallet') }}
                        </v-col>
                    </v-row>
                    <span v-for="(product, i) of skus" :key="i">
                        <v-row class="px-1 py-2" justify="center" align="center" >
                            <v-col cols="2" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black; text-align: left;">
                                <v-icon small :class="product.color != 'accent' ? '' : 'material-icons-outlined'" :color="product.color"> fiber_manual_record </v-icon>
                                {{ product.location }}
                            </v-col>
                            <v-col cols="2" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                {{ product.product }}
                            </v-col>
                            <v-col cols="2" justify="center" v-if="product.batch != null && product.batch != ''" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                {{ product.batch }}
                            </v-col>
                            <v-col cols="2" justify="center" v-else="" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                ---
                            </v-col>
                            <v-col cols="2" justify="center" v-if="product.reference != null && product.reference != ''" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                {{ product.reference }}
                            </v-col>
                            <v-col cols="2" justify="center" v-else="" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                ---
                            </v-col>
                            <v-col cols="2" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                {{ product.pallet }} 
                                <v-icon v-if="product.checked" class="material-icons-outlined" small color="green" >check_circle</v-icon>
                            </v-col>
                        </v-row>
                    </span>
                    <br>
                    <br>
                    <v-row class="px-0 py-0" justify="center" align="center">
                        <v-row justify="end" align="end">
                            <v-spacer></v-spacer>
                            <v-col cols="10" justify="end" align="end">
                                <v-btn class="mx-3" outlined color="amarillo" @click="cancel()">
                                    {{ $t('appointment_picking.cancel') }}
                                </v-btn>
                                <v-btn class="right white--text" :disabled="!all_scanned" color="amarillo" @click="save">
                                    {{ $t('appointment_picking.save') }}
                                </v-btn> 
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>
        <v-dialog v-model="alert_modal" persistent max-width="90%">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center">
                        <h3 class="text-uppercase" style="color: #00587d;">
                            {{ $t('appointment_picking.' + modal_type) }}
                        </h3>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" justify="center" class="py-3 my-3 text-center">
                        <v-col cols="12">
                            <h4>{{ $t('appointment_picking.' + modal_type + '_message') }}</h4>
                        </v-col>
                    </v-row>
                    
                    <div class="d-flex" style="justify-content: center;">
                        <v-btn color="orange" class="white--text" @click="closeModal">
                            {{ $t('appointment_picking.accept') }}
                        </v-btn>
                    </div>
                </v-card-text> 
                <div class="marker2"></div>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>

export default {
    computed: {
    },
    mounted(){
        let self = this; 

        const keyPressHandler = (e) => {
            if(self.last == true && /^\S+$/.test(e.key)) { 
                self.barcode = e.key;
                self.last = false;
            }
            else if(e.code == "Enter") {
                self.last = true;
                self.updateList();
            }
            else if(/^\S+$/.test(e.key)) self.barcode += e.key;
        };

        window.addEventListener ('keypress', keyPressHandler, { capture: true });
        
        this.$once('hook:beforeDestroy',
            () => window.removeEventListener('keypress', keyPressHandler)
        );

        this.index();
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            skus: [],
            all_scanned: false,
            current_location_barcode: "",
            barcode: "",
            modal_type: "",
            alert_modal: false,
        }
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointments/outcome/picking/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.details.forEach(x => {
                    x.color = "accent";
                    x.checked = false;
                    this.skus.push(x);
                })
                this.$store.state.loaderProps.visible = false;
            })
            .catch(() => {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('pendings.responses.error')
                }

                this.$store.state.loaderProps.visible = false;
            })
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.patch(this.$store.state.chronosApi + 'appointments/outcome/picked/' + this.id, {}, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                this.$store.state.loaderProps.visible = false;
                this.$router.go(-1);
            })
            .catch((e) => {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: e.response.data.message 
                }

                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
        closeModal(){
            this.alert_modal = false;
        },
        async updateList(){
            let sku_found = false;
            let location_found = false;

            this.barcode = this.barcode.toString().replaceAll("Enter", "");
            this.parseEPC();

            // update skus
            this.skus.forEach(x =>{
                if(x.epc.toLowerCase() == this.barcode.toLowerCase() && x.location_barcode.toLowerCase() == this.current_location_barcode.toLowerCase()){
                    if(x.checked){
                        this.modal_type = "product_already_scanned";
                        this.alert_modal = true;
                        
                        return;
                    }

                    sku_found = true;
                    x.checked = true;
                    x.color = "accent"

                    return;
                }
            })

            //update location
            this.skus.forEach(x =>{
                if(x.location_barcode.toLowerCase() == this.barcode.toLowerCase() && !x.checked){
                    location_found = true;
                    this.current_location_barcode = this.barcode;
                    x.color = "success2";
                }
            })

            // Switch pallet implementation
            // if(!sku_found && !location_found && this.current_location_barcode){                
            //     await this.compareEPC();
            // }

            // Check if all products from location are scanned
            const all_from_location_scanned = this.skus.find(x => (x.checked == false && x.location_barcode.toLowerCase() == this.current_location_barcode.toLowerCase()))
            if(all_from_location_scanned == undefined) this.current_location_barcode = "";

            // check products scanned
            const unescanned = this.skus.find(x => x.checked == false)
            if(unescanned == undefined) this.all_scanned = true;

            // update current location
            if(!sku_found && !location_found && this.current_location_barcode == ""){
                this.modal_type = "location_not_found";
                this.alert_modal = true;
                
                return;
            }

            if(!sku_found && !location_found && this.current_location_barcode != ""){
                this.modal_type = "sku_not_found";
                this.alert_modal = true;
                
                return;
            }
        },
        async compareEPC(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.patch(this.$store.state.chronosApi + '/appointments/outcome/picking/resource_change/' + this.id, {
                "epc": this.barcode
            }, {headers: {Authorization: lsToken}})
            .then((response) => {
                
                console.log(response);



            })
            .catch((e) => {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: e.response.data.fault.message 
                }

                this.$store.state.loaderProps.visible = false;
            })
        },
        parseEPC(){
            if (this.barcode.startsWith('P') || this.barcode.startsWith('p')) {
                this.barcode = this.barcode.substring(1);  
                this.barcode = 'C' + this.barcode.padStart(23, '0'); 
            }
            if (this.barcode.startsWith('T') || this.barcode.startsWith('t')) {
                this.barcode = this.barcode.substring(1);  
                    this.barcode = 'A' + this.barcode.padStart(23, '0'); 
            }
        }
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>