<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="2" class="py-0">
                    <datepicker :open_dialog="menu" :model="initial_date" :label="$t('fulfillment_summary.initial_date')" @model="updateInitialDate($event)" :rules_required="rules_required"></datepicker>
                </v-col>
                <v-col cols="2" class="py-0">
                    <datepicker :open_dialog="menu1" :model="final_date" :label="$t('fulfillment_summary.final_date')" @model="updateFinalDate($event)" :rules_required="rules_required"></datepicker>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="show_export_options = true" v-if="show_export_options == false">
                        {{ $t('stock_report.export') }}
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataPdf">
                        PDF
                    </v-btn>
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataExcel">
                        Excel
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="show_export_options = false;">
                        <v-icon small class="px-0">
                            keyboard_arrow_left
                        </v-icon>
                    </v-btn>

                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="generate">
                        {{ $t('fulfillment_summary.generate') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="pt-0 py-3 my-2">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers: headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('fulfillment_summary.client_name'), align: "center", value: 'client', class: 'text-uppercase' },
                { text: this.$t('fulfillment_summary.unique_order_number'), align: "center", value: 'unique_order_number', class: 'text-uppercase' },
                { text: this.$t('fulfillment_summary.shipping_service'), align: "center", value: 'shipping_service', class: 'text-uppercase' },
                { text: this.$t('fulfillment_summary.guide'), align: "center", value: 'shipment_number', class: 'text-uppercase' },
                { text: this.$t('fulfillment_summary.type'), align: "center", value: 'type', class: 'text-uppercase' },
                { text: this.$t('fulfillment_summary.creation_date'), align: "center", value: 'creation_date', class: 'text-uppercase' },
                { text: this.$t('fulfillment_summary.creation_time'), align: "center", value: 'creation_time', class: 'text-uppercase' },
                { text: this.$t('fulfillment_summary.ready_date'), align: "center", value: 'ready_date', class: 'text-uppercase' },
                { text: this.$t('fulfillment_summary.pickup_date'), align: "center", value: 'pickup_date', class: 'text-uppercase' },
                { text: this.$t('fulfillment_summary.delivery_date'), align: "center", value: 'delivered_date', class: 'text-uppercase' },
                { text: this.$t('fulfillment_summary.last_status'), align: "center", value: 'last_status', class: 'text-uppercase' }, 
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            initial_date: '',
            final_date: '',
            report:{
                initial_date: '',
                final_date: ''
            },
            menu: false,
            menu1: false,
            summary: {
                income: 0,
                outcome: 0,
                fulfillment: 0,
                returns: 0
            },
            income_ids: [],
            outcome_ids: [],
            fulfillment_ids: [],
            returns_ids: [],
            show_export_options: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.fulfillment_summary_report";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
        },
        updateInitialDate(value){
            this.initial_date = value;
        },
        updateFinalDate(value){
            this.final_date = value;
        },
        generate(){
            this.props.items = [];
            this.summary = {
                income: 0,
                outcome: 0,
                fulfillment: 0,
                returns: 0
            };

            this.income_ids = [];
            this.outcome_ids = [];
            this.fulfillment_ids = [];
            this.returns_ids = [];
            
            if(this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'reports/fulfillmentSummary?warehouse='+ this.$store.state.warehouseId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}})
                .then((response) => {
                    var count = 0;

                    response.data.data.forEach(x => {
                        var y = {
                            "id": count,
                            "unique_order_number": x[1],
                            "shipping_service": x[2],
                            "shipment_number": x[3],
                            "type": x[5],
                            "creation_date": x[6].split("T")[0],
                            "creation_time": x[6].split("T")[1].split(".")[0],
                            "ready_date": x[7],
                            "pickup_date": x[8],
                            "delivered_date": x[9],
                            "last_status": x[10],
                            "client": x[11]
                        }
                        
                        count++;
                        this.props.items.push(y);
                    })
                })
                .catch((response) => {
                    console.log(response)
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataExcel(){
            const lsToken = localStorage.getItem("access_token");
            if(this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;

                this.$http.get(this.$store.state.chronosApi + 'reports/fulfillmentSummaryExport?startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Fulfillment_Summary.xlsx');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataPdf(){
            const lsToken = localStorage.getItem("access_token");
            if(this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;

                this.$http.get(this.$store.state.chronosApi + 'reports/fulfillmentSummaryPDF?startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Fulfillment_Summary.pdf');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        }
    },
    watch: {
        '$store.state.clientId': function(){
            this.props.items = [];
        }
    }
}
</script>

<style>
</style>