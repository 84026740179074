<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="8" md="4" class="py-0">
                    <datepicker :open_dialog="date_modal" :model="today_date" @model="updateDate" :label="$t('backlog.date')" v-if="today_date != ''" :rules_required="rules_required"></datepicker>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row class="mt-2">
                        <v-col class="py-0" cols="7">
                            <v-row class="font-weight-bold text-uppercase white--text py-1 my-0 mx-0" style="background-color: #001F5B;" justify="center" align="center">
                                {{ $t("backlog.pending_operations")}}: {{ pending_operations }}
                            </v-row>
                        </v-col>
                        <v-col class="py-0" cols="5">
                            <v-row class="font-weight-bold text-uppercase white--text py-1 my-0 mx-0"  style="background-color: #001F5B;" justify="center" align="center">
                                {{ $t("backlog.pending_storage")}}: {{ pending_storage }} 
                            </v-row>
                        </v-col>
                    </v-row>
                    <br>
                    <v-row class="mt-2">
                        <v-col class="py-0" cols="7">
                            <v-row class="azul font-weight-bold text-uppercase white--text py-1 my-0 mx-0" justify="center" align="center">
                                {{ $t("backlog.storage")}}
                            </v-row>

                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers_storage"
                                :items="props_storage.items"
                                calculate-widths
                                :hide-default-footer="true"
                                :mobile-breakpoint="0"
                                disable-pagination
                                item-key="id"
                            >
                                <template v-slot:item.status="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <td style="text-align: center; text-transform: uppercase;" v-on="on" v-if="item.status != 'Pendiente Anexo24' && item.status != 'Completo' && item.status != 'Completed'">
                                                <span class="back_orange"> {{ $t('backlog.pending') }} </span> 
                                            </td>
                                            <td style="text-align: center; text-transform: uppercase;" v-else v-on="on">
                                                <span class="back_green"> {{ $t('backlog.completed') }} </span> 
                                            </td>
                                        </template>
                                        
                                        <span v-if="item.status != 'Pendiente Anexo24' && item.status != 'Completo' &&  item.status != 'Completed'">{{ $t('backlog.pending') }}</span>
                                        <span v-else>{{ $t('backlog.completed') }}</span>
                                    </v-tooltip>
                                </template>

                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                        <v-col class="py-0" cols="5">
                            <v-row class="azul font-weight-bold text-uppercase white--text py-1 my-0 mx-0" justify="center" align="center">
                                {{ $t("backlog.fulfillment")}}
                            </v-row>

                            <v-data-table class="px-0 my-2"
                                    no-results-text="No hay resultados"
                                    :headers="headers_fulfillment"
                                    :items="props_fulfillment.items"
                                    calculate-widths
                                    :hide-default-footer="true"
                                    :mobile-breakpoint="0"
                                    disable-pagination
                                    item-key="id"
                                >
                                
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers_storage(){
            return [
                // { text: this.$t('backlog.id'), align: "center", value: 'appointment_id', class: 'text-uppercase' },
                { text: this.$t('backlog.customer'), align: "center", value: 'client_name', class: 'text-uppercase' },
                { text: this.$t('backlog.operation'), align: "center", value: 'description', class: 'text-uppercase' },
                { text: this.$t('backlog.container'), align: "center", value: 'container_number', class: 'text-uppercase' },
                { text: this.$t('backlog.user'), align: "center", value: 'users_name', class: 'text-uppercase' },
                { text: this.$t('backlog.pending_activity'), align: "center", value: 'pending_operation', class: 'text-uppercase' },
                { text: '', align: "center", value: 'status', class: 'text-uppercase' }
            ];
        },
        headers_fulfillment() {
            return [
                { text: this.$t('backlog.customer'), align: "center", value: 'client_name', class: 'text-uppercase' },
                { text: this.$t('backlog.type'), align: "center", value: 'type', class: 'text-uppercase' },
                { text: this.$t('backlog.pendings'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('backlog.processed'), align: "center", value: 'processed', class: 'text-uppercase' }
            ]
        }
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            date_modal: false,
            props_storage: {
                search: '',
                items: [],
            },
            props_fulfillment: {
                searhc: '',
                items: []
            },
            today_date: '',
            pending_operations: "",
            pending_storage: ""
        }
    },
    mounted(){
        this.today_date = new Date();
        this.today_date = this.today_date.toISOString().split('T')[0];
        this.$store.state.module = "headers.backlog";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        updateDate(newDate) {
           this.today_date = newDate;  // Actualiza la fecha en el padre
        },
        index(){
            this.props_storage.items = [];
            this.props_fulfillment.items = [];

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'backlog?date=' + this.today_date, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.storage.forEach(x => {
                        x.container_number = x.container_number.substring(0,30);
                        this.props_storage.items.push(x);
                    })

                    response.data.fulfillment.forEach(x => {
                        x.type = this.$t('backlog.' + x.type)
                        this.props_fulfillment.items.push(x);
                    })

                    this.pending_operations = response.data.pending_operations.total;
                    this.pending_storage = response.data.pending_storage.total;
                    
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    },
    watch: {
        'today_date' : function(){
            this.index();
        }
    }
}
</script>

<style>

</style>