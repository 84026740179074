<template>
     <div class="py-0 mt-4 mb-8">
        <v-container class="mx-0 my-0" style="padding:0 !important; max-width: 100% !important">
            <v-card class="mt-1 pt-1 pb-10 px-0 size" style="margin-bottom: 35px !important" align="center">   
                <v-row class="px-3 py-4 text-center" justify="center" align="center">
                    <v-col cols="6" class="py-1" justify="center" align="center">
                        <span justify="center" class="pr-1 text-uppercase azul--text font-weight-bold">
                            {{ $t('process_pendings.enter_the_enclosure') }} 
                        </span> 
                    </v-col>
                </v-row>
                <v-col cols="6" justify="start" align="start" class="px-0">
                    <v-row>
                        <v-col cols="6" class="py-1">
                            <span justify="center" class="pr-1 azul--text text--uppercase font-weight-bold" style="font-size:18px">
                                {{ $t('process_pendings.general_data') }} 
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.appointment') }}: </span> {{ this.id }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.client') }}: </span> {{ this.appointment.client.name }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.estimated_date_of_arriving') }}: </span> {{ this.appointment.arrival_date }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col v-if="$store.state.language == 'en'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.operation') }}: </span> {{ this.appointment.operation.description1 }} </v-col>
                         <v-col v-if="$store.state.language == 'es'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.operation') }}: </span> {{ this.appointment.operation.description }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.container') }}: </span> {{ this.appointment.container_number }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.description') }}: </span> {{ this.appointment.description }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.comments') }}: </span> {{ this.appointment.commentary }} </v-col>
                    </v-row>
                </v-col>
                <v-col class="py-5 px-0" cols="6" justify="start" align="start">
                    <v-row>
                        <v-col cols="12" class="py-1">
                            <span justify="center" class="pr-1 azul--text font-weight-bold" style="font-size:18px">
                                {{ $t('process_pendings.products') }} 
                            </span>
                        </v-col>
                    </v-row>
                    <datatable :props="{headers:headers_sku, items: appointment.products}" @methodHandler="methodHandler"></datatable>   
                </v-col>
                <v-form ref="form" lazy-validation @submit.prevent="update">    
                    <v-col cols="6" justify="start" align="start" class="px-0">
                        <v-row>
                            <v-col cols="12" class="py-1">
                                <span justify="center" class="pr-1 azul--text font-weight-bold" style="font-size:18px">
                                    {{ $t('process_pendings.transport_details') }} 
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-col cols="12" class="py-0">
                                <v-text-field v-model="appointment.carrier_line" :label="$t('process_pendings.carrier_line')" :rules="rules_required" ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-col cols="12" class="py-0">
                                <v-text-field v-model="appointment.drivers_name" :label="$t('process_pendings.drivers_name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-col cols="12" class="py-0">
                                <v-text-field v-model="appointment.license_plate" :label="$t('process_pendings.plates')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-col cols="12" class="py-0">
                                <v-text-field v-model="appointment.tractor" :label="$t('process_pendings.tractor')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-col cols="12" class="py-0">
                                <v-text-field v-model="appointment.consignment_note" :label="$t('process_pendings.consignment_note')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-col cols="12" class="py-0">
                                <v-text-field v-model="appointment.seal_number" :label="$t('process_pendings.seal_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-col cols="12" class="py-0">
                                <v-autocomplete v-model="appointment.transport_type" :items="tipos_transporte" :label="$t('process_pendings.type_transport')" ></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-3 pb-2" justify="start" align="center">
                            <v-col cols="8" class="px-0 py-0">
                                <span >{{ $t('process_pendings.unit_good_conditions') }}:</span>
                            </v-col>
                            <v-col cols="4" class="px-0 py-0">
                                <v-btn class="radio-left" v-bind:color="appointment.unit_good_conditions == 0 ? 'info' : 'white'" @click="appointment.unit_good_conditions = 0">{{ $t('process_pendings.false') }}</v-btn>
                                <v-btn class="radio-right" v-bind:color="appointment.unit_good_conditions == 1? 'info' : 'white'" @click="appointment.unit_good_conditions = 1">{{ $t('process_pendings.true') }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center" v-if="appointment.unit_good_conditions == 0">
                            <v-col cols="12" class="py-0">
                                <v-text-field v-model="appointment.unit_damage_specifications" :label="$t('process_pendings.unit_damage_specifications')" ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-3 pb-2" justify="start" align="center">
                            <v-col cols="8" class="px-0 py-0">
                                <span >{{ $t('process_pendings.seal_well_placed') }}:</span>
                            </v-col>
                            <v-col cols="4" class="px-0 py-0">
                                <v-btn class="radio-left" v-bind:color="appointment.seal_good_conditions == 0 ? 'info' : 'white'" @click="appointment.seal_good_conditions = 0">{{ $t('process_pendings.false') }}</v-btn>
                                <v-btn class="radio-right" v-bind:color="appointment.seal_good_conditions == 1? 'info' : 'white'" @click="appointment.seal_good_conditions = 1">{{ $t('process_pendings.true') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-form>
                <v-row align="center" class="mt-2" justify="center">
                    <v-col class="mb-1" cols="6" align="end" justify="end">
                        <v-btn color="white" class="black--text mr-2" @click="back"> {{ $t('process_pendings.cancel') }} </v-btn>
                        <v-btn color="amarillo" class="white--text" @click="update" v-permission="'op_pendings.update'"> {{ $t('process_pendings.enter_the_enclosure') }} </v-btn>
                    </v-col> 
                </v-row>
            </v-card>
            <bottom-bar ref="bottombar" :from="from"></bottom-bar>         
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('edit_appointment.validations.required_field')]}, 
        headers_sku: function(){
            return [
                {text: this.$t('process_pendings.sku'), value: 'sku_p', class: 'text-uppercase'},
                // {text: this.$t('edit_appointment.description'), value: 'descripcion', class: 'text-uppercase'},
                {text: this.$t('process_pendings.pieces'), value: 'quantity', class: 'text-uppercase'},
                {text: this.$t('process_pendings.unit'), value: 'unit', class: 'text-uppercase'}
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            from: this.$route.params.from,
            m_type: this.$route.params.m_type,
            snackbar: {
                visible: false,
                color: 'primary',
                timeout: 2000,
                text: ''
            },
            tipos_carga: [
                "MONTACARGA",
                "MANUAL",
                "NINGUNA"
            ],
            menu: false,
            tipos_transporte:[
                "CAMIONETA 3 1/2",
                "RABÓN",
                "TORTHON",
                "CONTENEDOR 20'",
                "CONTENEDOR 40'",
                "CAJA SECA 40'",
                "CAJA SECA 48'",
                "CAJA SECA 53'",
                "OTROS"
            ],
            clients: [],
            appointment:{
                // General data
                client_id: "",
                client: {
                    name:""
                },
                arrival_date: "",
                eta: "",
                operation_id: "",
                operation: {
                    description: "",
                    description1: ""
                },
                container_number: "",
                description: "",
                commentary: "",
                additional_services: "",
                purchase_order: "",

                // Transport details
                carrier_line: "",
                drivers_name: "",
                license_plate: "",
                box: "",
                tractor: "",
                consignment_note: "",
                cargo_type: "",
                transport_type: "",
                seal_number: "",
                
                // Foreign trade
                origin_supplier_id: "",
                patent_number: "",
                modulation_status: "",
                modulation_date: "",
                modulation_time: "",
                bill_of_lading: "",
                remittance: "",
                petition: "",
                notices: "",
                entry_petition: "",
                
                packing_type: "",
                unit_good_conditions: 1,
                unit_damage_specifications: "",
                seal_good_conditions: 1,
                
                products: []
            },
            movements: [],
            items_g: [],
            racks: []
        }
    },
    mounted(){
        localStorage.setItem('bottom', 0);
        this.$store.state.bottom = 0;
        this.$store.state.module = "headers.enter_to_enclosure_operation";
        this.index();
    },
    methods: {
        back(){
            this.$router.go(-1);
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'appointments/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data = response.data.data.attributes;
                    
                    if(response.data.operation.format_type == "Entrada"){
                        if(response.data.unknown_products == true){
                            if(response.data.standby_products[0].attributes.unknownProducts.boxes > 0){
                                this.appointment.products.push({
                                    sku_p: this.$t('process_pendings.boxes'),
                                    quantity: response.data.standby_products[0].attributes.unknownProducts.boxes
                                })
                            }
                            else if(response.data.standby_products[0].attributes.unknownProducts.pallets > 0){
                                this.appointment.products.push({
                                    sku_p: this.$t('process_pendings.pallets'),
                                    quantity: response.data.standby_products[0].attributes.unknownProducts.pallets
                                })
                            }
                        }
                        else{
                            response.data.standby_products.forEach(x => 
                            {
                                x = x.attributes;
                                var p = {};
                                p = {
                                    sku_p: x.product.sku,
                                    quantity: x.quantity,
                                    unit: x.product.unit
                                }
                                            
                                this.appointment.products.push(p);
                            });
                        }
                    } 
                    else if(response.data.operation.format_type == "Salida"){
                        response.data.detail_orders.forEach(x => 
                        {
                            x = x.attributes;
                            var p = {};
                            p = {
                                sku_p: x.product.sku,
                                quantity: x.expected,
                                unit: x.product.unit
                            }
                                        
                            this.appointment.products.push(p);
                        });
                    }

                    // General Data
                    this.$store.state.clientId              = response.data.client.id ?? null;
                    this.appointment.client_id              = response.data.client.id ?? null;
                    this.appointment.client                 = response.data.client ?? null;
                    this.appointment.operation              = response.data.operation ?? null;
                    this.appointment.arrival_date           = response.data.arrival_date.substring(0, 10) ?? null;
                    if(response.data.is_national == true && response.data.eta != undefined && response.data.eta != null)
                        this.appointment.eta                = response.data.eta.substring(0, 10);
                    this.appointment.operation_id           = response.data.operation.id ?? null;
                    this.appointment.additional_services    = response.data.additional_services ?? null;
                    this.appointment.purchase_order         = response.data.purchase_order ?? null;
                    this.appointment.container_number       = response.data.container_number ?? null;
                    this.appointment.description            = response.data.description ?? null;
                    this.appointment.commentary             = response.data.commentary ?? null;
 
                    // Transport Details
                    this.appointment.carrier_line           = response.data.carrier_line ?? null;
                    this.appointment.drivers_name           = response.data.drivers_name ?? null;
                    this.appointment.license_plate          = response.data.license_plate ?? null;
                    this.appointment.tractor                = response.data.tractor ?? null;
                    this.appointment.consignment_note       = response.data.consignment_note ?? null;
                    this.appointment.cargo_type             = response.data.cargo_type ?? null;
                    this.appointment.transport_type         = response.data.transport_type ?? null;
                    this.appointment.seal_number            = response.data.seal_number ?? null;
                    this.appointment.unit_good_conditions           = response.data.unit_good_conditions ?? 1;
                    this.appointment.unit_damage_specifications     = response.data.unit_damage_specifications ?? null;
                    this.appointment.seal_good_conditions           = response.data.seal_good_conditions ?? 1;
                    this.appointment.merchandise_good_conditions    = response.data.merchandise_good_conditions ?? 1;
                    this.appointment.packaging_complies_conditions  = response.data.packaging_complies_conditions ?? 1;
                    this.appointment.packing_type                   = response.data.packing_type ?? null;

                    // Foreign Trade
                    if(response.data.is_national == false){
                        this.appointment.origin_supplier_id     = response.data.origin_supplier_id ?? null;
                        this.appointment.patent_number          = response.data.patent_number ?? null;    
                        this.appointment.modulation_status      = response.data.modulation_status ?? null;
                        this.appointment.modulation_date        = response.data.modulation_date ?? null;
                        this.appointment.modulation_time        = response.data.modulation_time ?? null;
                        this.appointment.bill_of_lading         = response.data.bill_of_lading ?? null;
                        this.appointment.remittance             = response.data.remittance ?? null;
                        this.appointment.petition               = response.data.petition ?? null;
                        this.appointment.notices                = response.data.notices ?? null;
                        this.appointment.entry_petition         = response.data.entry_petition ?? null;
                    }
                }

                this.$http.get(this.$store.state.chronosApi + 'getLocations/' + response.data.operation.id + '/' + response.data.client.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        response.data.forEach(x =>{
                            this.racks.push({
                                id: x.id,
                                name: x.name
                            });
                        });
                    }
                });       
            })
            .catch((response) => {              
                this.$store.state.loaderProps.visible = false;
                console.log(response)
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        update(){
            const valid = this.$refs.form.validate();
            
            if(valid){
                this.appointment.newStatus = 'En recinto';
                this.appointment.status = 'En recinto';
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'setStatus/' + this.$route.params.id, this.appointment, {headers: {Authorization: lsToken}})
                .then((response) => {   
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('process_pendings.responses.correct')
                        } 
                        this.$router.push('/operation/on-premises/' + this.from + '/' + this.m_type);
                    }
                }).catch((error) => {
                    console.log(error);
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('process_pendings.responses.update')
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                }); 
            } else {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('process_pendings.missing_required_fields')
                }
            }
        },
    },
    watch:{
    }
}
</script>

<style lang="scss">
</style>