<template>
    <div>
        <v-app-bar app color="#f5f5f5" elevation="0" dark class="mx-0 app-header px-3">
            <div class="d-flex align-center">
                <v-btn v-if="$store.state.menu" icon text @click="$store.state.minimenu = !$store.state.minimenu" class="accent--text" outlined>
                    <v-icon small>menu</v-icon>
                </v-btn>
                <v-btn v-else icon class="accent--text" outlined text @click="$store.state.menu = !$store.state.menu">
                    <v-icon small>menu</v-icon>
                </v-btn>
                <v-col class="px-0 mx-0" mb="3">
                    <h4 class="accent--text" v-if="elem() >= 600">{{ $t($store.state.module) }}</h4>
                    <h6 class="accent--text" v-if="elem() < 600">{{ $t($store.state.module) }}</h6>
                    <h5 class="accent--text"  v-if="elem() >= 750">{{ this.current_time }} | {{ this.day }} {{this.month}} {{this.year}}</h5>
                </v-col>
            </div>
            <div>
                <span v-if="this.$store.state.loggedUserRole == 'client'" class="mx-2 d-none d-sm-flex">{{ $store.state.loggedUserClientName }}</span>
            </div>
            <v-spacer></v-spacer>
            
            <v-spacer class="d-inline-flex d-sm-none"></v-spacer>
            <!-- Language menu -->
            <v-menu offset-y :disabled="this.$route.matched.some(({ name }) => name === 'AFPrepare')">
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" class="amarillo--text" style="font-size:12px; background-color:white; max-width: 50px !important;" outlined>
                        <span>{{ $store.state.language == 'es' ? 'ES' : 'EN' }}</span>
                        <v-icon right>arrow_drop_down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <div v-for="(item, index) in languages" :key="index">    
                        <v-list-item @click="changeLanguage(index)" link >
                            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-menu>

            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" class="accent--text mx-1" style="font-size:12px;background-color:white">
                        <span>{{ $store.state.warehouseName}}</span>
                        <v-icon right>arrow_drop_down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <div v-for="(item,index) in warehouses" :key="index">    
                        <v-list-item @click="selectWarehouse(item.id)">
                            <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-menu>
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" style="font-size:12px; background-color:white">
                        <v-avatar size="33" color="accent lighten-1">
                            <img v-if="$store.state.loggedPicture != '' && $store.state.loggedPicture != null" :src="$store.state.loggedPicture" :alt="$store.state.loggedUsername">
                            <span v-else>{{ $store.state.loggedUsername.split(' ').map((x) => { return x[0] }).join('').substring(0, 2) }}</span>
                        </v-avatar>
                        <span v-if="elem() >= 600" class="mx-2 d-none d-sm-flex accent--text">{{ $store.state.loggedUsername }}</span>
                        <v-icon right class="accent--text">arrow_drop_down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <div v-for="(item, index) in userMenu" :key="index">
                        <v-list-item v-if="item.link !== null" :to="item.link" v-permission:all="item.permission">
                            <v-list-item-title><v-icon class="material-icons-outlined mr-2"> {{ item.icon }} </v-icon>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-else @click="methodHandler(item.method)" link>
                            <v-list-item-title><v-icon class="material-icons-outlined mr-2"> {{ item.icon }} </v-icon> {{ $t(item.title) }}</v-list-item-title>
                        </v-list-item>
                    </div>
                    <v-list-item @click="logout" link>
                        <v-list-item-title><v-icon class="material-icons-outlined mr-2" color="accent">logout </v-icon>{{ $t('topbar.session')}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <div class="line-app-header">  
                <template>
                    <v-progress-linear indeterminate :hidden="!this.$store.state.loading"></v-progress-linear>
                </template>
            </div>
        </v-app-bar>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script lang="js">
    export default {
        computed: {
            clientToken: function(){
                return this.$store.state.clientId;
            }
        },
        data(){
            return {
                w_size: 0,
                iniciales: '',
                clientProfile: null,
                modal: false,
                inputFile: [],
                readFile: '',
                readRows: [],
                stepper: 1,
                warehouses: [],
                rules: {
                    file: [
                        // v => !v || 'El archivo es requerido'
                    ],
                    required: value => !!value || 'Requerido'
                },
                languages: [
                    {
                        icon: 'es.svg',
                        iconType: 'image',
                        title: (localStorage.getItem('language') == 'es' ? 'ES' : 'ES'),
                        link: null
                    },
                    {
                        icon: 'en.svg',
                        iconType: 'image',
                        title: (localStorage.getItem('language') == 'es' ? 'EN' : 'EN'),
                        link: null
                    },
                ],
                userMenu: [
                    // {
                    //     icon: 'list',
                    //     iconType: 'icon',
                    //     title: 'topbar.massive',
                    //     link: '/upload-multiple-appointments',
                    //     permission: "i_multiple.create|appointment.create"
                    // }
                ],
                snackbar: {
                    visible: false,
                    color: 'primary',
                    timeout: 4000,
                    text: ''
                },
                day: '',
                month: '',
                year: '',
                current_time: '',
                monthsEn : [
                    "Jan","Feb","Mar","Apr",
                    "May","Jun","Jul","Aug",
                    "Sep", "Oct","Nov","Dec"
                ],
                monthsEs : [
                    "Ene","Feb","Mar","Abr",
                    "May","Jun","Jul","Ago",
                    "Sep", "Oct","Nov","Dic"
                ],
                intervalid1: ''
            }
        },
        mounted(){
            this.getWarehouses();
            this.day = new Date().getDate();
            if(this.day.toString().length == 1) this.day = "" + 0 + this.day;
            this.month = (this.$store.state.language == 'es') ? this.monthsEs[new Date().getMonth()] : this.monthsEn[new Date().getMonth()];
            this.year = new Date().getFullYear();

            this.hours = new Date().getHours();
            if(this.hours.toString().length == 1) this.hours = "" + 0 + this.hours;
            this.minutes = new Date().getMinutes();
            if(this.minutes.toString().length == 1) this.minutes = "" + 0 + this.minutes;
            
            this.getTime();
        },
        methods: {
            methodHandler(name){
                if(name == 'topbar.updateData') this['updateData']()
            },
            getTime() {
                const t = this;
                this.intervalid1 = setInterval(function(){
                    var d = new Date();
                    var s = d.getSeconds();
                    var m = d.getMinutes();
                    var h = d.getHours();
                    t.current_time = ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2) + ":" + ("0" + s).substr(-2);
                }, 1000);
            },  
            elem() {
                this.w_size = window.innerWidth;
                return this.w_size;
            },
            logout(){
                var user = {
                    "user": {
                        "email": this.$store.state.loggedUserEmail,
                        "warehouse_id": localStorage.getItem("warehouse_id"),
                        "roles": []
                    }
                }

                const lsToken = localStorage.getItem("access_token")
                this.$http.delete(this.$store.state.chronosApi + 'users/sign_out', ({ headers: { Authorization: lsToken }, body: user }))
				.then((response) => {
                    if(response){
                        localStorage.removeItem('access_token');
                        this.$router.push("/login")
                    }
                })
                .catch(function (error) {
                    error
                    console.log(error);
                })
            },
            getWarehouses(){
                this.warehouses = [];
                var endpoint = "warehousesByUser";
                if(this.$store.state.clientUserId != '') endpoint = "warehousesForClient";
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + endpoint, {
                    headers: { Authorization: lsToken }
                })
				.then((response) => {
                    if(response){
                        response.data.forEach((x) =>{
                            this.warehouses.push({
                                    id: x.id, name: x.name
                                }
                            );
                        })
                    }
                })
                .catch(function (error) {
                    error
                })
            },
            selectWarehouse(index){
                const name = this.warehouses.find(item => item.id == index).name;
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'user/updateWarehouse', { warehouse_id: index }, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.data.message == "User Updated Successfully!"){
                        localStorage.setItem('warehouse_id', index);
                        localStorage.setItem('warehouseName', name);
                        this.$store.state.warehouseName = name;
                        this.$store.state.warehouseId = index;
                        window.location.reload();
                    }
                    else{
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: response.data.message
                        }
                    }
                }).catch((err) => {
                    console.log(err);
                });

            },
            changeLanguage(index) {
                localStorage.setItem('language', (this.languages[index].title == 'ES' || this.languages[index].title == 'ES') ? 'es' : 'en');
                
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'updateLanguage', 
                { 
                    "user": {
                        "email": this.$store.state.loggedUserEmail,
                        "language_preferences": localStorage.getItem("language")
                    } 
                }, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.data.message == "User language Updated Successfully!") window.location.reload();
                    else{
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: response.data.message
                        }
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
        },
    }
</script>
<style lang="scss">
    .app-header{
        margin: 7px;
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
    }

    .app-toolbar{
        padding: 0 !important;
    }
    
    .user-button{
        text-transform: unset !important;
    }

    .line-app-header{
        width: calc(100% - 1.6rem) !important;
        height: 1.5px;
        background: #b0b0b0;
        position: absolute;
        bottom: 0px;
        left: 0.8rem;
        margin:0;
        padding:0;
        z-index: -10;
    }
</style>