<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 size py-3">
                <v-card-text class="px-0">

                    <!-- Administrative History -->
                    <v-card elevation="4" class="mx-3" v-permission="'administrative_history.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.administrative_history') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="3" class="py-0">
                                        <v-autocomplete class="mt-0 py-0" v-model="administrative_history.type" :items="types" item-value="value" item-text="name" :label="$t('yego_reports.type')" :rules="rules_required"></v-autocomplete> 
                                    </v-col>
                                <v-col cols="3" class="py-0">
                                    <client-selector class="py-0" ref="clientSelector" @getClients="v => items.clients = v" :is_national="2" :rules="rules_required"></client-selector>  
                                </v-col>
                                <v-col cols="3" class="py-0">
                                    <datepicker :open_dialog="menu" :model="administrative_history.initial_date" :label="$t('yego_reports.initial_date')" @model="updateDate('administrative_history.initial_date', $event)" :rules_required="rules_required"></datepicker>
                                </v-col>
                                <v-col cols="3" class="py-0">
                                    <datepicker :open_dialog="menu1" :model="administrative_history.final_date" :label="$t('yego_reports.final_date')" @model="updateDate('administrative_history.final_date', $event)" :rules_required="rules_required"></datepicker>
                                </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-btn class="right white--text pt-0" color="amarillo" @click="exportAdministrativeHistory">
                                    {{ $t('yego_reports.export') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                    <!-- Operative History -->
                    <v-card elevation="4" class="mx-3 mt-8" v-permission="'operative_history.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.operative_history') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="3" class="py-0">
                                        <v-autocomplete class="mt-0 py-0" v-model="operative_history.type" :items="types" item-value="value" item-text="name" :label="$t('yego_reports.type')" :rules="rules_required"></v-autocomplete> 
                                    </v-col>
                                <v-col cols="3" class="py-0">
                                    <client-selector class="py-0" ref="clientSelector" @getClients="v => items.clients = v" :is_national="2" :rules="rules_required"></client-selector>  
                                </v-col>
                                <v-col cols="3" class="py-0">
                                    <datepicker :open_dialog="menu1" :model="operative_history.initial_date" :label="$t('yego_reports.initial_date')" @model="updateDate('operative_history.initial_date', $event)" :rules_required="rules_required"></datepicker>
                                </v-col>
                                <v-col cols="3" class="py-0">
                                    <datepicker :open_dialog="menu13" :model="operative_history.final_date" :label="$t('yego_reports.final_date')" @model="updateDate('operative_history.final_date', $event)" :rules_required="rules_required"></datepicker>
                                </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-btn class="right white--text pt-0" color="amarillo" @click="exportOperativeHistory">
                                    {{ $t('yego_reports.export') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- Balance -->
                    <v-card elevation="4" class="mx-3 mt-8" v-permission="'balance.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.balance') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="3" class="py-0">
                                        <v-autocomplete class="mt-0 py-0" v-model="balance.type" :items="types" item-value="value" item-text="name" :label="$t('yego_reports.type')" :rules="rules_required"></v-autocomplete> 
                                    </v-col>
                                    <v-col cols="3" class="py-0">
                                        <client-selector class="py-0" ref="clientSelector" @getClients="v => items.clients = v" :is_national="2" :rules="rules_required"></client-selector>  
                                    </v-col>
                                    <v-col cols="3" class="py-0">
                                        <datepicker :open_dialog="menu2" :model="balance.initial_date" :label="$t('yego_reports.initial_date')" @model="updateDate('balance.initial_date', $event)" :rules_required="rules_required"></datepicker>
                                    </v-col>
                                    <v-col cols="3" class="py-0">
                                        <datepicker :open_dialog="menu3" :model="balance.final_date" :label="$t('yego_reports.final_date')" @model="updateDate('balance.final_date', $event)" :rules_required="rules_required"></datepicker>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-btn class="right white--text pt-0" color="amarillo" @click="exportBalance">
                                    {{ $t('yego_reports.export') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- Balance V2 -->
                    <v-card elevation="4" class="mx-3 mt-8" v-permission="'balance.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.balancev2') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="3" class="py-0">
                                        <v-autocomplete class="mt-0 py-0" v-model="balancev2.type" :items="types" item-value="value" item-text="name" :label="$t('yego_reports.type')" :rules="rules_required"></v-autocomplete> 
                                    </v-col>
                                <v-col cols="3" class="py-0">
                                    <client-selector class="py-0" ref="clientSelector" @getClients="v => items.clients = v" :is_national="2" :rules="rules_required"></client-selector>  
                                </v-col>
                                <v-col cols="3" class="py-0">
                                    <datepicker :open_dialog="menu9" :model="balancev2.initial_date" :label="$t('yego_reports.initial_date')" @model="updateDate('balancev2.initial_date', $event)" :rules_required="rules_required"></datepicker>
                                </v-col>
                                <v-col cols="3" class="py-0">
                                    <datepicker :open_dialog="menu10" :model="balancev2.final_date" :label="$t('yego_reports.final_date')" @model="updateDate('balancev2.final_date', $event)" :rules_required="rules_required"></datepicker>
                                </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-btn class="right white--text pt-0" color="amarillo" @click="exportBalancev2">
                                    {{ $t('yego_reports.export') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- Address Corrections -->
                    <v-card elevation="4" class="mx-3 mt-8" v-permission="'address_corrections.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.address_corrections') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="6" class="py-0">
                                        <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="false" v-model="address_corrections.file" accept=".csv" :label="$t('yego_reports.consolidated_notice')"></v-file-input>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <datepicker :open_dialog="menu4" :model="address_corrections.date" :label="$t('yego_reports.date')" @model="updateDate('address_corrections.date', $event)" :rules_required="rules_required"></datepicker>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-1" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="correctionsModal = true">
                                            <v-icon aria-hidden="false" color="white" small>
                                                help
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('yego_reports.help') }}</span>
                                </v-tooltip>
                                <v-btn class="right white--text pt-0" color="amarillo" @click="importCorrections">
                                    {{ $t('yego_reports.import') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- Billing -->
                    <v-card elevation="4" class="mx-3 mt-8" v-permission="'billing.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.billing') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="6" class="py-0">
                                        <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="false" v-model="billing.file" accept=".csv" :label="$t('yego_reports.billing_file')"></v-file-input>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <datepicker :open_dialog="menu6" :model="billing.date" :label="$t('yego_reports.date')" @model="updateDate('billing.date', $event)" :rules_required="rules_required"></datepicker>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-1" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="billingModal = true">
                                            <v-icon aria-hidden="false" color="white" small>
                                                help
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('yego_reports.help') }}</span>
                                </v-tooltip>
                                <v-btn class="right white--text pt-0" color="amarillo" @click="billings">
                                    {{ $t('yego_reports.import') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- Returned Billing -->
                    <v-card elevation="4" class="mx-3 mt-8" v-permission="'returned_billing.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.returned_billing') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="6" class="py-0">
                                        <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="false" v-model="returned_billing.file" accept=".csv" :label="$t('yego_reports.returned_billing_file')"></v-file-input>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <datepicker :open_dialog="menu11" :model="returned_billing.date" :label="$t('yego_reports.date')" @model="updateDate('returned_billing.date', $event)" :rules_required="rules_required"></datepicker>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-1" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="returnedBillingModal = true">
                                            <v-icon aria-hidden="false" color="white" small>
                                                help
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('yego_reports.help') }}</span>
                                </v-tooltip>
                                <v-btn class="right white--text pt-0" color="amarillo" @click="returnedBillings">
                                    {{ $t('yego_reports.import') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- minutes -->
                    <v-card elevation="4" class="mx-3 mt-8">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.minutes') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" class="py-0">
                                        <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="false" v-model="minutes.file" accept=".csv" :label="$t('yego_reports.minutes_file')"></v-file-input>
                                    </v-col>
                                    <!-- <v-col cols="6" class="py-0">
                                        <v-menu ref="minutes_date" v-model="menu5" :close-on-content-click="false" :return-value.sync="minutes.date" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field class="py-0" v-model="minutes.date" :label="$t('yego_reports.date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="minutes.date" no-title scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menu5 = false">{{ $t('yego_reports.cancel') }}</v-btn>
                                                <v-btn text color="primary" @click="$refs.minutes_date.save(minutes.date)">{{ $t('yego_reports.accept') }}</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col> -->
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-1" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="minutesModal = true">
                                            <v-icon aria-hidden="false" color="white" small>
                                                help
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('yego_reports.help') }}</span>
                                </v-tooltip>
                                <v-btn class="right white--text pt-0" color="amarillo" @click="minutesShipments">
                                    {{ $t('yego_reports.import') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- Guides Identifier -->
                    <v-card elevation="4" class="mx-3 mt-8" v-permission="'orders_identifier.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.orders_identifier') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" class="py-0">
                                        <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="false" v-model="orders_identifier.file" accept=".csv" :label="$t('yego_reports.guides_file')"></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-1" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="commitsModal = true">
                                            <v-icon aria-hidden="false" color="white" small>
                                                help
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('yego_reports.help') }}</span>
                                </v-tooltip>
                                <v-btn class="right white--text pt-0" color="amarillo" @click="exportIdentifier">
                                    {{ $t('yego_reports.import') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- Products Summary -->
                    <v-card elevation="4" class="mx-3 mt-8" v-permission="'products_summary.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.products_by_order_summary') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" class="py-0">
                                        <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="false" v-model="products_by_orders_summary.file" accept=".csv" :label="$t('yego_reports.orders_file')"></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-1" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="productsByOrdersModal = true">
                                            <v-icon aria-hidden="false" color="white" small>
                                                help
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('yego_reports.help') }}</span>
                                </v-tooltip>
                                <v-btn class="right white--text pt-0" color="amarillo" @click="productsByOrdersSummary">
                                    {{ $t('yego_reports.upload') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                    <!-- Hermes Update Order Status -->
                    <v-card elevation="4" class="mx-3 mt-8" v-permission="'hermes_update_order_status.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.hermes_update_order_status') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" class="py-0">
                                        <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="false" v-model="hermes_status.file" accept=".csv" :label="$t('yego_reports.hermes_file')"></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-1" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="hermesModal = true">
                                            <v-icon aria-hidden="false" color="white" small>
                                                help
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('yego_reports.help') }}</span>
                                </v-tooltip>
                                <v-btn class="right white--text pt-0" color="amarillo" @click="importHermesFile">
                                    {{ $t('yego_reports.import') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- Yego Update Order Status -->
                    <v-card elevation="4" class="mx-3 mt-8" v-permission="'yego_update_order_status.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.yego_update_order_status') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" class="py-0">
                                        <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="false" v-model="yego_status.file" accept=".csv" :label="$t('yego_reports.yego_file')"></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-1" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="yegoModal = true">
                                            <v-icon aria-hidden="false" color="white" small>
                                                help
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('yego_reports.help') }}</span>
                                </v-tooltip>
                                <v-btn class="right white--text pt-0" color="amarillo" @click="importYegoFile">
                                    {{ $t('yego_reports.import') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- Payment Pending -->
                    <v-card elevation="4" class="mx-3 mt-8">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="12" align="center" justify="center">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.payment_pending') }} </h2>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 px-2 pb-2 mt-1">
                            <v-col cols="10">
                                <v-row align="center" justify="center">
                                    <v-col cols="6" class="py-0">
                                        <datepicker :open_dialog="menu14" :model="payment_pending.initial_date" :label="$t('yego_reports.initial_date')" @model="updateDate('payment_pending.initial_date', $event)" :rules_required="rules_required"></datepicker>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <datepicker :open_dialog="menu15" :model="payment_pending.final_date" :label="$t('yego_reports.final_date')" @model="updateDate('payment_pending.final_date', $event)" :rules_required="rules_required"></datepicker>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" class="pt-0" align="center" justify="center">
                                <v-btn class="right white--text pt-0" color="amarillo" @click="exportPaymentPending">
                                    {{ $t('yego_reports.export') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- OV of Odoo -->
                    <v-card elevation="4" class="mx-3 mt-8" v-permission="'odoo_report.show'">
                        <v-row class="px-5 pt-1 pb-5">
                            <v-col cols="10" align="center" justify="center" class="mt-3">    
                                <h2 class="azul--text text-uppercase">{{ $t('yego_reports.ov_odoo_report') }} </h2>
                            </v-col>
                            <v-col cols="2" class="pt-0 mt-3" align="center" justify="center" >
                                <v-btn class="right white--text pt-0" color="amarillo" @click="exportOdooReport">
                                    {{ $t('yego_reports.export') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="correctionsModal" persistent max-width="90%">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer></v-spacer>
                        {{ $t('yego_reports.address_corrections') }}
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" small icon @click="correctionsModal = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                        <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                        <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                        <p class="py-0 my-0">4.- El archivo de correcciones debe ser formato CSV.</p> 
                        <p>5.- Si el campo presenta la leyenda 'Opcional' este campo puede ir vacío.</p> 
                        <p class="py-0 my-0">Columnas: </p>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" style="padding: 0 5px !important;">tracking_guide</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>32782989383</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-row align="center" justify="center" class="my-3">
                            <v-btn color="amarillo" class="white--text" @click="correctionsModal = false">
                                {{ $t('yego_reports.close') }}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="minutesModal" persistent max-width="90%">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer></v-spacer>
                        {{ $t('yego_reports.minutes') }}
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" small icon @click="minutesModal = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                        <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                        <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                        <p class="py-0 my-0">4.- El archivo de actas debe ser formato CSV.</p> 
                        <p class="py-0 my-0">5.- La fecha debe tener fomato AAAA-MM-DD.</p> 
                        <p class="py-0 my-0">6.- Los valores permitidos para el estatus son 1, 2 ó 3. Para poder crear un acta con estatus 2 ó 3 debe existir un acta con estatus 1 asociada a la misma guía.</p> 
                        <p class="py-0 my-0">Columnas: </p>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" style="padding: 0 5px !important;">Guía</th>
                                        <th class="text-left" style="padding: 0 5px !important;">Estatus</th>
                                        <th class="text-left" style="padding: 0 5px !important;">Fecha</th>
                                        <th class="text-left" style="padding: 0 5px !important;">Comentarios</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>278399028393</td>
                                        <td>1</td>
                                        <td>2024-01-31</td>
                                        <td>Orden contiene productos no solicitados o equivocados</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-row align="center" justify="center" class="my-3">
                            <v-btn color="amarillo" class="white--text" @click="minutesModal = false">
                                {{ $t('yego_reports.close') }}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="commitsModal" persistent max-width="90%">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer></v-spacer>
                        {{ $t('yego_reports.delivery_commitment') }}
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" small icon @click="commitsModal = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                        <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                        <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                        <p class="py-0 my-0">4.- El archivo de compromisos debe ser formato CSV.</p> 
                        <p class="py-0 my-0">Columnas: </p>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" style="padding: 0 5px !important;">tracking_guide</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>391793712572</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-row align="center" justify="center" class="my-3">
                            <v-btn color="amarillo" class="white--text" @click="commitsModal = false">
                                {{ $t('yego_reports.close') }}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="billingModal" persistent max-width="90%">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer></v-spacer>
                        {{ $t('yego_reports.billing') }}
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" small icon @click="billingModal = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                        <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                        <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                        <p class="py-0 my-0">4.- El archivo de compromisos debe ser formato CSV.</p> 
                        <p class="py-0 my-0">Columnas: </p>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" style="padding: 0 5px !important;">tracking_guide</th>
                                        <th class="text-left" style="padding: 0 5px !important;">total</th>
                                        <th class="text-left" style="padding: 0 5px !important;">zona ext</th>
                                        <th class="text-left" style="padding: 0 5px !important;">combustible</th>
                                        <th class="text-left" style="padding: 0 5px !important;">iva</th>
                                        <th class="text-left" style="padding: 0 5px !important;">firma</th>
                                        <th class="text-left" style="padding: 0 5px !important;">dimensiones</th>
                                        <th class="text-left" style="padding: 0 5px !important;">correccion</th>
                                        <th class="text-left" style="padding: 0 5px !important;">v declarado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>391793712572</td>
                                        <td>44.5</td>
                                        <td>19.5</td>
                                        <td>13.3</td>
                                        <td>12.5</td>
                                        <td>4.5</td>
                                        <td>2.5</td>
                                        <td>14.5</td>
                                        <td>9.84</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-row align="center" justify="center" class="my-3">
                            <v-btn color="amarillo" class="white--text" @click="billingModal = false">
                                {{ $t('yego_reports.close') }}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="returnedBillingModal" persistent max-width="90%">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer></v-spacer>
                        {{ $t('yego_reports.returned_billing') }}
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" small icon @click="returnedBillingModal = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                        <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                        <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                        <p class="py-0 my-0">4.- El archivo de compromisos debe ser formato CSV.</p> 
                        <p class="py-0 my-0">Columnas: </p>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" style="padding: 0 5px !important;">tracking_guide</th>
                                        <th class="text-left" style="padding: 0 5px !important;">total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>391793712572</td>
                                        <td>44.5</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-row align="center" justify="center" class="my-3">
                            <v-btn color="amarillo" class="white--text" @click="returnedBillingModal = false">
                                {{ $t('yego_reports.close') }}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="productsByOrdersModal" persistent max-width="90%">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer></v-spacer>
                        {{ $t('yego_reports.products_by_order_summary') }}
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" small icon @click="productsByOrdersModal = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                        <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                        <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                        <p class="py-0 my-0">4.- El archivo de compromisos debe ser formato CSV.</p> 
                        <p class="py-0 my-0">Columnas: </p>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" style="padding: 0 5px !important;">order_number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>38920190</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-row align="center" justify="center" class="my-3">
                            <v-btn color="amarillo" class="white--text" @click="productsByOrdersModal = false">
                                {{ $t('yego_reports.close') }}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="hermesModal" persistent max-width="90%">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer></v-spacer>
                        {{ $t('yego_reports.delivery_commitment') }}
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" small icon @click="hermesModal = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                        <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                        <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                        <p class="py-0 my-0">4.- El archivo de compromisos debe ser formato CSV.</p> 
                        <p class="py-0 my-0">Columnas: </p>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" style="padding: 0 5px !important;">tracking_number</th>
                                        <th class="text-left" style="padding: 0 5px !important;">date</th>
                                        <th class="text-left" style="padding: 0 5px !important;">status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>391793712572</td>
                                        <td>dd/mm/yyyy</td>
                                        <td>SEND, INTENTO_DE_ENTREGA_1, INTENTO_DE_ENTREGA_2, INTENTO_DE_ENTREGA_3, RECHAZO, DIRECCION_INCORRECTA, ENTREGADO</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-row align="center" justify="center" class="my-3">
                            <v-btn color="amarillo" class="white--text" @click="hermesModal = false">
                                {{ $t('yego_reports.close') }}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="yegoModal" persistent max-width="90%">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer></v-spacer>
                        {{ $t('yego_reports.delivery_commitment') }}
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" small icon @click="yegoModal = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                        <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                        <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                        <p class="py-0 my-0">4.- El archivo de compromisos debe ser formato CSV.</p> 
                        <p class="py-0 my-0">Columnas: </p>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" style="padding: 0 5px !important;">tracking_number</th>
                                        <th class="text-left" style="padding: 0 5px !important;">date</th>
                                        <th class="text-left" style="padding: 0 5px !important;">status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>391793712572</td>
                                        <td>dd/mm/yyyy</td>
                                        <td>SEND, INTENTO_DE_ENTREGA_1, INTENTO_DE_ENTREGA_2, INTENTO_DE_ENTREGA_3, RECHAZO, DIRECCION_INCORRECTA, ENTREGADO</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-row align="center" justify="center" class="my-3">
                            <v-btn color="amarillo" class="white--text" @click="yegoModal = false">
                                {{ $t('yego_reports.close') }}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="responsesModal" persistent max-width="90%">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer></v-spacer>
                        {{ $t('yego_reports.minutes') }}
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" small icon @click="responsesModal = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <p class="py-0 my-0">Resultados: </p> 
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" style="padding: 0 5px !important;">Id</th>
                                        <th class="text-left" style="padding: 0 5px !important;">Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, i) in results" :key="i">
                                        <td>{{ item.id }}</td>
                                        <td>{{ item.message }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-row align="center" justify="center" class="my-3">
                            <v-btn color="amarillo" class="white--text" @click="responsesModal = false; results = []">
                                {{ $t('yego_reports.close') }}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showProductsSummaryModal" persistent max-width="90%">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer></v-spacer>
                        {{ $t('yego_reports.products_by_order_summary') }}
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" small icon @click="showProductsSummaryModal = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <p class="py-0 my-0">{{ ordersLength }} {{ $t('yego_reports.orders_in_file') }} </p> 
                        <v-simple-table fixed-header>
                            <template v-slot:default>

                                <thead>
                                    <tr>
                                        <th class="text-left" style="padding: 0 5px !important;">{{ $t('yego_reports.products') }}</th>
                                        <th class="text-left" style="padding: 0 5px !important;">{{ $t('yego_reports.quantity')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, i) in productsSummary" :key="i">
                                        <td>{{ item.sku }}</td>
                                        <td>{{ item.quantity }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-row align="center" justify="center" class="my-3">
                            <v-btn color="amarillo" class="white--text" @click="showProductsSummaryModal = false; results = []">
                                {{ $t('yego_reports.accept') }}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
import FileSaver from 'file-saver';

export default {
    computed:{
        client: function(){
            return this.$store.state.clientId
        },
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            types: [{name: this.$t('yego_reports.cod'), value: "cod"}, {name: this.$t('yego_reports.prepaid'), value: "prepaid"}, {name: this.$t('yego_reports.fulfillment_eon'), value: "external"}],
            administrative_history: {
                type: "cod",
                client_id: "",
                initial_date: "",
                final_date: ""
            },
            operative_history: {
                type: "cod",
                client_id: "",
                initial_date: "",
                final_date: ""
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            balance: {
                type: "cod",
                client_id: "",
                initial_date: "",
                final_date: ""
            },
            balancev2: {
                type: "cod",
                client_id: "",
                initial_date: "",
                final_date: ""
            },
            minutes: {
                file: [],
                // date: ""
            },
            commit: {
                file: [],
                date: ""
            },
            orders_identifier: {
                file: []
            },
            products_by_orders_summary: {
                file: []
            },
            hermes_status: {
                file: []
            },
            yego_status: {
                file: []
            },
            address_corrections:{
                file: [],
                date: ''
            },
            billing:{
                file: [],
                date: ''
            },
            returned_billing:{
                file: [],
                date: ''
            },
            payment_pending: {
                initial_date: "",
                final_date: "" 
            },
            correctionsModal: false,
            minutesModal: false,
            commitsModal: false,
            hermesModal: false,
            yegoModal: false,
            responsesModal: false,
            productsByOrdersModal: false,
            showProductsSummaryModal: false,
            billingModal: false,
            returnedBillingModal: false,
            results: [],
            productsSummary: '',
            ordersLength: 0,
            menu: false, // initial date administrative history
            menu1: false, // final date administrative history
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
            menu6: false, // Order Fees Date
            menu7: false,
            menu8: false,
            menu9: false, // Balance v2
            menu10: false,
            menu11: false,
            menu12: false, // inial date operative history
            menu13: false, // final date operative history
            menu14: false, // payment pending initial date
            menu15: false, // payment pending final date
            inputFile: [],
            readFile: '',
            readRows: []
        }
    },
    mounted(){
        this.$store.state.module = "headers.yego_reports";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        updateDate(model, new_date){
            this[model.split(".")[0]][model.split(".")[1]] = new_date;
        },
        exportAdministrativeHistory(){
            this.administrative_history.client_id = this.$store.state.clientId;
            if(this.administrative_history.client_id != "" && this.administrative_history.initial_date != "" && this.administrative_history.final_date != "" && this.administrative_history.type != ""){
                this.$store.state.loaderProps.visible = true
                const lsToken = localStorage.getItem("access_token")
                this.$http.get(this.$store.state.chronosApi + 'getAdministrativeHistory/' + this.administrative_history.client_id + '/' + this.administrative_history.initial_date + '/' + this.administrative_history.final_date + '/' + this.administrative_history.type, { headers: { Authorization: lsToken }, responseType:'blob' })
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, this.$store.state.clientName + ' - Historico_Administrativo - ' + this.administrative_history.final_date + '.xlsx');
                })
                .catch((error) => {
                    this.$store.state.loaderProps.visible = false;
                    if (error.response.status === 404){
                        this.snackbar = {
                            visible: true,
                            color: 'orange',
                            text: this.$t('yego_reports.not_results_found')
                        };
                    } else {
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('yego_reports.export_error')
                        };
                    }
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportOperativeHistory(){
            this.operative_history.client_id = this.$store.state.clientId;
            if(this.operative_history.client_id != "" && this.operative_history.initial_date != "" && this.operative_history.final_date != "" && this.operative_history.type != ""){
                this.$store.state.loaderProps.visible = true
                const lsToken = localStorage.getItem("access_token")
                this.$http.get(this.$store.state.chronosApi + 'getOperativeHistory/' + this.operative_history.client_id + '/' + this.operative_history.initial_date + '/' + this.operative_history.final_date + '/' + this.operative_history.type, { headers: { Authorization: lsToken }, responseType:'blob' })
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, this.$store.state.clientName + ' - Historico_Operativo - ' + this.operative_history.final_date + '.xlsx');
                })
                .catch((error) => {
                    this.$store.state.loaderProps.visible = false;
                    if (error.response.status === 404){
                        this.snackbar = {
                            visible: true,
                            color: 'orange',
                            text: this.$t('yego_reports.not_results_found')
                        };
                    } else {
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('yego_reports.export_error')
                        };
                    }
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportBalance(){
            this.balance.client_id = this.$store.state.clientId;
            if(this.balance.client_id != "" && this.balance.initial_date != "" && this.balance.final_date != "" && this.balance.type != ""){
                this.$store.state.loaderProps.visible = true
                const lsToken = localStorage.getItem("access_token")
                this.$http.get(this.$store.state.chronosApi + 'getBalance/' + this.balance.client_id + '/' + this.balance.initial_date + '/' + this.balance.final_date + '/' + this.balance.type, { headers: { Authorization: lsToken }, responseType:'blob' })
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, this.$store.state.clientName + ' - Balance - ' + this.balance.final_date + '.xlsx');
                })
                .catch((error) => {
                    this.$store.state.loaderProps.visible = false;
                    if (error.response.status === 404){
                        this.snackbar = {
                            visible: true,
                            color: 'orange',
                            text: this.$t('yego_reports.not_results_found')
                        };
                    } else {
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('yego_reports.export_error')
                        };
                    }
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportBalancev2(){
            this.balancev2.client_id = this.$store.state.clientId;
            if(this.balancev2.client_id != "" && this.balancev2.initial_date != "" && this.balancev2.final_date != "" && this.balancev2.type != ""){
                this.$store.state.loaderProps.visible = true
                const lsToken = localStorage.getItem("access_token")
                this.$http.get(this.$store.state.chronosApi + 'getBalanceV2/' + this.balancev2.client_id + '/' + this.balancev2.initial_date + '/' + this.balancev2.final_date + '/' + this.balancev2.type, { headers: { Authorization: lsToken }, responseType:'blob' })
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, this.$store.state.clientName + ' - BalanceV2 - ' + this.balancev2.final_date + '.xlsx');
                })
                .catch((error) => {
                    this.$store.state.loaderProps.visible = false;
                    if (error.response.status === 404){
                        this.snackbar = {
                            visible: true,
                            color: 'orange',
                            text: this.$t('yego_reports.not_results_found')
                        };
                    } else {
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('yego_reports.export_error')
                        };
                    }
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportIdentifier(){
            this.createInput(this.orders_identifier.file, "identifier");
        },
        importCorrections(){
            this.createInput(this.billing.file, "corrections");
        },
        minutesShipments(){
            this.createInput(this.minutes.file, "minutes");
        },
        commits(){
            this.createInput(this.commit.file, "commit");
        },
        billings(){
            this.createInput(this.billing.file, "billing");
        },
        returnedBillings(){
            this.createInput(this.returned_billing.file, "returned billing");
        },
        productsByOrdersSummary(){
            this.createInput(this.products_by_orders_summary.file, "summary");
        },
        createInput(file, type) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows = result.split('\r\n');
                    this.readRows.shift();

                    if(type == "corrections") this.saveCorrections();
                    else if(type == "identifier") this.downloadIdentifier();
                    else if(type == "minutes") this.importMinutes();
                    else if(type == "commit") this.importCommits();
                    else if(type == "summary") this.getProductsByOrder();
                    else if(type == "billing") this.importBilling();
                    else if(type == "returned billing") this.importReturnedBilling();
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        saveCorrections(){
            this.$store.state.loaderProps.visible = true
            const guides = [];
            if(this.readRows.length > 0){
                this.readRows.forEach((x) => {
                    const num = x.split(',');
                    guides.push({
                        'guide': num[0],
                    });
                })
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'addressCorrections/' + this.billings.date, { guides: guides}, { headers: { Authorization: lsToken } })
                .then((response) => {
                    if(response.data.status === 200){
                        this.readRows = [];
                        this.billings.file = null;
                        this.billings.date = "";
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('yego_reports.address_updated')
                        };
                    }
                })
                .catch((error) => {
                    error
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.error_address') 
                    };
                })
                .finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.error_address')
                };
            }
        },
        importMinutes(){
            this.$store.state.loaderProps.visible = true
            const minutes = [];
            if(this.readRows.length > 0){
                this.readRows.forEach((x) => {
                    const num = x.split(',');
                    minutes.push({
                        'guide': num[0],
                        'status': num[1],   
                        'date': num[2],
                        'comments': num[3]
                    });
                })
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'minutesShipments', { minutes: minutes }, { headers: { Authorization: lsToken } })
                .then((response) => {
                    if(response.data.status === 200){
                        this.results = [];
                        this.results = response.data.data;
                        this.responsesModal = true;

                        this.readRows = [];
                        this.minutes.file = null;
                        this.minutes.date = "";
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('yego_reports.address_updated')
                        };
                    }
                })
                .catch((error) => {
                    error
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.error_address') 
                    };
                })
                .finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.error_address')
                };
            }
        },
        importCommits(){
            this.$store.state.loaderProps.visible = true
            const commits = [];
            if(this.readRows.length > 0){
                this.readRows.forEach((x) => {
                    const num = x.split(',');
                    commits.push({
                        'tracking_guide': num[0]
                    });
                })
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'commitShipments/' + this.commit.date, { commits: commits }, { headers: { Authorization: lsToken } })
                .then((response) => {
                    if(response.data.status === 200){
                        this.readRows = [];
                        this.commit.file = null;
                        this.commit.date = "";
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('yego_reports.address_updated')
                        };
                    }
                })
                .catch((error) => {
                    error
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.error_address') 
                    };
                })
                .finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.error_address')
                };
            }
        },
        importBilling(){
            this.$store.state.loaderProps.visible = true
            const results = [];
            if(this.readRows.length > 0){
                this.readRows.forEach((x) => {
                    const num = x.split(',');
                    results.push({
                        'guide': num[0],
                        'total': num[1],
                        'ext_zone': num[2],
                        'fuel': num[3],
                        'iva': num[4],
                        'sign': num[5],
                        'dimensions': num[6],
                        'corrections': num[7],
                        'declared': num[8]
                    });
                })
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'billingShipments/' + this.billing.date, { billing: results }, { headers: { Authorization: lsToken } })
                .then((response) => {
                    if(response.data.status === 200){
                        this.readRows = [];
                        this.billing.file = null;
                        this.billing.date = "";
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('yego_reports.address_updated')
                        };
                    }
                })
                .catch((error) => {
                    error
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.error_address') 
                    };
                })
                .finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.error_address')
                };
            }
        },
        importReturnedBilling(){
            this.$store.state.loaderProps.visible = true
            const results = [];
            if(this.readRows.length > 0){
                this.readRows.forEach((x) => {
                    const num = x.split(',');
                    results.push({
                        'guide': num[0],
                        'billing_returned_cost': num[1]
                    });
                })
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'returnedBillingShipments/' + this.returned_billing.date, { returned_billing: results }, { headers: { Authorization: lsToken } })
                .then((response) => {
                    if(response.data.status === 200){
                        this.readRows = [];
                        this.returned_billing.file = null;
                        this.returned_billing.date = "";
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('yego_reports.address_updated')
                        };
                    }
                })
                .catch((error) => {
                    error
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.error_address') 
                    };
                })
                .finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.error_address')
                };
            }
        },
        downloadIdentifier(){
            this.$store.state.loaderProps.visible = true
            const guides = [];
            if(this.readRows.length > 0){
                this.readRows.forEach((x) => {
                    const num = x.split(',');
                    guides.push(num[0]);
                })
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'orders_identifier', {guides: guides}, { headers: { Authorization: lsToken }, responseType:'blob' })
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data,'Guías_Identificadas.xlsx');
                })
                .catch((err) => {
                    console.log(err)
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.error_address')
                };
            }  
        },
        importHermesFile(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token")
            const formData = new FormData();
            formData.append('file', this.hermes_status.file
            );

            this.$http.post(this.$store.state.chronosApi + 'shipment/hermes/updateStatus', formData, { headers: { Authorization: lsToken, 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                response
                this.$store.state.loaderProps.visible = false; 
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    // text: this.$t('yego_reports.address_updated')
                    text: "¡Completado Correctamente!"
                };   
                this.hermes_status.file = [];
            })
            .catch((err) => {
                console.log(err)
                this.$store.state.loaderProps.visible = false;
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            })
        },
        importYegoFile(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token")
            const formData = new FormData();
            formData.append('file', this.yego_status.file);

            this.$http.post(this.$store.state.chronosApi + 'shipment/yego/updateStatus', formData, { headers: { Authorization: lsToken, 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                response;
                this.$store.state.loaderProps.visible = false; 
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    // text: this.$t('yego_reports.address_updated')
                    text: "¡Completado Correctamente!"
                };   
                this.yego_status.file = [];
            })
            .catch((err) => {
                console.log(err)
                this.$store.state.loaderProps.visible = false;
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            })
        },
        getProductsByOrder(){
            this.ordersLength = 0;
            this.$store.state.loaderProps.visible = true
            const guides = [];
            if(this.readRows.length > 0){
                this.readRows.forEach((x) => {
                    const num = x.split(',');
                    guides.push(num[0]);
                })
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'products_by_orders_summary', {guides: guides}, { headers: { Authorization: lsToken }})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    this.showProductsSummaryModal = true;
                    this.productsSummary = response.data.data;
                    this.ordersLength = response.data.orders;
                    this.products_by_orders_summary.file = [];
                })
                .catch((err) => {
                    console.log(err)
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.error_address')
                };
            }
        },
        exportPaymentPending(){
            if(this.payment_pending.initial_date != "" && this.payment_pending.final_date != ""){
                this.$store.state.loaderProps.visible = true
                const lsToken = localStorage.getItem("access_token")
                this.$http.get(this.$store.state.chronosApi + 'getPaymentPending/' + this.payment_pending.initial_date + '/' + this.payment_pending.final_date, { headers: { Authorization: lsToken }, responseType:'blob' })
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'PagoPendiente-' + this.payment_pending.final_date + '.xlsx');
                })
                .catch((error) => {
                    this.$store.state.loaderProps.visible = false;
                    if (error.response.status === 404){
                        this.snackbar = {
                            visible: true,
                            color: 'orange',
                            text: this.$t('yego_reports.not_results_found')
                        };
                    } else {
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('yego_reports.export_error')
                        };
                    }
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportOdooReport(){
            this.$store.state.loaderProps.visible = true
            const lsToken = localStorage.getItem("access_token")
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/report/odoo', { headers: { Authorization: lsToken }, responseType:'blob' })
            .then(response => {
                this.$store.state.loaderProps.visible = false; 
                FileSaver.saveAs(response.data, 'reporte_ov_odoo.csv');
            })
            .catch((error) => {
                this.$store.state.loaderProps.visible = false;
                if (error.response.status === 404){
                    this.snackbar = {
                        visible: true,
                        color: 'orange',
                        text: this.$t('yego_reports.not_results_found')
                    };
                } else {
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                }
            })
        }
    },
    watch:{
    }
}
</script>

<style>

</style>