<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="8" md="4" class="py-0 my-0">
                    <client-selector :addAllOption="true" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>  
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text" color="amarillo" @click="showModalCutoff = true;">
                        {{ $t('cod_collection.new_cutoff') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>   
        <v-row justify="center">
            <v-dialog v-model="modal" persistent max-width="90%">
                <v-card>
                <v-card-title class="justify-center">
                    <v-spacer></v-spacer>
                    {{ $t('cod_collection.upload_payments') }}
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" small icon @click="stepper = 1;modal = false"><v-icon small>close</v-icon></v-btn>
                </v-card-title>

                <v-card-text>
                    <v-stepper v-model="stepper">
                        <v-stepper-header>
                            <v-stepper-step :complete="stepper > 1" step="1" color="amarillo">{{ $t('cod_collection.select_file') }}</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="stepper > 2" step="2" color="amarillo">{{ $t('cod_collection.verify_data') }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-card flat class="mb-5" >
                                    <v-form ref="form" lazy-validation>
                                        <v-row>
                                            <v-col cols="5" class="my-3">
                                                <datepicker :open_dialog="menu" :model="date" :label="$t('cod_collection.payment_date')" @model="updatePaymentDate($event)" :rules_required="rules_required"></datepicker>
                                            </v-col>
                                            <v-col cols="5">
                                                <v-file-input v-model="inputFile" :rules="rules_required" color="amarillo" accept=".csv" :label="$t('cod_collection.select_file')"></v-file-input>
                                            </v-col>
                                            <v-col cols="2" align="center" justify="center" class="my-2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mx-0" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="stepper = 3">
                                                            <v-icon aria-hidden="false" color="white" small>
                                                                help
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('upload_massive.help') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                                
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="right white--text" color="amarillo" @click="uploadFile" >
                                        {{ $t('cod_collection.next') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5" >
                                    <v-card-text>
                                        <p>{{readRows.length}} {{ $t('cod_collection.guides_in_file') }}</p>
                                    </v-card-text>
                                    <v-simple-table v-if="this.readRows.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th v-for="(th, key) in sTHeaders" :key="key" class="text-left">{{ th }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in readRows" :key="i">
                                                    <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mx-3" text @click="stepper = 1; sTHeaders = []; readRows = []">{{ $t('cod_collection.go_back') }}</v-btn>
                                    <v-btn color="amarillo" class="white--text" @click="saveGuides">
                                        {{ $t('cod_collection.upload_data') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                                <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                <p class="py-0 my-0">4.- El archivo de pagos debe ser formato CSV.</p> 
                                <p>5.- Si el campo presenta la leyenda 'Opcional' este campo puede ir vacío.</p> 
                                <p class="py-0 my-0">Columnas: </p>
                                <v-simple-table fixed-header>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left" style="padding: 0 5px !important;">número_orden</th>
                                                <th class="text-left" style="padding: 0 5px !important;">cod</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>390492239184</td>
                                                <td>200</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <v-row align="center" justify="center" class="my-3">
                                    <v-btn color="amarillo" class="white--text" @click="stepper = 1">
                                        {{ $t('products.go_back') }}
                                    </v-btn>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showModalCutoff" persistent max-width="90%" style="width: 500px !important;">
                <v-card style="width:500px !important">
                    <v-card-title class="justify-center mt-4">
                        <v-row align="center" justify="center" class="my-2">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('cod_collection.new_cutoff')}}</label>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <client-selector :addAllOption="false" class="py-0 mb-1" ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>  
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="cutoff.parcel" item-value="value" item-text="text" :items="shipping_services" :label="$t('cod_collection.shipping_service')"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <datepicker :open_dialog="menu2" :model="cutoff.initial_date" :label="$t('cod_collection.initial_date')" @model="updateDate($event)" :rules_required="rules_required"></datepicker>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <datepicker :open_dialog="menu3" :model="cutoff.final_date" :label="$t('cod_collection.final_date')" @model="updateDateFinal($event)" :rules_required="rules_required"></datepicker>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start" class="my-2 mx-0">
                            <label style="font-size: 12px; font-weight: bold;" class="azul--text text-uppercase">{{$t('cod_collection.type')}}</label>
                        </v-row>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <v-radio-group class="my-0" v-model="cutoff.is_cod">
                                    <v-radio color="info" :label="$t('cod_collection.cod')" :value="true"></v-radio>
                                    <v-radio color="info" :label="$t('cod_collection.regular')" :value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="showModalCutoff = false">{{ $t('cod_collection.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="saveCutoff">
                                {{ $t('cod_collection.save') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-row>  
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        headers(){
            return [
                { text: this.$t('cod_collection.customer'), align: "center", value: 'client.name', class: 'text-uppercase' },
                { text: this.$t('cod_collection.period'), align: "center", value: 'period', class: 'text-uppercase' },
                { text: this.$t('cod_collection.shipping_service'), align: "center", value: 'parcel', class: 'text-uppercase' },
                { text: this.$t('cod_collection.conciliated'), align: "center", value: 'conciliated', class: 'text-uppercase' },
                { text: this.$t('cod_collection.pendings'), align: "center", value: 'pending', class: 'text-uppercase' },
                { text: this.$t('cod_collection.paid'), align: "center", value: 'paid', class: 'text-uppercase' },
                { text: this.$t('cod_collection.to_pay'), align: "center", value: 'toPay', class: 'text-uppercase' },
                { text: this.$t('cod_collection.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            numberFormat: new Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            idCutoff: '',
            actions: [
                {
                    name: this.$t('cod_collection.upload_payment'),
                    icon: {
                        color: 'accent',
                        icon: 'file_upload'
                    },
                    method: 'openModal',
                    permission: "cod_collection.index"
                },
            ],
            description: '',
            props: {
                search: '',
                items: [],
            },
            modal: false,
            unknown: false,
            menu: false,
            inputFile: [],
            readFile: '',
            readRows: [],
            stepper: 1,
            date: '',
            all_cutoffs: [],
            showModalCutoff: false,
            shipping_services: this.$store.state.shipping_services,
            cutoff: {
                parcel: "",
                initial_date: "",
                final_date: "",
                client_id: "",
                warehouse_id: this.$store.state.warehouseId,
                is_cod: false
            },
            clients: [],
            menu2: false,
            menu3: false,
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.cod_collection";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        updateDate(newDate){
            this.cutoff.initial_date = newDate;
        },
        updateDateFinal(date){
            this.cutoff.final_date = date;
        },
        updatePaymentDate(date){
            this.date = date;
        },
        index(){
            this.props.items = []
            this.all_cutoffs= [];
            this.$store.state.clientId = "all"
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'cutoff/true', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data = response.data.data;
                    response.data.forEach(x =>{
                        x = x.attributes;
                        if(x['conciliated'] == null || x['conciliated'] == undefined) x['conciliated'] = 0;
                        if(x['pending'] == null || x['pending'] == undefined) x['pending'] = 0;
                        if(x['paid'] == null || x['paid'] == undefined) x['paid'] = '$' + this.numberFormat.format(0);
                        else x['paid'] = '$' + this.numberFormat.format(x['paid']);
                        if(x['toPay'] == null || x['toPay'] == undefined) x['toPay'] = '$' + this.numberFormat.format(0);
                        else x['toPay'] = '$' + this.numberFormat.format(x['toPay']);

                        x['period'] = x.initial_date + " - " + x.final_date;
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x['parameters'] = x.id;
                        this.props.items.push(x);
                    });
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            })
        },
        openModal(cutoff){
            this.idCutoff = cutoff;
            this.modal = true;
        },
        uploadFile() {
            if(this.$refs.form.validate()){
                // const files = e.target.files;
                // if (!files.length) return;
                this.createInput(this.inputFile);
            }
        },
        createInput(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows = result.split('\r\n');
                    this.sTHeaders = this.readRows[0].split(',')
                    this.readRows.shift()
                    this.stepper = 2
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        saveGuides(){
            this.$store.state.loaderProps.visible = true
            const info = [];
            this.readRows.forEach((x) => {
                const num = x.split(',');
                
                if(num[0] != '' && num[0] != null){
                    info.push ({
                        'unique_order_number': num[0],
                        'cod': num[1]
                    });
                }
            });

            const lsToken = localStorage.getItem("access_token")
            this.$http.post(this.$store.state.chronosApi + 'cutoff/guides', {'cutoff_id': this.idCutoff, guides: info, 'paid_at': this.date}, { headers: { Authorization: lsToken } })
            .then((response) => {
                if(response.status === 200){
                    this.modal = false;
                    this.index();
                    this.stepper = 1;
                    this.sTHeaders = [];
                    this.readRows = [];
                    this.date = "";

                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('cod_collection.upload_successfully')
                    }
                }
            })
            .catch((error) => {
                error
            })
            .finally(() => {
                this.$store.state.loaderProps.visible = false
            })
        },
        saveCutoff(){
            const i_d = new Date(this.cutoff.initial_date);
            const e_d = new Date(this.cutoff.final_date);
            if(i_d >= e_d){
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('cod_collection.dates_error')
                }
                
                return; 
            }

            const lsToken = localStorage.getItem("access_token")
            this.$http.post(this.$store.state.chronosApi + 'cutoffs', this.cutoff, { headers: { Authorization: lsToken } })
            .then((response) => {
                if(response.status === 201){
                    this.showModalCutoff = false;
                    this.cutoff = {
                        parcel: "",
                        initial_date: "",
                        final_date: "",
                        client_id: "",
                        warehouse_id: this.$store.state.warehouseId,
                        is_cod: false
                    }
                    this.index();
                    this.stepper = 1;
                    this.sTHeaders = [];
                    this.readRows = [];
                    this.date = "";

                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('cod_collection.cutoff_created')
                    }
                }
            })
            .catch((error) => {
                error
            })
            .finally(() => {
                this.$store.state.loaderProps.visible = false
            })
        }
    },
    watch: {
        '$store.state.clientId': function (){
            this.cutoff.client_id = this.$store.state.clientId;
            this.props.items = [];
            if(this.$store.state.clientId == 'all') this.props.items = this.all_cutoffs;
            else{
                this.all_cutoffs.forEach(x => {
                    if(x.client.id == this.$store.state.clientId) this.props.items.push(x);
                })
            }
        }
    }
}
</script>
<style>
</style>