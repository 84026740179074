<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('fixed_assets.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="showExport = true">
                        {{ $t('fixed_assets.export') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="showExport" persistent max-width="90%" style="width: 500px !important;">
                <v-card style="width:500px !important">
                    <v-card-title class="justify-center mt-4">
                        <v-row align="center" justify="center" class="my-2">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('fixed_assets.export_data')}}</label>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="6" class="py-0">
                                <datepicker :open_dialog="menu2" :model="report.initial_date" :label="$t('fixed_assets.initial_date')" @model="updateInitialDate($event)" :rules_required="rules_required"></datepicker>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <datepicker :open_dialog="menu3" :model="report.final_date" :label="$t('fixed_assets.final_date')" @model="updateFinalDate($event)" :rules_required="rules_required"></datepicker>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="showExport = false">{{ $t('fixed_assets.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="exportData">
                                {{ $t('fixed_assets.export') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('fixed_assets.id'), align: "center", value: 'id', class: 'text-uppercase' },
                { text: this.$t('fixed_assets.purchase_order'), align: "center", value: '', class: 'text-uppercase' },
                { text: this.$t('fixed_assets.sku'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('fixed_assets.description'), align: "center", value: 'description', class: 'text-uppercase' },
                { text: this.$t('fixed_assets.brand'), align: "center", value: '', class: 'text-uppercase' },
                { text: this.$t('fixed_assets.model'), align: "center", value: '', class: 'text-uppercase' },
                { text: this.$t('fixed_assets.Serie'), align: "center", value: '', class: 'text-uppercase' },
                { text: this.$t('fixed_assets.fa'), align: "center", value: 'fraction', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            report:{
                initial_date: '',
                final_date: ''
            },
            menu2: false,
            menu3: false,
            showExport: false,
            warehouse_id: this.$store.state.warehouseId
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.fixed_assets";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        updateInitialDate(value){
            this.report.initial_date = value;
        },
        updateFinalDate(value){
            this.report.initial_date = value;
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'customs/catalogs/products?warehouse=' + this.warehouse_id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.props.items = response.data.data;
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        exportData(){

        }
    }
}
</script>

<style>
</style>