<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_customer.new') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('create_customer.general_data')}}</label>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="customer.name" :label="$t('create_customer.tradename')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="customer.social_reason" :label="$t('create_customer.business_name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="customer.is_national" item-text="text" item-value="value" :items="behaviors" :label="$t('create_customer.operation_type')" ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.is_national != 1">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="customer.country" :items="countries" :label="$t('create_customer.country')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.is_national != 1 && customer.country == 'MX'">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="customer.state" :items="states" :label="$t('create_customer.state')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.is_national != 1 && customer.country == 'MX'">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="customer.municipality" :items="municipalities" :label="$t('create_customer.municipality')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.is_national != 1">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="customer.neighborhood" :label="$t('create_customer.neighborhood')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.is_national != 1">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="customer.street" :label="$t('create_customer.street')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.is_national != 1">
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="customer.zipcode" :label="$t('create_customer.zip_code')" :rules="rules_zipcode"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="py-0" v-if="customer.is_national != 1">
                                <v-text-field class="py-0" v-model="customer.external_number" :label="$t('create_customer.ext_number')" :rules="rules_external"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="py-0" v-if="customer.is_national != 1">
                                <v-text-field class="py-0" v-model="customer.internal_number" :label="$t('create_customer.int_number')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.is_national != 1">
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="customer.contact_phone" counter="10" :label="$t('create_customer.contact_phone')" :rules="rules_phone"></v-text-field>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="customer.service_phone" counter="10" :label="$t('create_customer.customer_service_phone')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="customer.email" :label="$t('create_customer.email')" :rules="email_rules"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.is_national != 1">
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="customer.immex_ssn" :label="$t('create_customer.immex')"></v-text-field>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="customer.rfe_idc" :label="$t('create_customer.rfe')"></v-text-field>
                            </v-col>
                        </v-row>

                        <!-- Warehouse data  -->
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('create_customer.warehouse')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-row class="px-0 py-0 my-0" justify="start" align="start">
                                    <v-col cols="6" class="py-0" style="display:flex" v-for="(warehouse, i) of customer.warehouses" :key="i">
                                        <v-checkbox class="mt-0" color="info" v-model="warehouse.value" :label="warehouse.name"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="12" class="py-0" style="display:flex">
                                        <v-checkbox class="mt-0" color="info" v-model="customer.hd_services" :label="$t('create_customer.hd_services_required')"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="12" class="py-0" style="display:flex">
                                        <v-checkbox class="mt-0" color="info" v-model="customer.picking_required" :label="$t('create_customer.advanced_picking')"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- RFID -->
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('create_customer.rfid_tech')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 pb-2 my-0" justify="center" align="center">
                            <v-col cols="4" class="px-3 py-0">
                                <span >{{ $t('create_customer.is_rfid') }}:</span>
                            </v-col>
                            <v-col cols="2" class="px-3 py-0" justify="end" align="end">
                                <v-btn class="radio-left" v-bind:color="is_rfid == 0 ? 'info' : 'white'" @click="(is_rfid = 0)">{{ $t('create_customer.no') }}</v-btn>
                                <v-btn class="radio-right" v-bind:color="is_rfid == 1 ? 'info' : 'white'" @click="(is_rfid = 1)">{{ $t('create_customer.yes') }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 pb-2 my-0" justify="center" align="center" v-if="is_rfid == true">
                            <v-col cols="4" class="px-3 py-0">
                                <span >{{ $t('create_customer.rfid_by_product') }}:</span>
                            </v-col>
                            <v-col cols="2" class="px-3 py-0" justify="end" align="end">
                                <v-btn class="radio-left" v-bind:color="customer.rfid_by_product == 0 ? 'info' : 'white'" @click="(customer.rfid_by_product = 0)">{{ $t('create_customer.no') }}</v-btn>
                                <v-btn class="radio-right" v-bind:color="customer.rfid_by_product == 1 ? 'info' : 'white'" @click="(customer.rfid_by_product = 1)">{{ $t('create_customer.yes') }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="is_rfid == true">
                            <v-col cols="6" class="py-0">
                                <label>{{$t('create_customer.warehouse_rfid')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="is_rfid == true">
                            <v-col cols="6" class="py-0">
                                <v-row class="px-0 py-0 my-0" justify="start" align="start">
                                    <v-col cols="6" class="py-0" style="display:flex" v-for="(warehouse, i) of customer.warehouses" :key="i">
                                        <v-checkbox class="mt-0" color="info" v-if="warehouse.value == true" v-model="warehouse.is_rfid" :label="warehouse.name"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- Fulfillment Data -->
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('create_customer.fulfillment')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 pb-2 my-0" justify="center" align="center">
                            <v-col cols="4" class="px-3 py-0">
                                <span >{{ $t('create_customer.client_fulfillment') }}:</span>
                            </v-col>
                            <v-col cols="2" class="px-3 py-0" justify="end" align="end">
                                <v-btn class="radio-left" v-bind:color="fulfillment == 0 ? 'info' : 'white'" @click="fulfillment = 0; customer.fulfillment = 'none'">{{ $t('create_customer.no') }}</v-btn>
                                <v-btn class="radio-right" v-bind:color="fulfillment == 1? 'info' : 'white'" @click="fulfillment = 1">{{ $t('create_customer.yes') }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="fulfillment == 1">
                            <v-col cols="6" class="py-0">
                                <label>{{$t('create_customer.select_fulfillment')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="fulfillment == 1">
                            <v-col cols="6" class="py-0">
                                <v-radio-group class="my-0" v-model="customer.fulfillment">
                                    <v-radio v-for="t of fullfillment_types" color="info" :key="t.label" :label="t.label" :value="t.value"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-3 mb-1" justify="center" align="center" v-if="fulfillment == 1">
                            <v-col cols="6" class="py-0">
                                <label>{{$t('create_customer.select_shipping_services')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="fulfillment == 1 && customer.shipping_accounts.length > 0">
                            <v-col cols="6" class="py-0">
                                <v-row class="px-0 py-0 my-0" justify="start" align="start">
                                    <v-col cols="6" class="py-0" style="display:flex" v-for="(ss, i) of customer.shipping_accounts" :key="i">
                                        <v-checkbox class="mt-0" color="info" v-model="ss.value" :label="ss.name"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 pb-2" justify="center" align="center" v-if="fulfillment == 1">
                            <v-col cols="4" class="px-3 py-0">
                                <span >{{ $t('create_customer.shipments_notifications') }}:</span>
                            </v-col>
                            <v-col cols="2" class="px-3 py-0" justify="end" align="end">
                                <v-btn class="radio-left" v-bind:color="customer.shipments_notifications == 0 ? 'info' : 'white'" @click="customer.shipments_notifications = 0">{{ $t('create_customer.no') }}</v-btn>
                                <v-btn class="radio-right" v-bind:color="customer.shipments_notifications == 1? 'info' : 'white'" @click="customer.shipments_notifications = 1">{{ $t('create_customer.yes') }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="fulfillment == 1">
                            <v-col cols="6" class="py-0">
                                <v-checkbox class="mt-0" color="info" v-model="customer.avoid_validations" :label="$t('create_customer.avoid_validations')"></v-checkbox>
                            </v-col>
                        </v-row>    

                        <!-- Operation Notifications -->
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('create_customer.notifications')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 pb-2 mt-2" justify="center" align="center">
                            <v-col cols="4" class="px-3 py-0">
                                <span >{{ $t('create_customer.operation_notifications') }}:</span>
                            </v-col>
                            <v-col cols="2" class="px-3 py-0" justify="end" align="end">
                                <v-btn class="radio-left" v-bind:color="operation_notifications == 0 ? 'info' : 'white'" @click="operation_notifications = 0">{{ $t('create_customer.no') }}</v-btn>
                                <v-btn class="radio-right" v-bind:color="operation_notifications == 1? 'info' : 'white'" @click="operation_notifications = 1">{{ $t('create_customer.yes') }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0 mt-2" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <!-- <v-text-field class="py-0" v-if="operation_notifications == 1" v-model="customer.email_to_notify" :label="$t('create_customer.email_to_notify')" :rules="email_rules"></v-text-field> -->
                                <v-combobox v-model="customer.email_to_notify"
                                            v-if="operation_notifications == 1"
                                            :label="$t('create_customer.email_to_notify')"
                                            small-chips
                                            clearable
                                            :rules="multiple_email_rules"
                                            :delimiters="[',', ' ']"
                                            multiple
                                            class="py-0 mt-4">
                                    <template v-slot:selection="data">
                                        <v-chip :input-value="data.selected"
                                                close
                                                label
                                                @input="remove(data.item)">
                                            <strong>{{ data.item }}</strong>&nbsp;
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                        
                        <v-row class="px-5 py-0 mt-3 mb-1" justify="center" align="center" v-if="operation_notifications == 1">
                            <v-col cols="4" class="py-0">
                                <label>{{$t('create_customer.select_language')}}</label>
                            </v-col>
                            
                            <v-col cols="2" class="px-3 py-0" justify="end" align="end">
                                <v-btn class="radio-left-word" v-bind:color="customer.language == 'es' ? 'info' : 'white'" @click="customer.language = 'es'">{{ $t('create_customer.es') }}</v-btn>
                                <v-btn class="radio-right-word" v-bind:color="customer.language == 'en' ? 'info' : 'white'" @click="customer.language = 'en'">{{ $t('create_customer.en') }}</v-btn>
                            </v-col>
                        </v-row>

                        <v-row class="px-5 py-0 mt-3 mb-1" justify="center" align="center" v-if="operation_notifications == 1">
                            <v-col cols="6" class="py-0">
                                <label>{{$t('create_customer.select_stepts')}}</label>
                            </v-col>
                        </v-row>

                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="operation_notifications == 1">
                            <v-col cols="6" class="py-0">
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="12" class="py-0" style="display:flex">
                                        <v-checkbox class="mt-0" color="info" v-model="customer.arrival_ad" :label="$t('create_customer.create')"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="12" class="py-0" style="display:flex">
                                        <v-checkbox class="mt-0" color="info" v-model="customer.in_out_ad" :label="$t('create_customer.on_premises')"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="12" class="py-0" style="display:flex">
                                        <v-checkbox class="mt-0" color="info" v-model="customer.ramping_ad" :label="$t('create_customer.ramping')"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="12" class="py-0" style="display:flex">
                                        <v-checkbox class="mt-0" color="info" v-model="customer.start_process_ad" :label="$t('create_customer.in_process')"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="12" class="py-0" style="display:flex">
                                        <v-checkbox class="mt-0" color="info" v-model="customer.end_process_ad" :label="$t('create_customer.end_process')"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="12" class="py-0" style="display:flex">
                                        <v-checkbox class="mt-0" color="info" v-model="customer.exit_truck_ad" :label="$t('create_customer.end_operation')"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                         <!-- Reports -->
                         <v-row class="px-5 py-0 mt-3 mb-1" justify="center" align="center" v-if="operation_notifications == 1">
                            <v-col cols="6" class="py-0">
                                <label>{{$t('create_customer.select_reports')}}</label>
                            </v-col>
                        </v-row>
                        <v-row  class="px-5 py-0 my-0" justify="center" align="center" v-if="operation_notifications == 1">
                            <v-col cols="6" class="py-0">
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="8" class="py-0" style="display:flex">
                                        <v-checkbox class="mt-0" color="info" v-model="customer.operations_by_customer_ad" :label="$t('create_customer.operations')"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4" class="py-0">
                                        <v-autocomplete class="mt-0 py-0" v-model="customer.operations_by_customer_time" item-text="label" item-value="value" :items="frequencies" :label="$t('create_customer.frequency')"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="8" class="py-0" style="display:flex">
                                        <v-checkbox class="mt-0" color="info" v-model="customer.stock_ad" :label="$t('create_customer.stock')"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4" class="py-0">
                                        <v-autocomplete class="mt-0 py-0" v-model="customer.stock_time" item-text="label" item-value="value" :items="frequencies" :label="$t('create_customer.frequency')"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="12" class="py-0" style="display:flex">
                                        <v-checkbox class="mt-0" color="info" v-model="customer.storage_policies_ad" :label="$t('create_customer.storage_policies')"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0 mt-2" justify="center" align="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_customer.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'client.create'">
                                {{ $t('create_customer.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        rules_phone(){
            return [
                v => !!v || this.$t('create_appointment.validations.required_field'), 
                v => /^(|\d)+$/.test(v) || this.$t('create_customer.validations.phone_format'),
                v => (v && v.length <= 12) || this.$t('create_customer.validations.phone_max_lenght'),
                v => (v && v.length >= 10) || this.$t('create_customer.validations.phone_min_lenght'),
        ]},
        rules_zipcode(){
            return [
                v => !!v || this.$t('create_appointment.validations.required_field'), 
                v => (v && v.length == 5) || this.$t('create_customer.validations.zipcode_lenght'),
                v => /^(|\d)+$/.test(v) || this.$t('create_customer.validations.zipcode_format'),
        ]},
        rules_external(){
            return [
                v => !!v || this.$t('create_appointment.validations.required_field'), 
                v => (v && v.length <= 5) || this.$t('create_customer.validations.zipcode_lenght'),
                v => /^(|\d)+$/.test(v) || this.$t('create_customer.validations.zipcode_format'),
            ]
        },
        email_rules(){
            return [
                v => v.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || this.$t('create_customer.validations.email')
            ]
        }, 
        multiple_email_rules(){
            return [
                v => {
                    for (let i = 0; i < v.length; i++) {
                        if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v[i]))) {
                            return this.$t('create_customer.validations.email');
                        }
                    }
                }
            ]        
        }
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            countries: [],
            states: [],
            municipalities: [],
            countries_load: false,
            states_load: false,
            municipalities_load: false,
            customer: {
                name: "",
                social_reason: "",
                country: "",
                state: "",
                neighborhood: "",
                street: "",
                zipcode: "",
                external_number: "",
                internal_number: "",
                municipality: "",
                contact_phone: "",
                service_phone: "",
                email: "",
                is_national: 1,
                immex_ssn: '',
                rfe_idc: '',
                language: 'es',
                warehouses: [],
                fulfillment: 0,
                return_label: 0,
                shipments_notifications: 1,
                shipping_accounts: [],
                email_to_notify: '',
                in_out_ad: 0,
                arrival_ad: 0,
                ramping_ad: 0,
                start_process_ad: 0,
                end_process_ad: 0,
                exit_truck_ad:0,
                operations_by_customer_ad: 0,
                stock_ad: 0,
                storage_policies_ad: 0,
                operations_by_customer_time: "",
                stock_time: "",
                avoid_validations: false,
                rfid_by_product: 0,
                hd_services: 0,
                picking_required: 0
            },
            frequencies: [
                {label: this.$t("create_customer.everyday"), value: "1"},
                {label: this.$t("create_customer.fridays"), value: "2"},
                {label: this.$t("create_customer.end_of_month"), value: "3"},
            ],
            is_rfid: false,
            operation_notifications: 0,
            fulfillment : 0,
            fullfillment_types: [
                {label: this.$t("create_customer.cod"), value: 1},
                {label: this.$t("create_customer.regulars"), value: 2},
                {label: this.$t("create_customer.both"), value: 3}
            ],
            behaviors: [ 
                { text: this.$t('edit_customer.national_operation'), value: 1 },
                { text: this.$t('edit_customer.international_operation'), value: 0 },
                { text: this.$t('edit_customer.both_operation'), value: 2 }
            ],
            settings: {
                fedex: {
                    shipping_service: "FEDEX",
                    fulfillment: 1,
                    label: 1,
                    cod_fee: 1,
                    shipping_1kg: 1,
                    shipping_2kg: 1,
                    return_1kg: 1,
                    return_2kg: 1,
                    packing_1kg: 1,
                    packing_2kg: 1,
                    extended_zone: 1,
                    address_correction: 1,
                    order_insurance: 1
                },
                minutos: {
                    shipping_service: "99MINUTOS",
                    fulfillment: 1,
                    label: 1,
                    cod_fee: 1,
                    shipping_1kg: 1,
                    shipping_2kg: 1,
                    return_1kg: 1,
                    return_2kg: 1,
                    packing_1kg: 1,
                    packing_2kg: 1,
                    extended_zone: 1,
                    address_correction: 1,
                    order_insurance: 1
                },
                quiken: {
                    shipping_service: 'QUIKEN',
                    fulfillment: 1,
                    label: 1,
                    cod_fee: 1,
                    shipping_1kg: 1,
                    shipping_2kg: 1,
                    return_1kg: 1,
                    return_2kg: 1,
                    packing_1kg: 1,
                    packing_2kg: 1,
                    extended_zone: 1,
                    address_correction: 1,
                    order_insurance: 1
                },
                ups: {
                    shipping_service: 'UPS',
                    fulfillment: 1,
                    label: 1,
                    cod_fee: 1,
                    shipping_1kg: 1,
                    shipping_2kg: 1,
                    return_1kg: 1,
                    return_2kg: 1,
                    packing_1kg: 1,
                    packing_2kg: 1,
                    extended_zone: 1,
                    address_correction: 1,
                    order_insurance: 1
                },
                yego: {
                    shipping_service: 'YEGO',
                    fulfillment: 1,
                    label: 1,
                    cod_fee: 1,
                    shipping_1kg: 1,
                    shipping_2kg: 1,
                    return_1kg: 1,
                    return_2kg: 1,
                    packing_1kg: 1,
                    packing_2kg: 1,
                    extended_zone: 1,
                    address_correction: 1,
                    order_insurance: 1
                },
                otra: {
                    shipping_service: 'OTRA',
                    fulfillment: 1,
                    label: 1,
                    cod_fee: 1,
                    shipping_1kg: 1,
                    shipping_2kg: 1,
                    return_1kg: 1,
                    return_2kg: 1,
                    packing_1kg: 1,
                    packing_2kg: 1,
                    extended_zone: 1,
                    address_correction: 1,
                    order_insurance: 1
                },
                merq: {
                    shipping_service: 'MERQ',
                    fulfillment: 1,
                    label: 1,
                    cod_fee: 1,
                    shipping_1kg: 1,
                    shipping_2kg: 1,
                    return_1kg: 1,
                    return_2kg: 1,
                    packing_1kg: 1,
                    packing_2kg: 1,
                    extended_zone: 1,
                    address_correction: 1,
                    order_insurance: 1
                },
                jtexpress: {
                    shipping_service: 'JTEXPRESS',
                    fulfillment: 1,
                    label: 1,
                    cod_fee: 1,
                    shipping_1kg: 1,
                    shipping_2kg: 1,
                    return_1kg: 1,
                    return_2kg: 1,
                    packing_1kg: 1,
                    packing_2kg: 1,
                    extended_zone: 1,
                    address_correction: 1,
                    order_insurance: 1
                }
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_customer";
    },
    methods: {
        index(){
            // Get countries
            this.$store.state.loaderProps.visible = true;
            this.$http.get('https://locations.intech.mx/api/getCountries')
            .then((response) => {
                this.countries = response.data
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            // Get current warehouses
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'warehouses', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach(x =>{
                        this.customer.warehouses.push({name:x.name, value: false, is_rfid: false});
                    });
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')                      
                    }
                }
            });

            // Get current shipping_accounts
            this.$http.get(this.$store.state.chronosApi + 'shipping_accounts', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.data.forEach(x =>{
                        x = x.attributes;
                        this.customer.shipping_accounts.push({name:x.name, value: false});
                    });
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            });
        },
        save(){
            const validate = this.$refs.form.validate();
            this.$store.state.loaderProps.visible = true;
            var at_least_one_warehouse = false;

            this.customer.warehouses.forEach(x => {
                if(x.value == true) at_least_one_warehouse = true;
            });

            if(validate && at_least_one_warehouse){
                const lsToken = localStorage.getItem("access_token");
                if(this.customer.email_to_notify != null && this.customer.email_to_notify != "") this.customer.email_to_notify = this.customer.email_to_notify.join(',');
                this.customer['settings'] = this.settings;
                this.$http.post(this.$store.state.chronosApi + 'clients', this.customer, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 201){
                        this.$store.state.loaderProps.visible = false;
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('create_custom_agent.responses.success')
                        }
                        this.$router.push({ name: "CustomersIndex" });  
                    }
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 201){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                });
            }
            else{
                this.$store.state.loaderProps.visible = false;
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('create_customer.invalid_form')
                }
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        "customer.country": function(){
            this.states = [];
            this.states_load = true;
            this.$http.get("https://locations.intech.mx/api/getStates/" + this.customer.country, {})
            .then((response) => {
                if(response.status === 200){
                    this.states = response.data
                    this.states_load = false;
                }
            }).finally(() => {
                this.states_load = false;
            });
        },
        "customer.state": function(){
            this.municipalities = [];
            this.municipalities_load = true;
            this.$http.get("https://locations.intech.mx/api/getCities/" + this.customer.country + "/" + this.customer.state, {})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach(x => {
                        this.municipalities.push(x.text);
                    })
                    this.municipalities_load = false;
                }
            }).finally(() => {
                this.municipalities_load = false;
            });
        }
    }
}
</script>

<style lang="css">
</style>