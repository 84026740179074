<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col cols="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('visitors.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="5" align="end" class="py-0">
                    <v-row class="px-0 mx-0 py-0 my-0" align="end" justify="end">
                        <v-tooltip bottom v-permission="'visitors.gafete'">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="right white--text tools-view-height mr-3" color="amarillo" @click="loadScheduledVisitors()" style="max-width: 40px !important; min-width: 40px !important" >
                                    <v-icon class="material-icons-outlined">badge</v-icon>
                                </v-btn>
                            </template>

                            <span>{{ $t('visitors.show_scheduled') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom v-permission="'visitors.qr'">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="right white--text tools-view-height mr-3" color="amarillo" @click="modalScannQR = true;" style="max-width: 40px !important; min-width: 40px !important" >
                                    <v-icon class="material-icons-outlined">qr_code_2</v-icon>
                                </v-btn>
                            </template>

                            <span>{{ $t('visitors.scan_code') }}</span>
                        </v-tooltip>
                        <v-btn class="right white--text tools-view-height mr-3" color="amarillo" :to="'/entrance/visitors/create_schedule'" v-permission:all="'visitors.show'|'visitors.gafete'">
                            {{ $t('visitors.schedule_visit') }}
                        </v-btn>
                        <v-btn class="right white--text tools-view-height" color="amarillo" :to="'/entrance/visitors/create/new'" v-permission="'visitors.show'">
                            {{ $t('visitors.register_visit') }}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('visitors.modal.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('visitors.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('visitors.no') }}</v-btn>
                            <v-btn color="error" @click="deleteVisitor">
                                {{ $t('visitors.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDeparture" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('visitors.modal.departure') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('visitors.confirm_out_1') }} {{ this.visitor.visitor_name }} {{ $t('visitors.confirm_out_2') }} {{ this.hours }} : {{ this.minutes }} {{ $t('visitors.confirm_out_3') }} {{ this.day }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDeparture = false">{{ $t('visitors.cancel') }}</v-btn>
                            <v-btn class="white--text" color="amarillo" @click="updateDeparture">
                                {{ $t('visitors.accept') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalScheduled" persistent max-width="90%">
                <v-card class="px-3">
                    <v-card-text>
                        <v-row justify="end" align="end">
                            <v-col cols="11" justify="center" align="center">
                                <v-card-title class="mt-5 py-0 azul--text"> {{ $t('visitors.download_credentials') }}</v-card-title>
                            </v-col>
                            <v-col cols="1" justify="center" align="center">
                                <v-btn color="primary darken-1" class="mt-5 py-0" small icon @click="modalScheduled = false"><v-icon small>close</v-icon></v-btn>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="text-center my-0 py-0">
                            <v-col cols="12">
                                <datatable :props="{headers:headers_scheduled, items: scheduled.items}" :hide_pagination="true" @methodHandler="methodHandler"></datatable>
                            </v-col>
                        </v-row>
                    </v-card-text> 
                </v-card>
                <div class="marker1"></div>

            </v-dialog>
            <v-dialog v-model="modalScannQR" persistent max-width="90%">
                <v-card>
                    <v-card-text>
                        <v-row justify="end" align="end">
                            <v-btn color="primary darken-1" class="my-3 py-0" small icon @click="modalScannQR = false"><v-icon small>close</v-icon></v-btn>
                        </v-row>
                        <v-row align="center" justify="center" class="text-center my-0 py-0">
                            <v-col cols="12">
                                <h4>{{ $t('visitors.scan_code') }}</h4>
                            </v-col>
                        </v-row>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        headers(){
            return [
                { text: this.$t('visitors.date'), align: "center", value: 'date', class: 'text-uppercase' },
                { text: this.$t('visitors.name'), align: "center", value: 'visitor_name', class: 'text-uppercase' },
                { text: this.$t('visitors.company'), align: "center", value: 'company', class: 'text-uppercase' },
                { text: this.$t('visitors.receiver_name'), align: "center", value: 'receiver_name', class: 'text-uppercase' },
                { text: this.$t('visitors.plates'), align: "center", value: 'plates', class: 'text-uppercase' },
                { text: this.$t('visitors.subject'), align: "center", value: 'subject', class: 'text-uppercase' },
                { text: this.$t('visitors.comments'), align: "center", value: 'observations', class: 'text-uppercase' },
                { text: this.$t('visitors.entry_time'), align: "center", value: 'entry_time', class: 'text-uppercase' },
                { text: this.$t('visitors.departure_time'), align: "center", value: 'departure_time', class: 'text-uppercase' },
                { text: this.$t('visitors.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        },
        headers_scheduled(){
            return [
                { text: this.$t('visitors.date'), align: "center", value: 'date', class: 'text-uppercase' },
                { text: this.$t('visitors.name'), align: "center", value: 'visitor_name', class: 'text-uppercase' },
                { text: this.$t('visitors.company'), align: "center", value: 'company', class: 'text-uppercase' },
                { text: this.$t('visitors.receiver_name'), align: "center", value: 'receiver_name', class: 'text-uppercase' },
                { text: this.$t('visitors.subject'), align: "center", value: 'subject', class: 'text-uppercase' },
                { text: this.$t('visitors.actions'), align: "center", value: 'actions', class: 'text-uppercase' }
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            modalDeparture: false,
            modalScannQR: false,
            modalScheduled: false,
            idvisitors: '',
            qr_image: '',
            qr_visitor: '',
            visitor: {
                name: "",
                departure_time: "",
            },
            actions: [
                {
                    name: this.$t('visitors.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '',
                },
                {
                    name: this.$t('visitors.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    link: '',
                    permission: "visitors.update"
                },
                {
                    name: this.$t('visitors.register_departure'),
                    icon: {
                        color: 'accent',
                        icon: 'logout'
                    },
                    method: 'askDeparture',
                    permission: "visitors.update"
                }
            ],
            actions_scheduled: [
                {
                    name: this.$t('visitors.qr'),
                    icon: {
                        color: 'accent',
                        icon: 'badge'
                    },
                    method: 'generateQr',
                }
            ],
            props: {
                search: '',
                items: [],
            },
            scheduled: {
                items: []
            },
            day: '',
            hours: '',
            minutes: '',
            qrcode: '',
            last: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.visitors";
        this.day = new Date().getDate();
        if(this.day.toString().length == 1) this.day = "" + 0 + this.day;
        this.hours = new Date().getHours();
        if(this.hours.toString().length == 1) this.hours = "" + 0 + this.hours;
        this.minutes = new Date().getMinutes();
        if(this.minutes.toString().length == 1) this.minutes = "" + 0 + this.minutes;
        this.day = new Date().toLocaleDateString('en-GB');

        let self = this; 
        const keyPressHandler = (e) => {
            if(self.last == true) { 
                self.qrcode = e.key;
                self.last = false;
            }
            else if(e.code == "Enter") {
                self.last = true;
                self.verifyQr();
            }
            else self.qrcode += e.key;
        };
        window.addEventListener ('keypress', keyPressHandler, { capture: true });

        this.$once('hook:beforeDestroy',
            () => window.removeEventListener('keypress', keyPressHandler)
        );

    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'visitors', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach(x =>{
                        if(x.entry_time != null){
                            x['actions'] = JSON.parse(JSON.stringify(this.actions));
                            x.entry_time = x.entry_time.substring(0,5)
                            x.actions[0].link = '/entrance/visitors/detail/' + x.id;
                            x.actions[1].link = '/entrance/visitors/edit/' + x.id;
                            if(x.departure_time != "") x.actions[2].method = null;
                            x['parameters'] = x;    
                        }
                        else {
                            x['actions'] = JSON.parse(JSON.stringify(this.actions_scheduled));
                            // x.actions[1].link = '/entrance/visitors/detail/' + x.id;
                            // x.actions[2].link = '/entrance/visitors/edit/' + x.id;
                            x['parameters'] = x;    
                        }

                        this.props.items.push(x);
                    });

                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {

                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        askDelete(visitors){
            this.modalDelete = true;
            this.idvisitors = visitors.id;
        },
        askDeparture(visitors){
            this.modalDeparture = true;
            this.visitor.visitor_name = visitors.visitor_name;
            this.idvisitors = visitors.id;
        },
        deleteVisitor(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'visitors/' + this.idvisitors, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.modalDelete = false;
                    this.index();
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        updateDeparture(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.visitor.departure_time = this.hours + ":" + this.minutes;
            this.$http.put(this.$store.state.chronosApi + 'visitors/' + this.idvisitors, this.visitor,{headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_box.responses.success')
                }
                this.modalDeparture = false;
                this.index();
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        generateQr(visitor){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + '/visitorValidities/get_qr/' + visitor.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.qr_visitor = visitor;
                this.qr_image  = response.data.qr;
                const link = document.createElement("a");
                link.href = this.qr_image;
                link.download = "QR_" + this.$t("visitors.visitor") + "_" + this.qr_visitor.visitor_name + ".png";
                link.click();
                link.remove();
                this.$store.state.loaderProps.visible = false;
            })
            .catch((response) => {
                console.log(response)
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        verifyQr(){
            this.qrcode = this.qrcode.toString().replaceAll("Enter", "");
        
            if(this.qrcode.split("|").length == 5 || this.qrcode.split("¡").length == 5){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.post(this.$store.state.chronosApi + '/visitorValidities/validate_qr', {data: this.qrcode}, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.data.success.message == "New Register" || response.data.success.message == "Nuevo Registro") this.$router.push('/entrance/visitors/create/' + response.data.success.arguments.id);
                    else {
                        this.snackbar = {
                            visible: true,
                            color: 'success',
                            text: response.data.success.message
                        }
                        this.index();
                        this.$store.state.loaderProps.visible = false;
                    }
                })
                .catch((err) => {
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: err.response.data.fault.message
                    }
                    this.modalScannQR = false;
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else {
                this.modalScannQR = false;

                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('visitors.wrong_scanned_qr')
                }

                return;
            }
        },
        loadScheduledVisitors(){
            this.modalScheduled = true;
            this.scheduled.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'visitor_validities', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach(x =>{
                        x['actions'] = JSON.parse(JSON.stringify(this.actions_scheduled));
                        x['parameters'] = x;    
                        this.scheduled.items.push(x);
                    });

                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {

                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    }
}
</script>

<style>

</style>