<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="8" md="6" class="py-0 my-0">
                    <client-selector :addAllOption="true" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>  
                </v-col>
                <!-- <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text" color="amarillo" :to="'/cutoff/create'" >
                        {{ $t('cutoffs.new') }}
                    </v-btn>
                </v-col> -->
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>   
        <v-row justify="center">
            <v-dialog v-model="modal" persistent max-width="90%">
                <v-card>
                <v-card-title class="justify-center">
                    <v-spacer></v-spacer>
                    {{ $t('cutoffs.upload_payments') }}
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" small icon @click="stepper = 1;modal = false"><v-icon small>close</v-icon></v-btn>
                </v-card-title>

                <v-card-text>
                    <v-stepper v-model="stepper">
                        <v-stepper-header>
                            <v-stepper-step :complete="stepper > 1" step="1" color="amarillo">{{ $t('cutoffs.select_file') }}</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="stepper > 2" step="2" color="amarillo">{{ $t('cutoffs.verify_data') }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-card flat class="mb-5" >
                                    <v-form ref="form" lazy-validation>
                                        <v-row>
                                            <v-col cols="5" class="my-3">
                                                <datepicker :open_dialog="menu" :model="date" :label="$t('cod_collection.payment_date')" @model="updatePaymentDate($event)" :rules_required="rules_required"></datepicker>
                                            </v-col>
                                            <v-col cols="5">
                                                <v-file-input v-model="inputFile" :rules="rules_required" color="amarillo" accept=".csv" :label="$t('cutoffs.select_file')"></v-file-input>
                                            </v-col>
                                            <v-col cols="2" align="center" justify="center" class="my-2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mx-0" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="stepper = 3">
                                                            <v-icon aria-hidden="false" color="white" small>
                                                                help
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('upload_massive.help') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                                
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="right white--text" color="amarillo" @click="uploadFile" >
                                        {{ $t('cutoffs.next') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5" >
                                    <v-card-text>
                                        <p>{{readRows.length}} {{ $t('cutoffs.guides_in_file') }}</p>
                                    </v-card-text>
                                    <v-simple-table v-if="this.readRows.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th v-for="(th, key) in sTHeaders" :key="key" class="text-left">{{ th }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in readRows" :key="i">
                                                    <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mx-3" text @click="stepper = 1; sTHeaders = []; readRows = []">{{ $t('cutoffs.go_back') }}</v-btn>
                                    <v-btn color="amarillo" class="white--text" @click="saveGuides">
                                        {{ $t('cutoffs.upload_data') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                                <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                <p class="py-0 my-0">4.- El archivo de cobranza debe ser formato CSV.</p> 
                                <p>5.- Si el campo presenta la leyenda 'Opcional' este campo puede ir vacío.</p> 
                                <p class="py-0 my-0">Columnas: </p>
                                <v-simple-table fixed-header>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left" style="padding: 0 5px !important;">numero_de_orden</th>
                                                <th class="text-left" style="padding: 0 5px !important;">fulfillment</th>
                                                <th class="text-left" style="padding: 0 5px !important;">shipment</th>
                                                <th class="text-left" style="padding: 0 5px !important;">labels</th>
                                                <th class="text-left" style="padding: 0 5px !important;">ext_zone</th>
                                                <th class="text-left" style="padding: 0 5px !important;">cod_fee</th>
                                                <th class="text-left" style="padding: 0 5px !important;">return</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1324655067</td>
                                                <td>200</td>
                                                <td>100</td>
                                                <td>50</td>
                                                <td>30</td>
                                                <td>10</td>
                                                <td>200</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <v-row align="center" justify="center" class="my-3">
                                    <v-btn color="amarillo" class="white--text" @click="stepper = 1">
                                        {{ $t('products.go_back') }}
                                    </v-btn>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>  
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        headers(){
            return [
                { text: this.$t('cutoffs.customer'), align: "center", value: 'client.name', class: 'text-uppercase' },
                { text: this.$t('cutoffs.period'), align: "center", value: 'period', class: 'text-uppercase' },
                { text: this.$t('cutoffs.shipping_service'), align: "center", value: 'parcel', class: 'text-uppercase' },
                { text: this.$t('cutoffs.charged'), align: "center", value: 'orders_paid', class: 'text-uppercase' },
                { text: this.$t('cutoffs.pendings'), align: "center", value: 'orders_pending', class: 'text-uppercase' },
                { text: this.$t('cutoffs.debt'), align: "center", value: 'debt', class: 'text-uppercase' },
                { text: this.$t('cutoffs.collected'), align: "center", value: 'charged', class: 'text-uppercase' },
                { text: this.$t('cutoffs.last_payment'), align: "center", value: 'last_payment', class: 'text-uppercase' },
                { text: this.$t('cutoffs.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            numberFormat: new Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            idCutoff: '',
            actions: [
                {
                    name: this.$t('cutoffs.upload_payment'),
                    icon: {
                        color: 'accent',
                        icon: 'file_upload'
                    },
                    method: 'openModal',
                    permission: "collection.index"
                },
            ],
            description: '',
            props: {
                search: '',
                items: [],
            },
            modal: false,
            unknown: false,
            menu: false,
            inputFile: [],
            readFile: '',
            readRows: [],
            stepper: 1,
            date: '',
            all_cutoffs: []
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.cutoffs";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        updatePaymentDate(date){
            this.date = date;
        },
        index(){
            this.props.items = [];
            this.all_cutoffs= [];
            this.$store.state.clientId = "all"
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'cutoff/true', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data = response.data.data;
                    response.data.forEach(x =>{
                        x = x.attributes;
                        if(x['orders_paid'] == null || x['orders_paid'] == undefined) x['orders_paid'] = 0;
                        if(x['orders_pending'] == null || x['orders_pending'] == undefined) x['orders_pending'] = 0;
                        if(x['debt'] == null || x['debt'] == undefined) x['debt'] = '$' + this.numberFormat.format(0);
                        else x['debt'] = '$' + this.numberFormat.format(x['debt']);
                        if(x['charged'] == null || x['charged'] == undefined) x['charged'] = '$' + this.numberFormat.format(0);
                        else x['charged'] = '$' + this.numberFormat.format(x['charged']);

                        x['period'] = x.initial_date + " - " + x.final_date;
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x['parameters'] = x.id;
                        this.props.items.push(x);
                    });
                    this.$store.state.clientId = "all";
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            })
        },
        openModal(cutoff){
            this.idCutoff = cutoff;
            this.modal = true;
        },
        uploadFile() {
            if(this.$refs.form.validate()){
                // const files = e.target.files;
                // if (!files.length) return;
                this.createInput(this.inputFile);
            }
        },
        createInput(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows = result.split('\r\n');
                    this.sTHeaders = this.readRows[0].split(',')
                    this.readRows.shift()
                    this.stepper = 2
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        saveGuides(){
            this.$store.state.loaderProps.visible = true
            const info = [];
            this.readRows.forEach((x) => {
                const num = x.split(',')
                if(num[0] != '' && num[0] != null){
                    info.push({
                        'unique_order_number': num[0],
                        'fulfillment': num[1],
                        'shipment': num[2],
                        'labels': num[3],
                        'ext_zone': num[4],
                        'cod_fee': num[5],
                        'return': num[6],
                    })  
                }
            })
            const lsToken = localStorage.getItem("access_token")
            this.$http.post(this.$store.state.chronosApi + 'cutoff/payments', {'cutoff_id': this.idCutoff, guides: info, 'paid_at': this.date}, { headers: { Authorization: lsToken } })
            .then((response) => {
                if(response.status === 200){
                    this.modal = false;
                    this.index();
                    this.stepper = 1;
                    this.sTHeaders = [];
                    this.readRows = [];
                    this.date = "";

                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('cutoffs.upload_successfully')
                    }
                }
            })
            .catch((error) => {
                error
            })
            .finally(() => {
                this.$store.state.loaderProps.visible = false
            })
        }
    },
    watch: {
        '$store.state.clientId': function (){
            // this.cutoff.client_id = this.$store.state.clientId;
            this.props.items = [];
            if(this.$store.state.clientId == 'all') this.props.items = this.all_cutoffs;
            else{
                this.all_cutoffs.forEach(x => {
                    if(x.client.id == this.$store.state.clientId) this.props.items.push(x);
                })
            }
        }
    }
}
</script>
<style>
</style>