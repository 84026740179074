<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('visits.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <!-- <v-col cols="2" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="showImport = true">
                        {{ $t('visits.import') }}
                    </v-btn>
                </v-col> -->
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="showExport = true">
                        {{ $t('visits.export') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="showExport" persistent max-width="90%" style="width: 500px !important;">
                <v-card style="width:500px !important">
                    <v-card-title class="justify-center mt-4">
                        <v-row align="center" justify="center" class="my-2">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('visits.export_data')}}</label>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="6" class="py-0">
                                <datepicker :open_dialog="menu2" :model="report.initial_date" :label="$t('transportation.initial_date')" @model="updateInitialDate($event)" :rules_required="rules_required"></datepicker>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <datepicker :open_dialog="menu3" :model="report.final_date" :label="$t('transportation.final_date')" @model="updateFinalDate($event)" :rules_required="rules_required"></datepicker>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="showExport = false">{{ $t('visits.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="exportData">
                                {{ $t('visits.export') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showImport" persistent max-width="90%">
                <v-card>
                <v-card-title class="justify-center">
                    <v-spacer></v-spacer>
                    {{ $t('cutoffs.upload_payments') }}
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" small icon @click="stepper = 1;showImport = false"><v-icon small>close</v-icon></v-btn>
                </v-card-title>

                <v-card-text>
                    <v-stepper v-model="stepper">
                        <v-stepper-header>
                            <v-stepper-step :complete="stepper > 1" step="1" color="amarillo">{{ $t('cutoffs.select_file') }}</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="stepper > 2" step="2" color="amarillo">{{ $t('cutoffs.verify_data') }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-card flat class="mb-5" >
                                    <v-form ref="form" lazy-validation>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-file-input v-model="inputFile" :rules="rules_required" color="amarillo" accept=".csv" :label="$t('cutoffs.select_file')"></v-file-input>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                                
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="right white--text" color="amarillo" @click="uploadFile" >
                                        {{ $t('cutoffs.next') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5" >
                                    <v-card-text>
                                        <p>{{readRows.length}} {{ $t('cutoffs.guides_in_file') }}</p>
                                    </v-card-text>
                                    <v-simple-table v-if="this.readRows.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th v-for="(th, key) in sTHeaders" :key="key" class="text-left">{{ th }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in readRows" :key="i">
                                                    <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mx-3" text @click="stepper = 1; sTHeaders = []; readRows = []">{{ $t('cutoffs.go_back') }}</v-btn>
                                    <v-btn color="amarillo" class="white--text" @click="importData">
                                        {{ $t('cutoffs.upload_data') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('visits.number'), align: "center", value: 'id', class: 'text-uppercase' },
                { text: this.$t('visits.date'), align: "center", value: 'date', class: 'text-uppercase' },
                { text: this.$t('visits.company'), align: "center", value: 'company', class: 'text-uppercase' },
                { text: this.$t('visits.entry_time'), align: "center", value: 'checkInTime', class: 'text-uppercase' },
                { text: this.$t('visits.departure_time'), align: "center", value: 'checkOutTime', class: 'text-uppercase' },
                { text: this.$t('visits.supplier'), align: "center", value: 'client', class: 'text-uppercase' },
                { text: this.$t('visits.name'), align: "center", value: 'name', class: 'text-uppercase' },
                { text: this.$t('visits.plates'), align: "center", value: 'placas', class: 'text-uppercase' },
                { text: this.$t('visits.s_v'), align: "center", value: 'sv', class: 'text-uppercase' },
                { text: this.$t('visits.business'), align: "center", value: 'bussines', class: 'text-uppercase' },
                { text: this.$t('visits.observations'), align: "center", value: 'observations', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            report:{
                initial_date: '',
                final_date: ''
            },
            menu2: false,
            menu3: false,
            showExport: false,
            showImport: false,
            inputFile: [],
            readFile: '',
            sTHeaders: '',
            readRows: [],
            stepper: 1,
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.visits";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        updateInitialDate(value){
            this.report.initial_date = value;
        },
        updateFinalDate(value){
            this.report.initial_date = value;
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'visits', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    x = x.attributes;
                    this.props.items.push(x);
                });
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        commits(){
            this.createInput(this.commit.file, "commit");
        },
        uploadFile() {
            this.createInput(this.inputFile);
        },
        createInput(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows = result.split('\r\n');11
                    this.readRows.shift();
                    this.stepper = 2;

                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        importData(){
            this.modalImport = false;
            this.$store.state.loaderProps.visible = true;
            const visits = [];
            if(this.readRows.length > 0){
                this.readRows.forEach((x) => {
                    const num = x.split(',');
                    visits.push({
                        'fecha': "20" + num[0].split("/")[2] + "-" + num[0].split("/")[0] + "-" + num[0].split("/")[1],
                        'empresa': num[1],
                        'entrada': num[2],
                        'salida': num[3],
                        'nombre': num[4],
                        'asunto': num[5]
                    });
                })
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'customs/importvisitsData', { visits: visits }, { headers: { Authorization: lsToken } })
                .then((response) => {
                    if(response.data.status === 200){
                        this.modal2 = false;
                        this.stepper2 = 1;
                        this.sTHeaders2 = [];
                        this.readRows2 = [];
                        this.inputFile2 = null;
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('products.responses.ok')
                        };
                        this.index();
                    }
                })
                .catch((error) => {
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: "error " + error 
                    };
                })
                .finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('products.validations.select_file_first')
                };
            }
        },
        exportData(){

        }
    }
}
</script>

<style>
</style>