<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="3" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('cm_outcoming_movements.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" class="py-0" justify="center" align="center">
                    <v-autocomplete v-model="year" class="py-0" :label="$t('no_annex.year')" :items="years"></v-autocomplete>
                </v-col>
                <v-col cols="6" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="showExport = true">
                        {{ $t('cm_outcoming_movements.export') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <!-- <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable> -->
                            <v-data-table class="px-0 my-2"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                item-key="id"
                                :items-per-page="items_per_page"
                                hide-default-footer
                            >
                                <template v-slot:footer>
                                    <v-row>
                                        <v-col cols="7"></v-col>
                                        <v-col cols="1" align="end" justify="center">
                                            <v-autocomplete class="py-0 px-0" :label="$t('cm_outcoming_movements.items_per_page')" v-model="items_per_page" :items="items_options"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="4" align="right" justify="right" class="px-0">
                                            <v-pagination v-model="page" color="azul" :length="pages" @input="next" justify="right" align="right"></v-pagination>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="showExport" persistent max-width="90%" style="width: 500px !important;">
                <v-card style="width:500px !important">
                    <v-card-title class="justify-center mt-4">
                        <v-row align="center" justify="center" class="my-2">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('cm_outcoming_movements.export_data')}}</label>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="6" class="py-0">
                                <datepicker :open_dialog="menu2" :model="report.initial_date" :label="$t('cm_outcoming_movements.initial_date')" @model="updateDate($event)" :rules_required="rules_required"></datepicker>
                            </v-col>

                            <v-col cols="6" class="py-0">
                                <datepicker :open_dialog="menu3" :model="report.final_date" :label="$t('cm_outcoming_movements.final_date')" @model="updateDateFinal($event)" :rules_required="rules_required"></datepicker>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="showExport = false">{{ $t('cm_outcoming_movements.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="exportData">
                                {{ $t('cm_outcoming_movements.export') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('cm_outcoming_movements.id'), align: "center", value: 'id', class: 'text-uppercase' },
                { text: this.$t('cm_outcoming_movements.number'), align: "center", value: 'customs_document', class: 'text-uppercase' },
                { text: this.$t('cm_outcoming_movements.key'), align: "center", value: 'cvedoc', class: 'text-uppercase' },
                { text: this.$t('cm_outcoming_movements.entry_date'), align: "center", value: 'customs_document_date', class: 'text-uppercase' },
                { text: this.$t('cm_outcoming_movements.destination_country'), align: "center", value: 'origin_country', class: 'text-uppercase' },
                { text: this.$t('cm_outcoming_movements.merchandise_data'), align: "center", value: 'description', class: 'text-uppercase' },
                { text: this.$t('cm_outcoming_movements.commercial_invoice'), align: "center", value: 'umt', class: 'text-uppercase' },
                { text: this.$t('cm_outcoming_movements.electronic_notice'), align: "center", value: 'notice_number', class: 'text-uppercase' },
                { text: this.$t('cm_outcoming_movements.date'), align: "center", value: 'customs_document_date', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            report:{
                initial_date: '',
                final_date: ''
            },
            menu2: false,
            menu3: false,
            showExport: false,
            warehouse_id: this.$store.state.warehouseId,
            years: ["2024", "2023", "2022", "2021", "2020", "2019", "2018", "2017"],
            year: "2024",
            page: 1,
            pages: 5,
            total_items: 500,
            items_per_page: 10,
            items_options: [10,25,50,100],
        }
    },
    mounted(){
        // this.index();
        this.$store.state.module = "headers.cm_outcoming_movements";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        updateDate(newDate){
            this.report.initial_date = newDate;
        },
        updateDateFinal(date){
            this.report.final_date = date;
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'customs/customs-module?type=Entrada&startDate=' + this.year + '-01-01&endDate=' + this.year + '-12-31&warehouse=' + this.warehouse_id + '&perPage=' + this.items_per_page + '&page=' + this.page, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    x = x.attributes;
                    this.props.items.push(x);
                });
                this.pages = response.data.meta.tota_pages;
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        exportData(){

        },
        next(page) {
            this.page = page;
            this.index();
        }, 
    },
    watch:{
        'year': function (){
            this.index();
        },
        'items_per_page': function(){
            this.index();
        }
    }
}
</script>

<style>
</style>