<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center" v-if="appointment.client">    
                        <h3 class="azul--text text-uppercase">{{ $t('preload.preload_outcome') }} {{ this.id }} {{ this.appointment.client }}</h3>
                    </v-col>
                </v-row>
                <v-row class="px-5 pt-0" align="center" justify="center">
                    <v-col cols="6" align="start" justify="start" v-if="appointment.client">    
                        <h4 class="azul--text text-uppercase">{{ $t('preload.products') }}</h4>
                    </v-col>
                </v-row>
                <v-row class="px-5 pb-2 my-0" justify="center" align="center">
                    <v-col cols="3" class="px-3 py-0">
                        <span >{{ $t('preload.select_method_type') }}:</span>
                    </v-col>
                    <v-col cols="3" class="px-3 py-0" justify="end" align="end">
                        <v-btn class="radio-left" v-bind:color="method == 'A' ? 'info' : 'white'" @click="(method = 'A')">A</v-btn>
                        <v-btn class="radio-center" v-bind:color="method == 'B' ? 'info' : 'white'" @click="(method = 'B')">B</v-btn>
                        <v-btn class="radio-right" v-bind:color="method == 'C' ? 'info' : 'white'" @click="(method = 'C')">C</v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <!-- METHOD A WITH HIS TABLE -->
                    <v-form ref="form_A" lazy-validation @submit.prevent="save" v-if="method == 'A'">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :loading="load_products" v-model="product.sku" :items="products" item-value="sku" item-text="sku_desc" :label="$t('preload.product')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :loading="load_batches" v-model="product.batch" :items="batches" :label="$t('preload.batch')"></v-autocomplete> 
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :loading="load_references" v-model="product.reference" :items="references" :label="$t('preload.reference')"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :loading="load_locations" v-model="product.location" :items="locations" :label="$t('preload.location')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :loading="load_pallets" v-model="product.pallet" :items="pallets" item-value="pallet" item-text="description" :label="$t('preload.pallet')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="product.quantity" :label="$t('preload.quantity')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0" justify="end" align="end">
                                <v-btn class="right white--text" color="amarillo" outlined @click="addProductAMethod">
                                    {{ $t('preload.add') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>

                    <!-- METHOD B WITH HIS TABLE -->
                    
                    <v-form ref="form_B" lazy-validation @submit.prevent="save" v-if="method == 'B'">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :loading="load_products" v-model="product.sku" :items="products" item-value="sku" item-text="sku" :label="$t('preload.product')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :loading="load_batches" v-model="product.batch" :items="batches" :label="$t('preload.batch')"></v-autocomplete> 
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :loading="load_references" v-model="product.reference" :items="references" :label="$t('preload.reference')"></v-autocomplete> 
                            </v-col>
                        </v-row>
                    </v-form>

                    <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="method == 'B'">
                        <v-col cols="6" class="py-0">
                            <v-row class="px-0 py-0 my-0" justify="start" align="start">
                                <v-col cols="12" class="py-0" style="display:flex" v-for="(info, i) of info_method_b" :key="i">
                                    <v-row class="px-0 py-0 my-0">
                                        <v-col cols="8">
                                            <v-checkbox class="mt-0" color="info" v-model="info.value" :label="info.description" v-if="info.hide == false"></v-checkbox> 
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="3">
                                            <v-text-field class="py-0" v-model="info.total" label="" :rules="numeric_required" v-if="info.hide == false"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="method == 'B'">
                        <v-col cols="6" class="py-0" justify="end" align="end">
                            <v-btn class="right white--text mr-2" color="amarillo" outlined @click="cleanSelectionB">
                                {{ $t('preload.clean') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" outlined @click="addProductBMethod">
                                {{ $t('preload.add') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                    <!-- METHOD C WITH HIS TABLE -->

                    <v-form ref="form_C" lazy-validation @submit.prevent="save" v-if="method == 'C'">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :loading="load_products" v-model="product.sku" :items="products" item-value="sku" item-text="sku" :label="$t('preload.product')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :loading="load_batches" v-model="product.batch" :items="batches" :label="$t('preload.batch')"></v-autocomplete> 
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="mt-0 py-0" :loading="load_references" v-model="product.reference" :items="references" :label="$t('preload.reference')"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.quantity" :label="$t('preload.quantity')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0" justify="end" align="end">
                                <v-btn class="right white--text" color="amarillo" outlined @click="addProductCMethod">
                                    {{ $t('preload.add') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>

                    <v-row justify="center" aling="center">
                        <v-col cols="6" class="py-0" justify="center" aling="center">
                            <datatable :props="{ headers: headers, items: skus }" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>

                    <v-row class="px-5 py-0 mb-0 mt-4" justify="center" align="center">
                        <v-col cols="6" class="py-0" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('preload.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save">
                                {{ $t('preload.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        client: function(){
            return this.$store.state.clientId
        },
        rules_required(){return [v => !!v || this.$t('preload.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
        headers: function(){
            return [
                {text: this.$t('preload.sku'), value: 'sku', class: 'text-uppercase'},
                {text: this.$t('preload.location'), value: 'location', class: 'text-uppercase'},
                {text: this.$t('preload.pallet_t'), value: 'pallet', class: 'text-uppercase'},
                {text: this.$t('preload.quantity'), value: 'quantity', class: 'text-uppercase'},
                {text: this.$t('preload.batch'), value: 'batch', class: 'text-uppercase'},
                {text: this.$t('preload.reference'), value: 'reference', class: 'text-uppercase'},
                {text: '', value: 'actions', class: 'text-uppercase', width:'10%', align: 'center', sortable: false},
            ]
        }
    },
    data(){
        return {
            from: this.$route.params.from,
            id: this.$route.params.id,
            m_type: this.$route.params.m_type,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            appointment: {

            },
            method: 'A',
            product: {
                detailed_stock_id: null,
                sku: null,
                batch: null,
                reference: null,
                location: null,
                pallet: null,
                quantity: null,
            },
            to_sum: "",
            product_to_sum: "",
            modal_sum: false,
            
            products: [], //catalogue of products
            batches: [],
            references: [],
            locations: [],
            pallets: [],
            
            load_products: false,
            load_batches: false,
            load_references: false,
            load_locations: false,
            load_pallets: false,
            quantity_limit: 0,
            info_method_b: [],
            skus: [], // appointments products
            actions: [
                {
                    name: this.$t('preload.remove'),
                    icon: {
                        color: 'error',
                        icon: 'clear'
                    },
                    method: 'removeProduct',
                }
            ]    
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.preload";
    },
    methods: {
        async index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.load_products = true;
            const {data} = await this.$http.get(this.$store.state.chronosApi + 'appointments/standby_products/' + this.id, {headers: {Authorization: lsToken}})

            this.appointment = {
                client: data.client.name,
                id: this.id,
                client_id: data.client.id
            }

            this.$http.get(this.$store.state.chronosApi + 'appointments/outcome/picking/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.details.forEach(x => {
                    this.skus.push(JSON.parse(JSON.stringify({
                        sku: x.product,
                        quantity: x.quantity,
                        location: x.location,
                        pallet: x.pallet,
                        batch: x.batch,
                        reference: x.reference,
                        detailed_stock_id: x.detailed_stock_id,
                        actions: JSON.parse(JSON.stringify(this.actions)),
                        parameters: x.detailed_stock_id
                    })))
                })
            })

            this.$http.get(this.$store.state.chronosApi + 'getProducts/' + this.appointment.client_id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data;
                response.data.forEach(x =>{
                    x = x.attributes;
                    console.log(x);
                    this.products.push({
                        product_id: x.id,
                        sku_desc: x.sku + " | " +x.description,
                        sku: x.sku, 
                    });
                });
            }).finally(() => {
                this.load_products = false;
                this.$store.state.loaderProps.visible = false;
            })
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        save(){
            if(this.skus.length == 0) {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('preload.no_products')
                }

                return;
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            
            const payload = {
                products: []
            };

            this.skus.forEach(x => {
                payload.products.push({ detailed_stock_id: x.detailed_stock_id, quantity: x.quantity })
            })

            this.$http.post(this.$store.state.chronosApi + 'appointments/reserve_products/' + this.id, payload, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('preload.responses.ok')
                    }

                    this.$router.go(-1);
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('preload.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancel(){
            this.$router.go(-1);
        },
        addProductAMethod(){
            var validate = this.$refs.form_A.validate();
            if(!validate) return;

            var finded = this.skus.find(x => x.detailed_stock_id == this.product.detailed_stock_id);

            if(finded != undefined) {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('preload.product_already_added')
                }

                return;
            }

            if(this.product.detailed_stock_id == null){
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('preload.form_incomplete')
                }

                return;
            }

            if(parseInt(this.product.quantity) > parseInt(this.quantity_limit) || parseInt(this.product.quantity) <= 0 ) {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('preload.quantity_positive_and_loe_to_limit')
                }

                return;
            }

            this.skus.push(JSON.parse(JSON.stringify({
                sku: this.product.sku,
                quantity: parseInt(this.product.quantity),
                location: this.product.location,
                pallet: this.product.pallet,
                batch: (this.product.batch == this.$t('preload.no_batch') ? null : this.product.batch),
                reference: (this.product.reference == this.$t('preload.no_reference') ? null : this.product.reference),
                detailed_stock_id: this.product.detailed_stock_id,
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: this.product.detailed_stock_id
            })))

            this.$refs.form_A.reset();
        },
        addProductBMethod(){
            var t = this;
            this.info_method_b.forEach(x => {
                if(x.value == true){
                    t.skus.push(JSON.parse(JSON.stringify({
                        sku: t.product.sku,
                        quantity: parseInt(x.total),
                        location: x.location,
                        batch: (this.product.batch == this.$t('preload.no_batch') ? null : this.product.batch),
                        reference: (this.product.reference == this.$t('preload.no_reference') ? null : this.product.reference),
                        pallet: x.pallet,
                        detailed_stock_id: x.detailed_stock_id,
                        actions: JSON.parse(JSON.stringify(t.actions)),
                        parameters: x.detailed_stock_id
                    })));

                    x.hide = true;
                }
            });

            this.$refs.form_B.reset();
        },
        async addProductCMethod(){
            this.$store.state.loaderProps.visible = true;

            try{
                const lsToken = localStorage.getItem("access_token");
                const {data} = await this.$http.get(this.$store.state.chronosApi + 'detailed_stocks/extract_products/' + this.method + '?client_id=' + this.appointment.client_id + '&sku=' + this.product.sku + '&batch=' + (this.product.batch == this.$t('preload.no_batch') ? null : this.product.batch) + '&reference=' + (this.product.reference == this.$t('preload.no_reference') ? null : this.product.reference) + '&quantity=' + this.product.quantity, {headers: {Authorization: lsToken}})

                data.data.forEach(x => {
                    this.skus.push(JSON.parse(JSON.stringify({
                        sku: this.product.sku,
                        quantity: parseInt(x.discount),
                        location: x.location,
                        batch: (this.product.batch == this.$t('preload.no_batch') ? null : this.product.batch),
                        reference: (this.product.reference == this.$t('preload.no_reference') ? null : this.product.reference),
                        pallet: x.pallet,
                        detailed_stock_id: x.detailed_stock_id,
                        actions: JSON.parse(JSON.stringify(this.actions)),
                        parameters: x.detailed_stock_id
                    })));
                });

                this.$store.state.loaderProps.visible = false;
                this.$refs.form_C.reset();
            }
            catch(e){
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: e.response.data.message
                }

                this.$store.state.loaderProps.visible = false;
            }
        },
        sum(product_id){
            this.product_to_sum = product_id

            this.modal_sum = true;
        },
        removeProduct(product_id){
            const index = this.skus.findIndex(x => x.detailed_stock_id == product_id)

            if(index != null && index != undefined){
                this.skus.splice(index, 1)
            }
        },
        cleanSelectionB(){
            this.info_method_b.forEach(x => {
                x.value = false;
            })
        }
    },
    watch:{
        'product.sku': function (){
            this.product.detailed_stock_id = null;
            this.product.batch = null;
            this.product.reference = null;
            this.product.location = null;
            this.product.pallet = null;
            this.product.quantity = null;
            this.info_method_b = [];

            if((this.method == "A" || this.method == "B" || this.method == "C") && this.product.sku != null){
                const lsToken = localStorage.getItem("access_token");
                this.load_batches = true;
                this.$http.get(this.$store.state.chronosApi + 'detailed_stocks/extract_products/' + this.method + '?client_id=' + this.appointment.client_id + '&sku=' + this.product.sku, {headers: {Authorization: lsToken}})
                .then((response) => {
                    var nullables = response.data.data.filter(x => x == null);
                    this.batches = response.data.data;  

                    if(response.data.data.length == 0 || nullables.length > 0) this.batches.push(this.$t('preload.no_batch'));
                })
                .finally(() => {
                    this.load_batches = false;
                });
            }
        },
        'product.batch': function (){
            this.product.detailed_stock_id = null;
            this.product.reference = null;
            this.product.location = null;
            this.product.pallet = null;
            this.product.quantity = null;
            this.info_method_b = [];

            if((this.method == "A" || this.method == "B" || this.method == "C") && this.product.batch != null){
                const lsToken = localStorage.getItem("access_token");
                this.load_references = true;
                this.$http.get(this.$store.state.chronosApi + 'detailed_stocks/extract_products/' + this.method + '?client_id=' + this.appointment.client_id + '&sku=' + this.product.sku + '&batch=' + (this.product.batch == this.$t('preload.no_batch') ? null : this.product.batch), {headers: {Authorization: lsToken}})
                .then((response) => {
                    var nullables = response.data.data.filter(x => x == null);
                    this.references = response.data.data;  

                    if(response.data.data.length == 0 || nullables.length > 0) this.references.push(this.$t('preload.no_reference'));
                })
                .finally(() => {
                    this.load_references = false;
                });
            }
        },
        'product.reference': function (){
            this.product.detailed_stock_id = null;
            this.product.location = null;
            this.product.pallet = null;
            this.product.quantity = null;
            this.info_method_b = [];

            if((this.method == "A" || this.method == "B") && this.product.reference != null){
                const lsToken = localStorage.getItem("access_token");
                this.load_locations = true;
                this.$http.get(this.$store.state.chronosApi + 'detailed_stocks/extract_products/' + this.method + '?client_id=' + this.appointment.client_id + '&sku=' + this.product.sku + '&batch=' + (this.product.batch == this.$t('preload.no_batch') ? null : this.product.batch) + '&reference=' + (this.product.reference == this.$t('preload.no_reference') ? null : this.product.reference), {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(this.method == "A") this.locations = response.data.data; 
                    else if(this.method == "B") {
                        response.data.data.forEach(x => {
                            x.value = false;
                            x.hide = false;
                            x.total = x.quantity;

                            this.info_method_b.push(x);
                        })
                    }
                })
                .finally(() => {
                    this.load_locations = false;
                });
            }    
        },
        'product.location': function (){
            this.product.detailed_stock_id = null;
            this.product.pallet = null;
            this.product.quantity = null;

            if(this.method == "A" && this.product.location != null){
                const lsToken = localStorage.getItem("access_token");
                this.load_pallets = true;
                this.$http.get(this.$store.state.chronosApi + 'detailed_stocks/extract_products/' + this.method + '?client_id=' + this.appointment.client_id + '&sku=' + this.product.sku + '&batch=' + (this.product.batch == this.$t('preload.no_batch') ? null : this.product.batch) + '&reference=' + (this.product.reference == this.$t('preload.no_reference') ? null : this.product.reference) + '&location=' + this.product.location, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.pallets = response.data.data;  
                })
                .finally(() => {
                    this.load_pallets = false;
                });
            }
        },
        'product.pallet': function (){
            this.product.detailed_stock_id = null;
            if(this.method == "A" && this.product.pallet != null){
                var c_pallet = this.pallets.find(x =>  x.pallet == this.product.pallet);
                this.product.detailed_stock_id = c_pallet.detailed_stock_id;
                this.quantity_limit = c_pallet.quantity;
            }
        },
        'method': function (){
            this.info_method_b = [];
            // this.skus = [];
            this.product = {
                detailed_stock_id: null,
                sku: null,
                batch: null,
                reference: null,
                location: null,
                pallet: null,
                quantity: null,
            }
        }
    }
}
</script>

<style lang="css">
</style>