<template>
    <div>
        <v-menu ref="reference" v-model="open" :close-on-content-click="false" :return-value.sync="v_model" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
                <v-text-field class="py-0" v-model="v_model" :label="label" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
            </template>

            <v-date-picker v-model="v_model" @click:date="menu = false; $refs.reference.save(v_model)" no-title scrollable></v-date-picker>
        </v-menu>
    </div>
</template>

<script>

export default {
    props: [
        'open_dialog',
        'model',
        'rules_required',
        'label'
    ],
    data(){
        return {
            open: this.open_dialog,
            v_model: this.model
        }
    },
    mounted(){
        this.v_model = this.model
    },
    methods: {
        emitValue() {
            this.$emit('model', this.v_model);  // Emite el valor actualizado
        }
    },
    watch: {
        "v_model": function (){
            this.emitValue();
        }
    }
}
</script>

<style>

</style>